import React, { useState } from "react";
import "./Processing.scss";
import { Grid, Table } from "semantic-ui-react";
import {
  CommonTable,
  CustomButton,
  DropDown,
  TitleView,
} from "../../../components";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { ProcessHeatTableHeader } from "../../../config/constants";
import { InputNumber } from "../../../components/InputText/InputNumber";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { valueGraterThanZero } from "../../../utils/utils";

const SimulationProcessHeatSource = ({
  heatSourceTable,
  originalHeatSourceTable,
  heatSourceType,
  control,
  defaultHeatType,
  setDefaultHeatType,
  errorsHeatSource,
  handleSubmitHeatSource,
  onAddHeatSourceHandler,
  onEditHeatSourceHandler,
  onDeleteHeatSourceHandler,
}: any) => {
  const [enableUpdateButton, setEnableUpdateButton] = useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [selectedHeatSource, setSelectedHeatSource] = useState(null);

  return (
    <>
      <TitleView title="Heat Sources" />
      <Grid>
        <Grid.Row divided>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            {originalHeatSourceTable?.length > 0 && (
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
              >
                <CommonTable
                  tableHeaderData={ProcessHeatTableHeader.filter(
                    (e: any) => e.name !== ""
                  )}
                  isSimulateView
                >
                  {originalHeatSourceTable.map((item: any) => {
                    return (
                      <Table.Row key={`heatSource_${item.key}`}>
                        <Table.Cell>
                          <p>{heatSourceType?.[item?.HeatSource].text ?? ""}</p>
                        </Table.Cell>
                        <Table.Cell>
                          <p>{item?.HeatAmount ?? ""}</p>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </CommonTable>
              </Grid.Column>
            )}
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Grid>
              {heatSourceTable?.length > 0 && (
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="heatSourceTable"
                >
                  <CommonTable tableHeaderData={ProcessHeatTableHeader}>
                    {heatSourceTable.map((item: any) => {
                      return (
                        <Table.Row key={`heatSource_${item.index}`}>
                          <Table.Cell>
                            <p>
                              {heatSourceType?.[item?.HeatSource].text ?? ""}
                            </p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{item?.HeatAmount ?? ""}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <Grid>
                              <Grid.Column computer={8} tablet={16} mobile={16}>
                                <MdModeEditOutline
                                  cursor={"pointer"}
                                  size={24}
                                  color="var(--tableEditIcon)"
                                  onClick={() => {
                                    onEditHeatSourceHandler(item);
                                    setEnableUpdateButton(true);
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column computer={8} tablet={16} mobile={16}>
                                <MdDeleteOutline
                                  cursor={"pointer"}
                                  size={24}
                                  color="var(--tableEditIcon)"
                                  onClick={() => {
                                    setSelectedHeatSource(item);
                                    setVisibleDeleteModal(true);
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </CommonTable>
                </Grid.Column>
              )}
              <Grid.Column computer={7} tablet={8} mobile={16}>
                <DropDown
                  labelName={"Source*"}
                  placeholder="Heat Source"
                  currentData={heatSourceType}
                  defaultValue={heatSourceType?.[defaultHeatType].value || null}
                  customGridColumn={"customGridColomnType"}
                  handleChangeState={(e: any, { value }: any) => {
                    let getIndex = heatSourceType?.findIndex(
                      (e: any) => e?.value === value
                    );
                    setDefaultHeatType(getIndex);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={6} mobile={16}>
                <InputNumber
                  errors={errorsHeatSource.HeatAmount}
                  control={control}
                  labelName={"Heating Amt: (MJ/kg)*"}
                  placeholder="MJ/kg"
                  required={true}
                  errorMessage="Heat Amount is required"
                  name="HeatAmount"
                  otherValidation={{
                    validate: {
                      valueCheck: (v: any) =>
                        valueGraterThanZero(v) ||
                        "Heat Amount must be grater than 0 ",
                    },
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={2} tablet={2} mobile={16}>
                <CustomButton
                  theme="green"
                  title={enableUpdateButton ? "Update" : ""}
                  icon={!enableUpdateButton ? "plus" : null}
                  disabled={false}
                  customColumnStyle={"plusButton"}
                  onClick={handleSubmitHeatSource((data: any) => {
                    onAddHeatSourceHandler(data);
                    setEnableUpdateButton(false);
                  })}
                />
              </Grid.Column>
              <ConfirmModal
                viewModal={visibleDeleteModal}
                closeModal={() => setVisibleDeleteModal(false)}
                cancel={() => {
                  setVisibleDeleteModal(false);
                }}
                approve={() => {
                  onDeleteHeatSourceHandler(selectedHeatSource);
                  setVisibleDeleteModal(false);
                }}
                title={`Delete Heat Source`}
                subTitle={`Are you sure you want to delete process heat source?`}
              />
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default SimulationProcessHeatSource;
