import React, { useState, useEffect } from "react";
import { Grid, Table, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { MdDeleteOutline } from "react-icons/md";

import {
  InputText,
  CustomButton,
  InputTextArea,
  CommonTable,
  NewImageUploader,
  Switch,
  CommonModal,
  TitleView,
  DropDown,
} from "../../../components";
import defaultUserImage from "../../../assets/images/default_icon.jpeg";
import { ProductUnitTable, UNIT_TYPES } from "../../../config/constants";
import useWindowDimensions from "../../../hooks/windowsSize";
import { isEmpty } from "lodash";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import {
  useCreateProduct,
  useUploadProductImage,
  usePostUSalesUnit,
} from "../../../api/product";
import CommonSplitContainer from "../../../components/commonSplitContainer/CommonSplitContainer";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import PlusIcon from "../../../assets/commonIcon/PlusIcon";

import "./productRegister.scss";

interface ProductRegisterModalProps {
  visibleModal: boolean;
  setVisibleModal: (visible: boolean, value?: any) => void;
}

interface ProductUnit {
  sales_unit_size: number;
  sales_unit_type: string;
  ean_barcode: string;
  sales_unit_name: string;
}

interface ProductFormData {
  product_name: string;
  product_other_info?: string;
  registration_number?: string;
  productData?: any
  enableNavigate?: any
}

const ProductRegisterModal: React.FC<ProductRegisterModalProps> = ({
  visibleModal,
  setVisibleModal,
}) => {
  const [addProductUnit, setProductUnit] = useState<ProductUnit[]>([]);
  const [productType, setProductType] = useState<string>("PROCESS");
  const [defaultType, setDefaultType] = useState<string>(UNIT_TYPES[0].value);
  const [imageData, setImageData] = useState<File | null>(null);
  const { height, width } = useWindowDimensions();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<ProductFormData>();

  const {
    register: registerUnitSize,
    formState: { errors: errorsUnit },
    handleSubmit: handleSubmitUnitSize,
    reset: resetUnitSize,
  } = useForm<ProductUnit>();

  const { isLoading, mutate, data, reset: resetData } = useCreateProduct();
  const { isLoading: isLoadingProductImage, mutate: uploadProductImage } = useUploadProductImage();
  const { isLoading: isLoadingSalesUnit, mutate: uploadSalesUnit } = usePostUSalesUnit();

  useEffect(() => {
    return () => {
      setProductUnit([]);
      setDefaultType(UNIT_TYPES[0].value);
      reset();
      resetUnitSize();
    };
  }, [visibleModal]);

  const closeModalStatus = () => {
    const value = getValues();
    setVisibleModal(true, value);
  };

  const saveProductImage = (data: any) => {
    if (imageData) {
      const productImageData = {
        product_id: data?._id,
        file: imageData,
      };
      uploadProductImage(productImageData, {
        onSuccess: () => {
          finishProductImageSave();
        },
      });
    } else {
      successMessage("Product created successfully");
      closeModalStatus();
    }
  };

  const saveUnitData = (productData: any) => {
    if (addProductUnit.length > 0) {
      const productUnitData = {
        product_id: productData._id,
        sales_units: addProductUnit,
      };
      uploadSalesUnit(productUnitData, {
        onSuccess: () => {
          saveProductImage(productData);
        },
      });
    }
  };

  const finishProductImageSave = () => {
    successMessage("Product created successfully");
    closeModalStatus();
  };

  const removeDataHandler = (index: number) => {
    const newData = [...addProductUnit];
    newData.splice(index, 1);
    setProductUnit(newData);
  };

  const checkProductSaveCondition = (data: ProductFormData): boolean => {
    if (isEmpty(data.product_name.trim())) {
      errorView("Product name is required");
      return true;
    } else if (addProductUnit.length <= 0) {
      errorView("Please add product sizes");
      return true;
    }
    return false;
  };

  const addUnitData = (data: ProductUnit) => {
    setProductUnit([...addProductUnit, { ...data, sales_unit_type: defaultType }]);
    resetUnitSize();
  };

  const onSubmit = async (data: ProductFormData) => {
    if (!checkProductSaveCondition(data)) {
      const productData = {
        ...data,
        product_type: productType,
        sales_units: [],
        product_ingredients: [],
      };

      mutate(productData, {
        onSuccess: (data) => {
          setValue("productData", { ...data, product_id: data._id });
          saveUnitData(data);
        },
      });
    }
  };

  const cancel = () => {
    closeModalStatus();
    reset();
  };

  const saveProduct = () => {
    const values = getValues();
    setValue("enableNavigate", false);
    onSubmit(values);
  };

  //load product unit data table
  const loadTableData = () => {
    return addProductUnit.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.sales_unit_name ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.sales_unit_size ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.sales_unit_type ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.ean_barcode ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  onClick={() => removeDataHandler(index)}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  //loading view
  if (isLoading || isLoadingSalesUnit || isLoadingProductImage) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          closeModalStatus();
          reset();
        }}
        centered={true}
        title="Add New Product"
      >
        <TitleView
          title="Product Info"
          CustomTitleViewMain="productRegisterTitle"
        />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CommonSplitContainer
                  topChild={
                    <div
                    >
                      <Grid>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                          <Grid>
                            <Grid.Column
                              computer={8}
                              tablet={16}
                              mobile={16}
                              className="paddingRemoveBottom"
                            >
                              <InputText
                                register={register}
                                errors={errors.product_name}
                                labelName={"Name"}
                                placeholder="Name"
                                name="product_name"
                                required={true}
                                errorMessage={"Please enter product name"}
                              />
                            </Grid.Column>
                            <Grid.Column
                              computer={8}
                              tablet={16}
                              mobile={16}
                              className="paddingRemoveBottom"
                            >
                              <InputText
                                register={register}
                                errors={errors.registration_number}
                                labelName={"Register number"}
                                placeholder="Register number"
                                name="registration_number"
                                required={false}
                              />
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={16} mobile={16}>
                              <Switch
                                label={"Product Type"}
                                onClick={(data: any) => {
                                  setProductType(data);
                                }}
                                titleOne={"Processed Product"}
                                titleTwo={"Farm Product"}
                                defaultValue={"PROCESS"}
                                titleOneValue={"PROCESS"}
                                titleTwoValue={"FARM"}
                              />
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={16} mobile={16}>
                              <NewImageUploader
                                defaultUserImage={defaultUserImage}
                                buttonTitle={"Upload an Image"}
                                customImageViewMain={"customProductImageView"}
                                imageData={(data: any) => {
                                  setImageData(data);
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column
                              computer={16}
                              tablet={16}
                              mobile={16}
                              className="paddingRemoveTop"
                            >
                              <InputTextArea
                                register={register}
                                errors={errors.product_other_info}
                                labelName={"Additional Information"}
                                placeholder="Additional Information"
                                name="product_other_info"
                                customInputValidation={true}
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                      <TitleView title="Add Unit sizes" />
                      <Grid>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                          <form>
                            <Grid>
                              <Grid.Column computer={3} tablet={8} mobile={16}>
                                <InputText
                                  register={registerUnitSize}
                                  errors={errorsUnit.sales_unit_name}
                                  labelName={"Unit Name"}
                                  placeholder="Unit Name"
                                  name="sales_unit_name"
                                  required={true}
                                  errorMessage="Unit name is required"
                                />
                              </Grid.Column>
                              <Grid.Column computer={3} tablet={8} mobile={16}>
                                <InputText
                                  register={registerUnitSize}
                                  errors={errorsUnit.sales_unit_size}
                                  labelName={"Unit Size"}
                                  placeholder="Unit Size"
                                  minNumber={0}
                                  name="sales_unit_size"
                                  required={true}
                                  type="number"
                                  valueAsNumber={true}
                                  errorMessage="Unit size is required"
                                />
                              </Grid.Column>
                              <Grid.Column computer={3} tablet={8} mobile={16}>
                                <DropDown
                                  labelName={"Unit Type"}
                                  placeholder="Type"
                                  currentData={UNIT_TYPES}
                                  defaultValue={defaultType}
                                  customGridColumn={"customGridColomnType"}
                                  handleChangeState={(e: any, { value }: any) => {
                                    setDefaultType(value);
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column computer={4} tablet={8} mobile={16}>
                                <InputText
                                  register={registerUnitSize}
                                  errors={errorsUnit.ean_barcode}
                                  labelName={"EAN Barcode"}
                                  placeholder="EAN Barcode"
                                  name="ean_barcode"
                                />
                              </Grid.Column>

                              <Grid.Column computer={3} tablet={8} mobile={16}>
                                <CustomButton
                                  theme="green"
                                  customColumnStyle={"addUnitSize"}
                                  icon
                                  iconChild={<PlusIcon/>}
                                  onClick={handleSubmitUnitSize((data) => {
                                    addUnitData(data);
                                  })}
                                  customBase="productUnitAddButton"
                                />
                              </Grid.Column>
                            </Grid>
                            <Grid.Column
                              computer={16}
                              tablet={16}
                              mobile={16}
                              className="productRegisterTable"
                            >
                              {addProductUnit.length > 0 && (
                                <CommonTable tableHeaderData={ProductUnitTable}>
                                  {loadTableData()}
                                </CommonTable>
                              )}
                            </Grid.Column>
                          </form>
                        </Grid.Column>
                      </Grid>
                    </div>
                  }
                  bottomChild={
                    <MainBottomButtonView customBottomContentMain={"productRegisterBottomContain"} cancelButtonStatus
                      saveButtonStatus
                      cancelButton={cancel}
                      saveButton={saveProduct} />
                  }
                />
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default ProductRegisterModal;
