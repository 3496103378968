import React, { useEffect } from "react";
import { Checkbox, Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  InputText,
  CustomButton,
  InputTextArea,
  CommonModal,
  TitleView,
} from "../../../components";
import CommonSplitContainer from "../../../components/commonSplitContainer/CommonSplitContainer";
import SupplierIngredientList from "./SupplierIngredientList";

import "./supplierDetails.scss";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
interface Supplier {
  id?: string;
  supplier_name?: string;
  supplier_contact_name?: string;
  supplier_email?: string;
  supplier_phone?: string;
  supplier_address?: string;
  supplier_province?: string;
  supplier_country?: string;
  supplier_postal_code?: string;
  supplier_other_info?: string;
  is_supplier_as_user?: boolean;
  ingredients?: any[];
  supplierIngredientDetails?: any[];
}

interface SupplierDetailsModalProps {
  visibleModal: boolean;
  setVisibleModal: React.Dispatch<React.SetStateAction<boolean>>;
  supplier: Supplier | any;
  countriesData: any,
  setVisibleEdit: any
}

const SupplierDetailsModal: React.FC<SupplierDetailsModalProps> = ({
  visibleModal,
  setVisibleModal,
  supplier,
  countriesData,
  setVisibleEdit = () => { }
}) => {
  const { register, setValue } = useForm({
    values: supplier,
  });

  useEffect(() => {
    const currentCountry = countriesData?.find((e: any) => e.key == supplier?.supplier_country || 0)
    setValue("supplier_country", currentCountry?.text || "")
  }, [countriesData, supplier])

  const ingredients = supplier?.ingredients || [];
  const ingredientDetails = supplier?.supplierIngredientDetails || [];
  const supplierAsUser = supplier?.is_supplier_as_user || false;

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      title="Supplier Details"
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div>
            <form>
              <CommonSplitContainer
                topChild={
                  <>
                    <TitleView
                      title="Supplier Details"
                      CustomTitleViewMain="mt-0"
                    />
                    <Grid>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputText
                          register={register}
                          labelName={"Supplier Name"}
                          placeholder="Supplier Name"
                          name="supplier_name"
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputText
                          register={register}
                          labelName={"Supplier Contact Name"}
                          placeholder="Supplier Contact Name"
                          name="supplier_contact_name"
                          required
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputText
                          register={register}
                          labelName={"Email"}
                          placeholder="Email"
                          name="supplier_email"
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputText
                          register={register}
                          labelName={"Contact Number"}
                          placeholder="Contact Number"
                          name="supplier_phone"
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputText
                          register={register}
                          labelName={"Address"}
                          placeholder="Address"
                          name="supplier_address"
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputText
                          register={register}
                          labelName={"Province"}
                          placeholder="Province"
                          name="supplier_province"
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputText
                          register={register}
                          labelName={"Country"}
                          placeholder="Country"
                          name="supplier_country"
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputText
                          register={register}
                          labelName={"Zip Code"}
                          placeholder="Zip Code"
                          name="supplier_postal_code"
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={16}
                        tablet={16}
                        mobile={16}
                        className="paddingRemoveBottom"
                      >
                        <InputTextArea
                          register={register}
                          labelName={"Other Information"}
                          placeholder="Other Information"
                          name="supplier_other_info"
                          disabled={true}
                          customText={"customSupplierDetailsInput"}
                          customGridColumn={"supplierDetailsView"}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        className="customCheckpointSupplierUser mb-2"
                      >
                        <Checkbox
                          checked={supplierAsUser}
                          disabled
                        />
                        <p className="registerSupplierRegister">Register supplier as user</p>
                      </Grid.Column>
                    </Grid>
                    <SupplierIngredientList
                      ingredients={ingredients}
                      ingredientDetails={ingredientDetails}
                    />
                  </>
                }
                bottomChild={
                  <MainBottomButtonView
                    cancelButtonStatus
                    saveButtonStatus
                    saveTitle={"Edit"}
                    saveButton={setVisibleEdit}
                    cancelButton={() => setVisibleModal(false)}
                  />
                }
              />
            </form>
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default SupplierDetailsModal;

