import { useState } from "react";
import { Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { format } from "date-fns";

import { TitleBar } from "../../components/titleBar/Titlebar";
import { useGetNotification } from "../../api/notification";
import { useAuthContext } from "../../hooks/useAuthContext";
import { CommonTable } from "../../components";
import { NotificationTableCok } from "../../config/constants";

import "./notificationList.scss";
import PaginationView from "../../components/pagination/Pagination";

const NotificationList = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { user, newNotification } = useAuthContext();

  const {
    isLoading: isNotificationLoad,
    data: notificationData
  } = useGetNotification(user, newNotification, pageNumber, pageSize);

  const totalPages = (notificationData?.totalCount || 0) / (pageSize || 0);

  if (isNotificationLoad) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const loadTableData = () => {
    return notificationData?.list?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.title}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.body}</p>
          </Table.Cell>
          <Table.Cell>
            <p>
              {format(new Date(data.createdAt || new Date()), "dd/MM/yyyy")}
            </p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div>
      <TitleBar
        enableTitle={true}
        titleName={"Notification"}
        customPageButtonContainer="product-title-container"
        customButtonMain="title-button"
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable
            tableHeaderData={NotificationTableCok}
            singleLine={false}
          >
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <PaginationView
        currentPageNo={pageNumber}
        totalPages={totalPages}
        pageSize={pageSize}
        currentSelectPage={(page: number) => {
          setPageNumber(page);
        }}
        changePageSize={(pageSize: number) => {
          setPageNumber(1);
          setPageSize(pageSize);
        }}
      />
    </div>
  );
};

export default NotificationList;
