import { useMutation } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "../apiService";

export const usePostSimulationChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.post(
      `/farm-gate/processing-chemical?processingId=${data?.processingId}`,
      data.chemicalData
    );
  }, opt);
};

export const usePutSimulationChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.put(
      `/farm-gate/processing-chemical/${data.chemicalId}`,
      data.chemicalData
    );
  }, opt);
};

export const useDeleteSimulationChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.delete(
      `/farm-gate/processing-chemical/${data.chemicalData.chemicalId}?processingId=${data?.chemicalData.processingId}`
    );
  }, opt);
};

export const usePostSimulationChemicalTransportData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.post(
      `/farm-gate/processing-chemical-transport?chemicalId=${data?.chemicalId}`,
      data.transportData
    );
  }, opt);
};

export const usePutSimulationChemicalTransportData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.put(
      `/farm-gate/processing-chemical-transport/${data.chemicalTransportId}`,
      data.transportData
    );
  }, opt);
};

export const useDeleteSimulationChemicalTransportData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.delete(
      `/farm-gate/processing-chemical-transport/${data.chemicalTransportId}?chemicalId=${data?.chemicalId}`
    );
  }, opt);
};

export const usePostPGSimulationChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.post(
      `/process-gate/processing-chemical?processingId=${data?.processingId}`,
      data.chemicalData
    );
  }, opt);
};

export const usePutPGSimulationChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.put(
      `/process-gate/processing-chemical/${data.chemicalId}`,
      data.chemicalData
    );
  }, opt);
};

export const useDeletePGSimulationChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.delete(
      `/process-gate/processing-chemical/${data.chemicalData.chemicalId}?processingId=${data?.chemicalData.processingId}`
    );
  }, opt);
};

export const usePostPGSimulationChemicalTransportData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.post(
      `/process-gate/processing-chemical-transport?chemicalId=${data?.chemicalId}`,
      data.transportData
    );
  }, opt);
};

export const usePutPGSimulationChemicalTransportData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.put(
      `/process-gate/processing-chemical-transport/${data.chemicalTransportId}`,
      data.transportData
    );
  }, opt);
};

export const useDeletePGSimulationChemicalTransportData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.delete(
      `/process-gate/processing-chemical-transport/${data.chemicalTransportId}?chemicalId=${data?.chemicalId}`
    );
  }, opt);
};
