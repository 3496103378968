import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiArrowDownDropCircleOutline,
  mdiArrowRightDropCircleOutline,
  mdiFileSearchOutline,
  mdiPencil,
  mdiDeleteOutline,
} from "@mdi/js";
import {
  Checkbox,
  Grid,
  PaginationProps,
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
  Table,
} from "semantic-ui-react";

import "./SimulationTable.scss";
import { warningMessage } from "../../../helpers/ErrorHandler";
import {
  SimulationImpactFarm,
  SimulationImpactProcess,
  SimulationNetZeroDataTable,
} from "../../../config/constants";
import { CommonTable } from "../../../components";
import PaginationView from "../../../components/pagination/Pagination";

interface SimulationTableProps {
  data: DrawingSimulationData[] | undefined;
  onEdit: (data: DrawingSimulationData, isView?: boolean) => void;
  onDelete: (data: DrawingSimulationData) => void;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  pageNumber: number;
  pageSize: number;
  totalSimulations: number;
  isGetProductSimulationsLoading: boolean;
  currentDrawingId: (id: string) => void;
}

type ClimateImpactData = {
  processGates?: any[];
  farmGates?: any[];
  externalStorages?: { storage?: any[] };
  externalTransports?: { transport?: any[] };
};

const LoadingComponent = () => (
  <Placeholder fluid>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
  </Placeholder>
);

const SimulationTable: React.FC<SimulationTableProps> = ({
  data,
  onEdit,
  onDelete,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
  totalSimulations,
  isGetProductSimulationsLoading,
  currentDrawingId,
}) => {
  const [totalPages, setTotalPages] = useState(10);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [selectNetZeroId, setSelectNetZeroId] = useState<any>(null);

  useEffect(() => {
    setTotalPages(Math.ceil(totalSimulations / pageSize));
  }, [totalSimulations, pageSize]);

  const handlePaginationChange = (
    e: any,
    { activePage, totalPages }: PaginationProps
  ) => {
    if (typeof activePage === "number") setPageNumber(activePage);
    if (typeof totalPages === "number") setTotalPages(totalPages);
  };

  const getCurrentImpact = (
    originalImpact: number,
    simulatedImpact: number
  ) => {
    return simulatedImpact
      ? Math.fround(((simulatedImpact - originalImpact) / originalImpact) * 100)
      : 0;
  };

  const scaleFactor = (
    filterOtherData: any[],
    simulationOriginalImpact: number,
    originalImpact: number
  ) => {
    const percentageChange = filterOtherData.reduce((acc, curr) => {
      return acc + getCurrentImpact(originalImpact, curr.totalImpact);
    }, 0);

    const scalingFactorValue =
      Number(getCurrentImpact(originalImpact, simulationOriginalImpact)) /
      percentageChange;
    return scalingFactorValue;
  };

  const handleRowClick = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);
    setExpandedRows(newExpandedRows);
  };

  const checkNetZero = (item: any, data: any) => {
    if (item._id === selectNetZeroId) return;
    setSelectNetZeroId(item._id);
    currentDrawingId(item._id);
  };

  const renderItemCaret = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return mdiArrowDownDropCircleOutline;
    } else {
      return mdiArrowRightDropCircleOutline;
    }
  };

  const renderItem = (item: DrawingSimulationData, index: number) => {
    const simulationProductSummaryData: any =
      item?.simulationProductSummary || [];

    const originalImpact = item?.productSummary?.totalImpact;
    const filterOriginalData = item.originalSimulationId
      ? simulationProductSummaryData?.find(
          (e: any) => e._id === item.originalSimulationId
        )
      : [];
    const filterOtherData = simulationProductSummaryData?.filter(
      (e: any) => e._id !== item.originalSimulationId
    );
    const simulationOriginalImpact = filterOriginalData
      ? filterOriginalData
      : {};

    const scaleFactorValue = scaleFactor(
      filterOtherData,
      simulationOriginalImpact?.totalImpact,
      originalImpact
    );

    const itemRows: any = [
      <Table.Row key={`row-data-${index}`}>
        <Table.Cell
          onClick={() => {
            filterOtherData.length > 0
              ? handleRowClick(index)
              : warningMessage("Simulation Data not available");
          }}
        >
          <Icon
            className="table-icon"
            color="var(--tableEditIcon)"
            path={renderItemCaret(index)}
            size={1.2}
          />
        </Table.Cell>
        <Table.Cell>{item?.drawingName}</Table.Cell>
        <Table.Cell>{originalImpact?.toFixed(5)}</Table.Cell>
        <Table.Cell>{simulationOriginalImpact.totalImpact?.toFixed(5)}</Table.Cell>
        <Table.Cell>
          {getCurrentImpact(
            originalImpact,
            simulationOriginalImpact.totalImpact
          ).toFixed(4)}
        </Table.Cell>
        <Table.Cell>
          <Grid>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onEdit(item, true);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiFileSearchOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onEdit(item);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiPencil}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onDelete(item);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiDeleteOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Table.Cell>
        <Table.Cell className="netZeroContentCheckbox">
          <Checkbox
            checked={item._id === selectNetZeroId}
            onChange={(e, data) => checkNetZero(item, data)}
          />
        </Table.Cell>
      </Table.Row>,
    ];

    if (expandedRows.includes(index)) {
      filterOtherData.map((data: any) => {
        return itemRows.push(
          <Table.Row key={"row-expanded-" + index}>
            <Table.Cell></Table.Cell>
            <Table.Cell>{data?.name}</Table.Cell>
            <Table.Cell>{originalImpact?.toFixed(5)}</Table.Cell>
            <Table.Cell>{data?.totalImpact?.toFixed(5)}</Table.Cell>
            <Table.Cell>
              {(
                Number(getCurrentImpact(originalImpact, data?.totalImpact)) *
                scaleFactorValue
              ).toFixed(4)}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        );
      });
    }

    return itemRows;
  };

  return (
    <Grid>
      <Grid.Column computer={16}>
        <CommonTable tableHeaderData={SimulationNetZeroDataTable}>
          {isGetProductSimulationsLoading && (
            <Table.Row>
              <Table.Cell colSpan="7">
                <LoadingComponent />
              </Table.Cell>
            </Table.Row>
          )}
          {!isGetProductSimulationsLoading &&
            data?.map((item: DrawingSimulationData, index) =>
              renderItem(item, index)
            )}
        </CommonTable>
      </Grid.Column>
      <Grid.Column computer={16}>
        <PaginationView
          changePageSize={setPageSize}
          pageSize={pageSize}
          totalPages={totalPages}
          currentPageNo={pageNumber}
          currentSelectPage={handlePaginationChange}
        />
      </Grid.Column>
    </Grid>
  );
};

export default SimulationTable;
