import { useEffect, useMemo, useState } from "react";
import SupplerRegister from "../../ingredientRegister/SupplierRegister";
import IngredientPackagingView from "../../ingredientRegister/IngredientPackageing";
import { Image, Message } from "semantic-ui-react";
import { useGetIngredientPackaging } from "../../../../api/ingredients";
import TransportationDetails from "../../transportationDetails/TransportationDetails";
import { getUserIdInLocal } from "../../../../utils/cacheStorage";
import { ROLES } from "../../../../config/permission-maps";
import { useGetMappingImageUri } from "../../../../api/drawingTool";
import { MappingSourceType } from "../../../../config/enums/MappingSourceType.enum";

const IngredientSummaryTab = ({ ingredientIdToUpdate, visibleModal }: any) => {
  let ingredientId = ingredientIdToUpdate?.ingredient_id || null;
  let supplierId = ingredientIdToUpdate?.supplier_id || null;

  const [viewPackage, setViewPackage] = useState<any>({
    processIngredient: false,
    produceIngredient: false,
    enableIngredient: false,
  });

  const [viewEnvironmentalData, setViewEnvironmentalData] =
    useState<boolean>(false);

  const packageDataView = viewPackage.enableIngredient
    ? true
    : false;
  const transportDataView = viewPackage.processIngredient ? true : false;

  const supplierMappingImpact =
    ingredientIdToUpdate?.supplierIngredientDetails?.product_summary
      ?.displayTotalImpactClimateChange || null;

  let getPackagingData = {
    ingredientId: ingredientId,
    supplierId: supplierId,
    loadPackageData: visibleModal,
  };

  const { data: ingredientPackageData, refetch: ingredientPackageDataRefetch } =
    useGetIngredientPackaging(getPackagingData);

  const { data: signedUrlData } = useGetMappingImageUri(
    MappingSourceType.product,
    ingredientIdToUpdate?.supplierIngredientDetails?.product_summary?.imageKey
  );

  const localUserRole = getUserIdInLocal();
  const checkIsUserSupplier = localUserRole?.[0] === ROLES.supplier;

  const enablePackageData = useMemo(() => {
    return packageDataView && viewEnvironmentalData
  }, [packageDataView, viewEnvironmentalData])


  const enableTransportData = useMemo(() => {
    const enableTransportDetails = transportDataView && viewEnvironmentalData
    return enableTransportDetails || (!enableTransportDetails && packageDataView)
  }, [transportDataView, viewEnvironmentalData])

  useEffect(() => {
    if (
      ingredientIdToUpdate &&
      ingredientIdToUpdate.supplierIngredientDetails?.data_type
    ) {
      setViewEnvironmentalData(true);
    }
  }, [ingredientIdToUpdate]);
  return (
    <>
      <SupplerRegister
        ingredientSupplierList={[]}
        ingredientData={ingredientIdToUpdate}
        supplierCurrentData={() => { }}
        ingredientList={[]}
        disableEdit={true}
        dataView={true}
        changeDataIngredient={setViewPackage}
        setViewEnvironmentalData={setViewEnvironmentalData}
        viewEnvironmentalData={viewEnvironmentalData}
      />
      {enablePackageData ? (
        <IngredientPackagingView
          ingredientId={ingredientId}
          supplierId={supplierId}
          refetchPackagingData={ingredientPackageDataRefetch}
          ingredientPackageData={ingredientPackageData}
          dataView={true}
        />
      ) : null}
      {enableTransportData ?
        <TransportationDetails ingredientId={ingredientId} disabled={true} /> : null
      }
      {supplierMappingImpact ? (
        <Message className="climateImpactMessage">
          Total impact climate change :{" "}
          <span className="climateImpactValue">
            {checkIsUserSupplier
              ? "Calculated: Upgrade to see results"
              : supplierMappingImpact}
          </span>
        </Message>
      ) : null}
      {signedUrlData ? (
        <Image src={signedUrlData?.signedUrl} size="large" rounded />
      ) : null}
    </>
  );
};

export default IngredientSummaryTab;
