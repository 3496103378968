import * as React from "react";
import { getPathName } from "../../utils/utils";
const ProductIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "/product"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M15.75.023 24 4.148v9.2l-1.5-.75V5.824l-6 3v3.024l-1.5.75V8.824l-6-3v2.66l-1.5-.75V4.148L15.75.023Zm0 7.5 2.074-1.043-5.426-3.105-2.472 1.242 5.824 2.906Zm3.691-1.84 2.133-1.066L15.75 1.7l-1.746.88 5.437 3.105ZM13.5 13.349l-1.5.75v-.012l-4.5 2.25v5.332l4.5-2.262v1.688l-5.25 2.625L0 20.332V12.41l6.75-3.375 6.75 3.375v.938ZM6 21.668v-5.332l-4.5-2.25v5.32L6 21.668Zm.75-6.633 4.324-2.156L6.75 10.71l-4.324 2.168 4.324 2.156Zm6.75-.012 5.25-2.625L24 15.023V21.2l-5.25 2.625L13.5 21.2v-6.176Zm4.5 6.75V18.2l-3-1.5v3.574l3 1.5Zm4.5-1.5V16.7l-3 1.5v3.574l3-1.5Zm-3.75-3.375 2.824-1.418-2.824-1.406-2.824 1.406 2.824 1.418Z"
      />
    </svg>
  );
};
export default ProductIcon;
