import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { MappingSourceType } from "../../config/enums/MappingSourceType.enum";
import { successMessage } from "../../helpers/ErrorHandler";
import { ApiServiceErr, axiosApi } from "../apiService";

interface GetProductSimulationsOptions {
  pageNumber: number;
  pageSize: number;
  ownerId?: string;
  sourceId?: string;
}

export const useCreateSimulateDrawing = (opt?: UseMutationOptions) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/simulation-drawing-tool`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetSimulationDrawing = (
  params: GetProductSimulationsOptions
) => {
  return useQuery<any, ApiServiceErr>(
    ["getSimulationDrawingPageData", params],
    async () => {
      const response = await axiosApi.get(
        `/drawing-tool/${MappingSourceType.simulation}/simulations`,
        {
          params,
        }
      );
      return response.data;
    },
    {
      enabled: !!params.sourceId && !!params.ownerId,
    }
  );
};

export const useGetSimulationDrawingById = (
  simulationDrawingId: string | null
) =>
  useQuery<any, ApiServiceErr>(
    [simulationDrawingId, "useGetSimulationDrawingById"],
    async () => {
      const response = await axiosApi.get(
        `/drawing-tool/${MappingSourceType.simulation}/${simulationDrawingId}`
      );
      return response.data;
    },
    {
      enabled: !!simulationDrawingId,
    }
  );

export const useUpdateSimulateDrawing = (opt?: UseMutationOptions) =>
  useMutation<any, ApiServiceErr, any>(
    async ({ data, simulationDrawingId }) => {
      const response = await axiosApi.put(
        `/drawing-tool/${MappingSourceType.simulation}/${simulationDrawingId}`,
        data
      );
      successMessage(response.data);
      return response.data;
    },
    opt
  );

export const usePutSimulationMappingDataToQueue = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (drawingId) => {
    const response = await axiosApi.put(
      `/drawing-tool/${MappingSourceType.simulation}/queue/${drawingId}`
    );
    successMessage(response.data);
    return response.data;
  }, opt);
