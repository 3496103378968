import { useCallback, useContext, useMemo } from "react";
import { isEmpty } from "lodash";
import { useReactFlow } from "reactflow";

import { CommonModal } from "../../../components";
import { nodeType } from "../../../config/drawingConstants";
import { DISABLE_CLOSE_MODEL_ID } from "../../../config/constants";
import { checkNodeModalValidation } from "../utils";
import { errorView } from "../../../helpers/ErrorHandler";
import { getNewDaringItemStatus } from "../../../utils/cacheStorage";
import useWindowDimensions from "../../../hooks/windowsSize";
import { IngredientBase } from "./IngredientBase";
import { InternalTransportBase } from "./FarmGate/Transport/InternalTransportBase";
import { TransportBase } from "./TransportBase";
import { InternalStorageBase } from "./FarmGate/Storage/InternalStorageBase";
import { FarmGateProcessBase } from "./FarmGate/Processing/FarmGateProcessBase";
import { FarmGatePackagingBase } from "./FarmGate/Packaging/FarmGatePackagingBase";
import { WarehouseBase } from "./WarehouseBase";
import { ClientBase } from "./ClientBase";
import { CultivarBase } from "./CultivarBase";
import { ProcessInternalTransportBase } from "./ProcessGate/Transport/InternalTransportBase";
import { ProcessInternalStorageBase } from "./ProcessGate/Storage/InternalStorageBase";
import { ProcessGateProcessingBase } from "./ProcessGate/Processing/ProcessGateProcessBase";
import { ProcessGatePackagingBase } from "./ProcessGate/Packaging/ProcessGatePackagingBase";

import "./UpdateNodeModal.scss";
import { DrawingContext } from "../../../components/drawing-tool/DrawingTool";

interface UpdateNodeModalProps {
  sourceId: string | undefined;
  ownerId: string | undefined;
}

const UpdateNodeModal = (props: UpdateNodeModalProps) => {
  const { sourceId, ownerId } = props;

  const { nodeItemId, chartNodes, chartEdges, setNodeItem } =
    useContext(DrawingContext);
  const reactFlow = useReactFlow();
  const height = useWindowDimensions();

  const nodeItem = useMemo(() => {
    const getIconId = nodeItemId?.data?.icon || 0;
    const checkDrawingIsNew = getNewDaringItemStatus();
    if (
      nodeItemId?.id &&
      DISABLE_CLOSE_MODEL_ID.includes(getIconId) &&
      !isEmpty(checkDrawingIsNew)
    ) {
      return reactFlow.getNode(nodeItemId.id);
    }
    return reactFlow.getNode(nodeItemId || "");
  }, [nodeItemId, reactFlow]);

  const nodeTypeContent = useCallback(() => {
    const { status, data, errorMessage } = checkNodeModalValidation(
      nodeItem,
      chartNodes,
      chartEdges,
      sourceId,
      ownerId
    );
    if (!status) {
      setNodeItem(null);
      errorView(errorMessage);
      return null;
    }

    const nodeContentMap: { [key: string]: JSX.Element } = {
      [nodeType.Ingredients]: <IngredientBase />,
      [nodeType.Client]: <ClientBase modalData={data} />,
      [nodeType.FarmGateInternalTransport]: (
        <InternalTransportBase modalData={data} />
      ),
      [nodeType.Transportation]: <TransportBase modalData={data} />,
      [nodeType.FarmGateInternalStorage]: (
        <InternalStorageBase modalData={data} />
      ),
      [nodeType.FarmGateProcess]: <FarmGateProcessBase modalData={data} />,
      [nodeType.FarmGatePacking]: <FarmGatePackagingBase modalData={data} />,
      [nodeType.Warehouse]: <WarehouseBase />,
      [nodeType.Cultivar]: <CultivarBase modalData={data} />,
      [nodeType.ProcessGateInternalTransport]: (
        <ProcessInternalTransportBase modalData={data} />
      ),
      [nodeType.ProcessGateInternalStorage]: (
        <ProcessInternalStorageBase modalData={data} />
      ),
      [nodeType.ProcessGatePacking]: (
        <ProcessGatePackagingBase modalData={data} />
      ),
      [nodeType.ProcessGateProcessing]: (
        <ProcessGateProcessingBase modalData={data} />
      ),
    };

    return nodeContentMap[nodeItem?.data.icon] || null;
  }, [nodeItem, chartNodes, chartEdges, sourceId, ownerId, setNodeItem]);

  const nodeTypeHeader = useMemo(() => {
    const nodeHeaderMap: { [key: string]: string } = {
      [nodeType.Ingredients]: "Ingredients",
      [nodeType.FarmGateInternalTransport]: "Internal Transportation",
      [nodeType.Transportation]: "External Transportation",
      [nodeType.FarmGateInternalStorage]: "Internal Storage",
      [nodeType.FarmGateProcess]: "Processing Information",
      [nodeType.Warehouse]: "External Warehouse",
      [nodeType.Client]: "Client",
      [nodeType.FarmGatePacking]: "Packaging Information",
      [nodeType.Cultivar]: "Cultivar",
      [nodeType.ProcessGateInternalTransport]:
        "Process Internal Transportation",
      [nodeType.ProcessGateInternalStorage]: "Process Internal Storage",
      [nodeType.ProcessGatePacking]: "Packaging Information",
      [nodeType.ProcessGateProcessing]: "Processing Information",
    };

    return nodeHeaderMap[nodeItem?.data.icon] || "";
  }, [nodeItem]);

  return (
    <CommonModal
      modalOpenState={!!nodeItem}
      modalCloseState={() => setNodeItem(null)}
      centered={true}
      title={`Compare ${nodeTypeHeader} Simulation`}
      customDimmer={"custom-dimmer-modal-simulation"}
      style={height}
    >
      {nodeTypeContent()}
    </CommonModal>
  );
};

export default UpdateNodeModal;
