import { useEffect, useState } from "react";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InputText, CustomButton } from "../../components";
import "./Login.scss";
import { usePostUserDetails, usePostUserLogin } from "../../api/userDetails";
import {
  saveCompanyTenantId,
  saveCompanyTokenInLocal,
  saveRefreshTokenInLocal,
  saveTokenExpiration,
  saveTokenInLocal,
  saveUserTenantId,
} from "../../utils/cacheStorage";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useGetCompanyName, useGetCompanyToken } from "../../api/company";
import { errorView } from "../../helpers/ErrorHandler";
import { removeTokens } from "../../utils/cacheStorage";
import { checkAvailableUserCompanyId } from "../../utils/utils";
import { setupNotifications } from "../../config/firebase";
import VisiblePasswordIcon from "../../assets/commonIcon/VisiblePasswordIcon";
import HiddenPasswordIcon from "../../assets/commonIcon/HiddenPasswordIcon";

const Login = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { dispatch }: any = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: userData, isLoading: userLoading, mutate } = usePostUserLogin();

  const {
    data: userProfileData,
    isLoading = false,
    mutate: loadUserData,
  } = usePostUserDetails();

  const {
    data: company,
    status,
    fetchStatus,
  } = useGetCompanyName(userProfileData?.tenantId);

  const { isLoading: loadCompanyToken, mutate: getCompanyToken } =
    useGetCompanyToken();

  const loadUserDetails = (userData: any) => {
    if (userData?.isPasswordResetRequired) {
      navigate("/password-reset", {
        state: {
          isPasswordResetRequired: userData?.isPasswordResetRequired || false,
          email: userEmail,
        },
      });
    } else {
      loadUserData("");
    }
  };

  const loadCompanyDetials = () => {
    let { id, status, name } = checkAvailableUserCompanyId(
      company,
      userProfileData
    );
    if (status) {
      getCompanyToken(id, {
        onSuccess: (data) => {
          saveCompanyToken(data);
        },
      });
      dispatch({ type: "SET_COMPANY", payload: name });
    } else {
      errorView("User hasn't any company");
      removeTokens();
    }
  };

  const saveCompanyToken = (data: any) => {
    saveCompanyTokenInLocal(data?.token || null);
    saveRefreshTokenInLocal(data?.refreshToken || null);
    saveTokenExpiration(data?.expiredIn || null);
    saveCompanyTenantId(userProfileData?.tenantId || null);
    saveUserTenantId(userProfileData?.tenantId || null);
    requestPermission(userProfileData);
    if (userData) {
      if (userData?.isPasswordResetRequired || false) {
        navigate("/password-reset", {
          state: {
            isPasswordResetRequired: userData?.isPasswordResetRequired || false,
            email: userEmail,
          },
        });
      } else {
        if (userProfileData && data) {
          dispatch({ type: "LOGIN", payload: userProfileData });
          navigate("/", {
            state: { isPasswordResetRequired: false, email: userEmail },
          });
        }
      }
    }
  };

  const requestPermission = (userProfileData: any) => {
    setupNotifications(userProfileData, () => {});
  };

  useEffect(() => {
    if (status == "success") {
      loadCompanyDetials();
    }
  }, [status == "success"]);

  const onSubmit = async (data: any) => {
    setUserEmail(data.email);
    mutate(data, {
      onSuccess: (data) => {
        saveTokenInLocal(data.token);
        saveRefreshTokenInLocal(data.refreshToken);
        saveTokenExpiration(data.expiredIn);
        loadUserDetails(data);
      },
    });
  };

  if (
    userLoading ||
    isLoading ||
    loadCompanyToken ||
    (status == "loading" && fetchStatus == "fetching")
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <form className="mainContaa" onSubmit={handleSubmit(onSubmit)}>
        <Grid className="backgroundLoginMain">
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <span className="welcomeTxt">Welcome!</span>
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <span className="secondaryTxt">Please sign in to continue.</span>
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <InputText
              register={register}
              errors={errors.email}
              required={true}
              labelName={"Username*"}
              placeholder="Enter Username"
              name="email"
              errorMessage="Please enter email"
            />
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <InputText
              register={register}
              errors={errors.password}
              required={true}
              labelName={"Password*"}
              placeholder="Enter Password"
              name="password"
              type={showPassword ? "text" : "password"}
              errorMessage="Please enter password"
              rightContentEnable={true}
              rightContent={
                showPassword ? (
                  <HiddenPasswordIcon
                    onClick={() => setShowPassword(false)}
                    className="passwordIcon"
                  />
                ) : (
                  <VisiblePasswordIcon
                    onClick={() => setShowPassword(true)}
                    className="passwordIcon"
                  />
                )
              }
            />
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <a
              className="forgotTxt"
              onClick={() => navigate("/password-forgot")}
            >
              Forgot Your Password?
            </a>
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "15px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <CustomButton
              theme="green"
              title={"Login"}
              customButtonStyle={"customBtnn"}
              type={"submit"}
            />
          </Grid.Column>
        </Grid>
      </form>
    </>
  );
};
export default Login;
