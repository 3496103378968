import React, { useEffect, useState } from "react";
import { MdUpload } from "react-icons/md";
import { Icon } from "semantic-ui-react";

import { ImageView } from "../ImageView/ImageView";

import "./imageUploaderNew.scss";

export const NewImageUploader = ({
  defaultImage = null,
  deleteButtonView = true,
  uploadButtonView = true,
  customImageViewMain,
  customFileView,
  buttonTitle = "Add Image",
  imageData,
  deleteImage,
  customImageUploadMain,
  mainTitle = "Product Image",
  customImageDeleteMain,
}: any) => {
  const [file, setFile] = useState<any>(defaultImage);

  useEffect(() => {
    setFile(defaultImage);
  }, [defaultImage]);

  function handleFileChange(e: any) {
    if (e.target.files) {
      setFile(URL.createObjectURL(e.target.files[0]));
      imageData(e.target.files[0]);
    }
  }

  return (
    <div className={`imageUploaderMain ${customImageUploadMain}`}>
      <p className="labelUploder">{mainTitle}</p>
      {file ? (
        <>
          <ImageView
            avatar={false}
            src={defaultImage ? defaultImage : file}
            addRandomId={false}
            customImageView={`${customImageViewMain} imageViewMainOther`}
          />
          {deleteButtonView ? (
            <div
              className={`deleteButtonContentMain ${customImageDeleteMain}`}
              onClick={() => {
                setFile(null);
                imageData(null);
                deleteImage();
              }}
            >
              <Icon name={"trash alternate"} size={"large"} color="black" />
            </div>
          ) : null}
        </>
      ) : null}
      {uploadButtonView ? (
        <label className={`${customFileView} custom-file-upload`}>
          <input type="file" onChange={handleFileChange} />
          <MdUpload size={18} className="image" />
          {buttonTitle}
        </label>
      ) : null}
    </div>
  );
};
