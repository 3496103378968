import React, { ReactNode } from 'react';
import useWindowDimensions from '../../hooks/windowsSize';

interface CommonSplitContainerProps {
    topChild?: ReactNode;
    bottomChild?: ReactNode;
    currentHeight?:number
}

const CommonSplitContainer: React.FC<CommonSplitContainerProps> = ({ topChild, bottomChild, currentHeight = 0.9 }) => {
    const { height } = useWindowDimensions();
    return (
        <>
            <div
                style={{
                    height: height * currentHeight - 100,
                    overflowX: "hidden",
                    scrollbarWidth: "none"
                }}
            >
                {topChild}
            </div>
            {bottomChild}
        </>
    );
};

export default CommonSplitContainer;
