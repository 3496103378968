import { useState } from "react";
import { Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { CompanyTableCol } from "../../../config/constants";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import CompanyRegisterModal from "../companyRegister/CompanyRegisterModal";
import CompanyDetailsModal from "../companyDetails/CompanyDetailsModal";
import CompanyEditModal from "../companyEdit/CompanyEditModal";
import { useDeleteCompanyData, useGetCompanyList } from "../../../api/company";
import { successMessage } from "../../../helpers/ErrorHandler";
import PaginationView from "../../../components/pagination/Pagination";
import PlusIcon from "../../../assets/commonIcon/PlusIcon";

const CompanyList = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleProductDetailsModal, setVisibleProductDetailsModal] =
    useState(false);
  const [visibleProductEditModal, setVisibleProductEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [id, setId] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);

  const {
    data: company,
    isLoading: loadCompanyData,
    refetch,
  } = useGetCompanyList(pageNumber, pageLimit);

  const handlePagination = (currentPage: number) => {
    setPageNumber(currentPage);
  };

  const handlePageSize = (pageSize: number) => {
    setPageLimit(pageSize);
  };

  const { isLoading, isSuccess = false, mutate } = useDeleteCompanyData();

  const getCompanyDetails = (id: any, type: any) => {
    setId(id);
    if (type == "edit") {
      setVisibleProductEditModal(true);
    } else {
      setVisibleProductDetailsModal(true);
    }
  };

  const deleteProductDetails = (data: any) => {
    mutate(data._id, {
      onSuccess: () => {
        successMessage("Company inactive successfully");
        setVisibleModal(false);
        refetch();
      },
    });
    setDeleteData({});
  };

  const loadTableData = () => {
    return company?.result?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.name}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.companyEmail}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.status}</p>
          </Table.Cell>
          <Table.Cell>
            {data.name !== "NP Admin Company" ? (
              <Grid>
                <Grid.Column computer={4} tablet={5} mobile={5}>
                  <TbFileSearch
                    cursor={"pointer"}
                    onClick={() => {
                      getCompanyDetails(data._id, "view");
                    }}
                    size={24}
                    color="var(--tableEditIcon)"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={5} mobile={5}>
                  <MdModeEditOutline
                    cursor={"pointer"}
                    onClick={() => {
                      getCompanyDetails(data._id, "edit");
                    }}
                    size={24}
                    color="var(--tableEditIcon)"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={5} mobile={5}>
                  <MdDeleteOutline
                    cursor={"pointer"}
                    size={24}
                    color="var(--tableEditIcon)"
                    onClick={() => {
                      setVisibleDeleteModal(true);
                      setDeleteData(data);
                    }}
                  />
                </Grid.Column>
              </Grid>
            ) : null}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  //handle loading
  if (loadCompanyData || isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar enableTitle={true} titleName={"Register Company"} />
      <CustomButton
        theme="green"
        title="Add New company"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconChild={<PlusIcon/>}
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={CompanyTableCol}>
            {loadTableData()}
          </CommonTable>
          <PaginationView
            changePageSize={handlePageSize}
            pageSize={pageLimit}
            totalPages={Math.ceil(company?.total / pageLimit)}
            currentPageNo={pageNumber}
            currentSelectPage={handlePagination}
          />
        </Grid.Column>
      </Grid>
      <CompanyRegisterModal
        visibleModal={visibleModal}
        setVisibleModal={() => {
          refetch();
          setVisibleModal(false);
        }}
      />
      <CompanyDetailsModal
        visibleModal={visibleProductDetailsModal}
        setVisibleModal={() => setVisibleProductDetailsModal(false)}
        id={id}
      />
      <CompanyEditModal
        visibleModal={visibleProductEditModal}
        setVisibleModal={() => {
          refetch();
          setVisibleProductEditModal(false);
        }}
        id={id}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deleteProductDetails(deleteData);
        }}
        title="Delete Company"
        subTitle="Are you sure you want to delete your company?"
      />
    </>
  );
};

export default CompanyList;
