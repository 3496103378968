import React from "react";
import {
  Grid,
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
  Table,
} from "semantic-ui-react";

import "./SimulationTable.scss";
import { SimulationDataTable } from "../../../../config/constants";
import { CommonTable } from "../../../../components";
import { getImpactChangePercentage } from "../../../../utils/utils";

interface SimulationTableProps {
  currentSimulationData: any | undefined;
  simulationDrawingData: any | undefined;
  isGetProductSimulationsLoading: boolean;
}

const LoadingComponent = () => (
  <Placeholder fluid>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
  </Placeholder>
);

const DrawingSimulationData: React.FC<SimulationTableProps> = ({
  simulationDrawingData,
  currentSimulationData,
  isGetProductSimulationsLoading,
}) => {
  const getChangedItem = (items: any[]) => {
    return items?.find((item) => item.changeStatus);
  };

  const filterNodeCalculation = (data: any) => {
    const item =
      getChangedItem(data.cultivation) ||
      getChangedItem(data.processes) ||
      getChangedItem(data.transportation) ||
      getChangedItem(data.storages) ||
      getChangedItem(data.packaging);
    const nodeImpactChange = getImpactChangePercentage(
      item?.originalCalculatedClimateImpact || 1,
      item?.calculatedClimateImpact || 0
    );
    return nodeImpactChange;
  };

  const scaleFactor = (
    filterOtherData: any[],
    simulationOriginalImpact: number,
    originalImpact: number
  ) => {
    const percentageChange = filterOtherData.reduce((acc, curr) => {
      return acc + getImpactChangePercentage(originalImpact, curr.totalImpact);
    }, 0);

    const scalingFactorValue =
      Number(
        getImpactChangePercentage(originalImpact, simulationOriginalImpact)
      ) / percentageChange;
    return scalingFactorValue;
  };

  const renderItem = () => {
    const originalImpact =
      currentSimulationData?.productSummary?.totalImpact?.climateChange
        ?.measure;

    const filterOriginalData = currentSimulationData?.originalSimulationId
      ? simulationDrawingData?.find(
          (e: any) => e._id === currentSimulationData?.originalSimulationId
        )
      : [];
    const filterOtherData = simulationDrawingData?.filter(
      (e: any) => e._id !== currentSimulationData?.originalSimulationId
    );
    const simulationOriginalImpact = filterOriginalData
      ? filterOriginalData
      : {};

    const scaleFactorValue = scaleFactor(
      filterOtherData,
      simulationOriginalImpact?.totalImpact,
      originalImpact
    );

    return simulationDrawingData.map((data: any) => {
      let nodeImpactChange = null;
      if (data.totalChanges > 0) {
        nodeImpactChange = filterNodeCalculation(data);
      }

      return (
        <Table.Row key={"row-expanded-"}>
          <Table.Cell>{data?.name}</Table.Cell>
          <Table.Cell>
            {
              currentSimulationData.productSummary?.totalImpact?.climateChange
                ?.measure?.toFixed(5)
            }
          </Table.Cell>
          <Table.Cell>{data?.totalImpact?.toFixed(5)}</Table.Cell>
          {/* <Table.Cell>{nodeImpactChange}</Table.Cell> */}
          <Table.Cell>
            {data._id === currentSimulationData?.originalSimulationId
              ? getImpactChangePercentage(
                  currentSimulationData.productSummary?.totalImpact
                    ?.climateChange?.measure || 0,
                  data?.totalImpact || 0
                )
              : (
                  getImpactChangePercentage(
                    currentSimulationData.productSummary?.totalImpact
                      ?.climateChange?.measure || 0,
                    data?.totalImpact || 0
                  ) * scaleFactorValue
                ).toFixed(4)}
          </Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Grid>
      <Grid.Column computer={16}>
        <CommonTable tableHeaderData={SimulationDataTable}>
          {isGetProductSimulationsLoading && (
            <Table.Row>
              <Table.Cell colSpan="7">
                <LoadingComponent />
              </Table.Cell>
            </Table.Row>
          )}
          {renderItem()}
        </CommonTable>
      </Grid.Column>
    </Grid>
  );
};

export default DrawingSimulationData;
