import { useMutation, useQuery } from "@tanstack/react-query";
import { errorView } from "../../helpers/ErrorHandler";
import { ApiServiceErr, MutOptions, axiosApi } from "../apiService";

export const useSimulationCultivationFormData = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (formData) => {
    return await axiosApi.post(`/farm-gate`, formData);
  }, opt);
};

export const useSimulationUpdateCultivationFormData = (
  opt?: MutOptions<string>
) => {
  return useMutation<string, ApiServiceErr, any>(async (formData: any) => {
    return await axiosApi.put(`/farm-gate/${formData.farmGateId}`, formData);
  }, opt);
};

export const useSimulationCultivationFormFieldData = (
  opt?: MutOptions<string>
) => {
  return useMutation<string, ApiServiceErr, any>(async (data: any) => {
    return await axiosApi.post(
      `/farm-gate/cultivation-field/${data.farmGateId}`,
      data
    );
  }, opt);
};

export const useSimulationGetCultivationFormData = (farmGateId: any) => {
  return useQuery(
    ["getSimulationFarmingData", farmGateId],
    async () => {
      const response = await axiosApi.get(`/farm-gate/${farmGateId}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!farmGateId,
      onError: () => {
        errorView("Something went wrong");
      },
    }
  );
};

export const useSimulationDeleteFarmGate = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (farmGateId) => {
    return await axiosApi.delete(`/farm-gate/${farmGateId}`);
  }, opt);
};
