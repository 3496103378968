import { useMemo } from "react";
import { Checkbox, Grid } from "semantic-ui-react";

import { DropDown, InputText, InputTextArea } from "../../../components";
import { customEmailValidation } from "../../../utils/utils";
import { useGetCountries } from "../../../api/static-data";

import "./supplierRegisterForm.scss";
interface SupplierRegisterFormProps {
  handleSubmit?: any;
  onSubmit?: any;
  register?: any;
  errors?: any;
  setCountry?: any;
  country?: string | number;
  setIsSupplierAsUser?: any;
  isSupplierAsUser?: boolean;
}

const SupplierRegisterForm: React.FC<SupplierRegisterFormProps> = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  setCountry,
  country,
  setIsSupplierAsUser,
  isSupplierAsUser,
}) => {
  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
    }
  }, [countriesData, isCountriesDataSuccess]);

  return (
    <Grid>
      <Grid.Column computer={16} tablet={16} mobile={16}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <InputText
                  register={register}
                  errors={errors.supplier_name}
                  labelName="Supplier Name"
                  placeholder="Supplier Name"
                  name="supplier_name"
                  required
                  errorMessage="Supplier Name can't be empty!"
                />
              </Grid.Column>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <InputText
                  register={register}
                  errors={errors.supplier_contact_name}
                  labelName="Supplier Contact Name"
                  placeholder="Supplier Contact Name"
                  name="supplier_contact_name"
                  required
                  errorMessage="Supplier Contact Name can't be empty!"
                />
              </Grid.Column>
              <Grid.Column computer={8} tablet={16} mobile={16} className="paddingRemoveTop">
                <InputText
                  register={register}
                  errors={errors.supplier_email}
                  labelName="Email"
                  placeholder="Email"
                  name="supplier_email"
                  required={isSupplierAsUser}
                  customInputValidation={true}
                  validateHandle={(value: string) => (value ? customEmailValidation(value) : true)}
                  errorMessage="Supplier email is required"
                />
              </Grid.Column>
              <Grid.Column computer={8} tablet={16} mobile={16} className="paddingRemoveTop">
                <InputText
                  register={register}
                  errors={errors.supplier_phone}
                  labelName="Contact Number"
                  placeholder="Contact Number"
                  name="supplier_phone"
                />
              </Grid.Column>
              <Grid.Row columns={2} className="paddingRemoveTop customSupplierColumn">
                <Grid.Column computer={8} tablet={16} mobile={16} className="paddingRemoveTop">
                  <InputTextArea
                    register={register}
                    errors={errors.supplier_address}
                    labelName="Address"
                    placeholder="Address"
                    name="supplier_address"
                    customText="supplierCustomAddress"
                  />
                </Grid.Column>
                <Grid.Row columns={2}>
                  <Grid.Column computer={8} tablet={16} mobile={16} className="paddingRemoveTop">
                    <DropDown
                      labelName="Country"
                      placeholder="Select country"
                      defaultValue={country}
                      currentData={countries}
                      handleChangeState={(e: any, { value }: any) => {
                        setCountry(value);
                      }}
                      loading={isCountriesDataLoading}
                      key={"supplier_country"}
                    />
                  </Grid.Column>
                  <Grid>
                    <Grid.Column computer={8} tablet={16} mobile={16} className="paddingRemoveBottom">
                      <InputText
                        register={register}
                        errors={errors.supplier_province}
                        labelName="Province"
                        placeholder="Province"
                        name="supplier_province"
                      />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16} className="paddingRemoveBottom">
                      <InputText
                        register={register}
                        errors={errors.supplier_postal_code}
                        labelName="Zip Code"
                        placeholder="Zip Code"
                        name="supplier_postal_code"
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
              </Grid.Row>
              <Grid.Column computer={16} tablet={16} mobile={16} className="paddingRemoveTop paddingRemoveBottom">
                <InputTextArea
                  register={register}
                  errors={errors.supplier_other_info}
                  labelName="Other Information"
                  placeholder="Other Information"
                  name="supplier_other_info"
                  customInputValidation={true}
                  customText="supplierCustomOther"
                />
              </Grid.Column>
              <Grid.Column computer={8} tablet={16} mobile={16} className="customCheckpointSupplierUser">
                <Checkbox
                  label=""
                  checked={isSupplierAsUser}
                  onChange={() => setIsSupplierAsUser(!isSupplierAsUser)}
                />
                <p className="registerSupplierRegister">Register supplier as user</p>
              </Grid.Column>
            </Grid>
          </form>
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default SupplierRegisterForm;
