import * as React from "react";
const VisiblePasswordIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#C1C7D0"
      d="M10 4.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0-3.75c4.167 0 7.725 2.592 9.167 6.25-1.442 3.658-5 6.25-9.167 6.25S2.275 10.658.833 7C2.275 3.342 5.833.75 10 .75ZM2.65 7a8.184 8.184 0 0 0 14.7 0 8.184 8.184 0 0 0-14.7 0Z"
    />
  </svg>
);
export default VisiblePasswordIcon;
