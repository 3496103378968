import { Grid, Table } from "semantic-ui-react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
  Switch,
} from "../../../../components";
import "./CultivarForm.scss";
import { InputNumber } from "../../../../components/InputText/InputNumber";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { isEmpty, isNumber } from "lodash";
import { useState } from "react";
import { customButtonUpdatetitle } from "../../../../config/constants";

const LandUse = ({
  setLandUsageChange,
  landUsageChange,
  onEditHandler,
  control,
  setVisibleDeleteModal,
  setDeleteValue,
  landUseYearOfChange,
  getLastTwentyYears,
  setLandUseYearOfChange,
  setValue,
  cultivationType,
  landUsageFrom,
  landUsage,
  setLandUSageFrom,
  landUsageTo,
  setLandUSageTo,
  onEdit,
  editTableData,
  cancelEdit,
  onAddClickLandUsageHandler,
  landUsagesData,
  LandUsageTableHeader,
  formData,
  currentOriginalFormData,
}: any) => {
  const landUsageData =
    currentOriginalFormData?.cultivationField?.landUsage || [];
  const [enableUpdateButton, setEnableUpdateButton] = useState<any>(false);

  const buttonOnClickhandle = () => {
    onAddClickLandUsageHandler();
    setEnableUpdateButton(false);
  };

  return cultivationType === "Supplier" ? (
    <div>
      <AccordionTitleView
        accordionEnabled={false}
        title={"Land Use Change Details"}
        plusIcon={false}
      />
      <Grid>
        <Grid.Row divided>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Grid.Column style={{ marginBottom: 15 }}>
              <label className="textLabel">
                Has any part of the field management practice changed between
                land use within the last 20 years?
              </label>
              <br />
              <label className="textLabel">
                {currentOriginalFormData?.landUsageChange}
              </label>
            </Grid.Column>
            {landUsageData.length > 0 && (
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                className="landUsageTable"
              >
                <CommonTable
                  tableHeaderData={LandUsageTableHeader.filter(
                    (e: any) => e.name !== ""
                  )}
                  className="landUsageTable"
                  isSimulateView
                >
                  {landUsageData.map((item: any, index: any) => {
                    return (
                      <Table.Row key={`landUsage_${item?.id}`}>
                        <Table.Cell>
                          <p>{item.landUseYearOfChange}</p>
                        </Table.Cell>
                        <Table.Cell>
                          <p>{item.landUsageAllocation}</p>
                        </Table.Cell>
                        <Table.Cell>
                          <p>{item?.landUsageFrom ?? ""}</p>
                        </Table.Cell>
                        <Table.Cell>
                          <p>{item?.landUsageTo ?? ""}</p>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </CommonTable>
              </Grid.Column>
            )}
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Grid>
              <Grid.Column
                computer={12}
                tablet={16}
                mobile={16}
                className="landUseView"
              >
                <p>
                  Has any part of the field management practice changed between
                  land use within the last 20 years?
                </p>
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <Switch
                  onClick={(value: any) => {
                    setLandUsageChange(value);
                  }}
                  titleOne={"No"}
                  titleTwo={"Yes"}
                  titleOneValue={"no"}
                  titleTwoValue={"yes"}
                  defaultValue={landUsageChange}
                />
              </Grid.Column>
              {landUsageChange === "yes" ? (
                <Grid>
                  <Grid.Column computer={7} tablet={16} mobile={16}>
                    <DropDown
                      labelName="Year of change"
                      placeholder="Select Year"
                      defaultValue={landUseYearOfChange}
                      currentData={getLastTwentyYears()}
                      handleChangeState={(e: any, { value }: any) => {
                        setLandUseYearOfChange(value);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={8} mobile={16}>
                    <InputNumber
                      transform={{
                        input: (value: any) =>
                          isNaN(value) || value === 0 || value > 101
                            ? ""
                            : value.toString(),
                        output: (e: any) => {
                          return e;
                        },
                      }}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        if (
                          value &&
                          isNumber(value) &&
                          (value <= 100 || value >= 0)
                        ) {
                          setValue("landUsageAllocation", value);
                        } else {
                          setValue("landUsageAllocation", "");
                        }
                      }}
                      control={control}
                      labelName={"Allocation (%)"}
                      placeholder="Allocation (%)"
                      name="landUsageAllocation"
                      enableMinus={false}
                    />
                  </Grid.Column>
                  <Grid.Column computer={7} tablet={8} mobile={16}>
                    <DropDown
                      labelName="Land use change - From"
                      placeholder="Select Land use change"
                      defaultValue={landUsageFrom}
                      currentData={landUsage}
                      handleChangeState={(e: any, { value }: any) => {
                        setLandUSageFrom(value);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={8} mobile={16}>
                    <DropDown
                      labelName="Land use change - To"
                      placeholder="Select Land use change"
                      defaultValue={landUsageTo}
                      currentData={landUsage}
                      handleChangeState={(e: any, { value }: any) => {
                        setLandUSageTo(value);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column computer={2} tablet={2} mobile={16}>
                    {onEdit ? (
                      <>
                        <Grid>
                          <Grid.Column computer={8}>
                            <CustomButton
                              theme="green"
                              title="edit"
                              customColumnStyle={"plusButton"}
                              onClick={() => editTableData("LandUsage")}
                            />
                          </Grid.Column>

                          <Grid.Column computer={8}>
                            <CustomButton
                              theme="green"
                              title="cancel"
                              customColumnStyle={"plusButton"}
                              onClick={() => cancelEdit("LandUsage")}
                            />
                          </Grid.Column>
                        </Grid>
                      </>
                    ) : (
                      <CustomButton
                        theme="green"
                        title={enableUpdateButton ? customButtonUpdatetitle : ""}
                        icon={!enableUpdateButton ? "plus" : null}
                        disabled={
                          isEmpty(landUsageFrom) || isEmpty(landUsageTo)
                        }
                        customColumnStyle={"plusButton"}
                        onClick={buttonOnClickhandle}
                      />
                    )}
                  </Grid.Column>
                  <Grid>
                    {landUsagesData?.length > 0 && (
                      <Grid.Column
                        computer={16}
                        tablet={16}
                        mobile={16}
                        className="landUsageTable"
                      >
                        <CommonTable
                          tableHeaderData={LandUsageTableHeader}
                          className="landUsageTable"
                        >
                          {landUsagesData?.map((item: any, index: any) => {
                            return (
                              <Table.Row key={`landUsage_${item?.id}`}>
                                <Table.Cell>
                                  <p>{item.landUseYearOfChange}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <p>{item.landUsageAllocation}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <p>{item?.landUsageFrom ?? ""}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <p>{item?.landUsageTo ?? ""}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <Grid>
                                    <Grid.Column
                                      computer={8}
                                      tablet={16}
                                      mobile={16}
                                    >
                                      <MdModeEditOutline
                                        cursor={"pointer"}
                                        size={24}
                                        color="var(--tableEditIcon)"
                                        onClick={() => {
                                          onEditHandler(
                                            item,
                                            "LandUsage",
                                            index
                                          );
                                          setEnableUpdateButton(true);
                                        }}
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      computer={8}
                                      tablet={16}
                                      mobile={16}
                                    >
                                      <MdDeleteOutline
                                        cursor={"pointer"}
                                        size={24}
                                        color="var(--tableEditIcon)"
                                        onClick={() => {
                                          setVisibleDeleteModal(true);
                                          setDeleteValue({
                                            type: "LandUsage",
                                            item,
                                            index,
                                          });
                                        }}
                                      />
                                    </Grid.Column>
                                  </Grid>
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </CommonTable>
                      </Grid.Column>
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  ) : null;
};

export default LandUse;
