import { useMutation } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "../apiService";

export const usePostPGSimulationWasteManagementData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.post(
      `/process-gate/processing-waste-management?processingId=${data?.processingId}`,
      data.wasteData
    );
  }, opt);
};

export const usePutPGSimulationWasteManagementData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.put(
      `/process-gate/processing-waste-management/${data.wasteManagementId}`,
      data.wasteData
    );
  }, opt);
};

export const useDeletePGSimulationWasteManagementData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.delete(
      `/process-gate/processing-waste-management/${data.wasteData.wasteManagementId}?processingId=${data?.wasteData.processingId}`
    );
  }, opt);
};

export const usePostSimulationWasteManagementData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.post(
      `/farm-gate/processing-waste-management?processingId=${data?.processingId}`,
      data.wasteData
    );
  }, opt);
};

export const usePutSimulationWasteManagementData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.put(
      `/farm-gate/processing-waste-management/${data.wasteManagementId}`,
      data.wasteData
    );
  }, opt);
};

export const useDeleteSimulationWasteManagementData = (
  opt?: MutOptions<string>
) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    return await axiosApi.delete(
      `/farm-gate/processing-waste-management/${data.wasteData.wasteManagementId}?processingId=${data?.wasteData.processingId}`
    );
  }, opt);
};
