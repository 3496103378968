import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import { CommonModal } from "../../../components";
import {
  useGetIngredientPackaging,
  useUploadIngredientImage,
} from "../../../api/ingredients";
import IngredientDataRegister from "../ingredientRegister/IngredientDataRegister";
import SupplerRegister from "../ingredientRegister/SupplierRegister";
import IngredientPackagingView from "../ingredientRegister/IngredientPackageing";
import TransportationDetails from "../transportationDetails/TransportationDetails";
import CommonSplitContainer from "../../../components/commonSplitContainer/CommonSplitContainer";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import { successMessage } from "../../../helpers/ErrorHandler";

interface IngredientEditModalProps {
  ingredientIdToUpdate: any;
  visibleModal: boolean;
  setVisibleModal: (visible: boolean) => void;
  refetchIngredients: () => void;
}

interface HandleValidation {
  saveData(): void;
}

const IngredientEditModal = ({
  ingredientIdToUpdate,
  visibleModal,
  setVisibleModal,
  refetchIngredients,
}: IngredientEditModalProps) => {
  const ref = useRef<HandleValidation>();
  const [ingredientData, setIngredientData] = useState<any>(null);
  const [viewEnvironmentalData, setViewEnvironmentalData] =
    useState<boolean>(false);
  const [imageData, setImageData] = useState<File | null>(null);
  const [viewPackage, setViewPackage] = useState<any>({
    processIngredient: false,
    produceIngredient: false,
    enableIngredient: false,
  });

  useEffect(() => {
    setIngredientData(ingredientIdToUpdate);
  }, [ingredientIdToUpdate]);

  const packageDataView = viewPackage.enableIngredient ? true : false;
  const transportDataView = viewPackage.processIngredient ? true : false;

  let ingredientId = ingredientIdToUpdate?.ingredient_id || null;
  let supplierId = ingredientIdToUpdate?.supplier_id || null;

  let getPackagingData = {
    ingredientId: ingredientId,
    supplierId: supplierId,
    loadPackageData: visibleModal,
  };

  useEffect(() => {
    if (ingredientData && ingredientData.supplierIngredientDetails?.data_type) {
      setViewEnvironmentalData(true);
    }
  }, [ingredientData]);

  const { data: ingredientPackageData, refetch: ingredientPackageDataRefetch } =
    useGetIngredientPackaging(getPackagingData);

  const { isLoading: isLoadingProductImage, mutate: uploadIngredientImage } =
    useUploadIngredientImage();
  const handleSave = () => {
    if (ref?.current) {
      ref?.current?.saveData();
    }
  };

  const saveIngredientImage = (data: any) => {
    if (imageData) {
      const ingredientImageData = {
        ingredient_id: data?.ingredient_data?._id,
        file: imageData,
      };
      uploadIngredientImage(ingredientImageData, {
        onSuccess: () => {
          finishIngredientImageSave();
        },
      });
    }
  };

  const finishIngredientImageSave = () => {
    successMessage("Ingredient Image Save successfully");
  };

  const modalClose = () => {
    refetchIngredients();
    setVisibleModal(false);
  };

  const enablePackageData = useMemo(() => {
    return packageDataView && viewEnvironmentalData;
  }, [packageDataView, viewEnvironmentalData]);

  const enableTransportData = useMemo(() => {
    const enableTransportDetails = transportDataView && viewEnvironmentalData;
    return (
      enableTransportDetails || (!enableTransportDetails && packageDataView)
    );
  }, [transportDataView, viewEnvironmentalData]);

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => {
        modalClose();
      }}
      centered={true}
      title="Edit Product Ingredient"
    >
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain"
        >
          <CommonSplitContainer
            currentHeight={0.94}
            topChild={
              <>
                <IngredientDataRegister
                  ingredientListByProduct={[]}
                  currentIngredientData={ingredientData}
                  ingredientRegisterData={(data: any) => {
                    setIngredientData(data);
                  }}
                  setImageData={setImageData}
                />
                <SupplerRegister
                  ref={ref}
                  ingredientSupplierList={[]}
                  ingredientData={ingredientData}
                  supplierCurrentData={() => {}}
                  ingredientList={[]}
                  disableEdit={true}
                  changeDataIngredient={setViewPackage}
                  setViewEnvironmentalData={setViewEnvironmentalData}
                  viewEnvironmentalData={viewEnvironmentalData}
                  saveIngredientImage={saveIngredientImage}
                />
                {enablePackageData ? (
                  <>
                    <IngredientPackagingView
                      ingredientId={ingredientId}
                      supplierId={supplierId}
                      refetchPackagingData={ingredientPackageDataRefetch}
                      ingredientPackageData={ingredientPackageData}
                    />
                  </>
                ) : null}

                {enableTransportData ? (
                  <TransportationDetails ingredientId={ingredientId} />
                ) : null}
              </>
            }
            bottomChild={
              <MainBottomButtonView
                cancelButtonStatus
                cancelButton={modalClose}
                saveButtonStatus
                saveTitle={"Update"}
                saveButton={() => handleSave()}
              />
            }
          />
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default IngredientEditModal;
