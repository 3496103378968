import React, { useMemo } from "react";
import { Button, Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

import {
  InputText,
  InputTextArea,
  CommonTable,
  NewImageUploader,
  Switch,
  CommonModal,
  TitleView,
  CustomButton,
} from "../../../components";
import defaultUserImage from "../../../assets/images/default_icon.jpeg";
import { PackagingColDetails } from "../../../config/constants";
import { useGetProductById } from "../../../api/product";

import "./productDetails.scss";
import CommonSplitContainer from "../../../components/commonSplitContainer/CommonSplitContainer";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";

const ProductDetailModal = ({
  visibleModal,
  setVisibleModal,
  productId,
}: any) => {
  const navigate = useNavigate();

  const { data: productDetails, isLoading: productDetailsLoading } =
    useGetProductById(productId);

  const values = useMemo(() => {
    return {
      product_name: productDetails?.product_name,
      registration_number: productDetails?.registration_number,
      product_other_info: productDetails?.product_other_info,
      product_type: productDetails?.product_type,
    };
  }, [productDetails]);

  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  //product details view table
  const loadTableData = () => {
    return productDetails?.sales_units.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data["sales_unit_name"]}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data["sales_unit_size"]}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data["sales_unit_type"]}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data["ean_barcode"]}</p>
          </Table.Cell>
          <Table.Cell>
            <CustomButton
              theme="green"
              title={"View Mapping"}
              onClick={() => {
                navigate(`/drawing?salesUnitId=${data?._id}`);
              }}
              buttonOutLine={true}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  //loading view
  if (productDetailsLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => setVisibleModal(false)}
        centered={true}
        title="Product Details"
      >
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16} className="p-0">
            <div className="productDetailsInsert">
              <form>
                <CommonSplitContainer
                  topChild={
                    <>
                      <TitleView title="Product Info" />
                      <Grid className="productDetailsViewMain">
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.product_name}
                            labelName={"Name"}
                            placeholder="Name"
                            name="product_name"
                            required={true}
                            errorMessage={"Please enter product name"}
                            disabled={true}
                            customText={"removeInputBorder"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.registration_number}
                            labelName={"Register number"}
                            placeholder="Register number"
                            name="registration_number"
                            required={false}
                            disabled={true}
                            customText={"removeInputBorder"}
                          />
                        </Grid.Column>

                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          <InputText
                            register={register}
                            errors={errors.product_type}
                            labelName={"Product Type"}
                            placeholder="Product Type"
                            name="product_type"
                            required={false}
                            disabled={true}
                            customText={"removeInputBorder"}
                          />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          {!isEmpty(productDetails?.product_image_path) ? (
                            <NewImageUploader
                              defaultUserImage={defaultUserImage}
                              buttonTitle={"Upload an Image"}
                              defaultImage={productDetails?.product_image_path}
                              uploadButtonView={false}
                              deleteButtonView={false}
                              mainTitle={"Product Image"}
                            />
                          ) : null}
                        </Grid.Column>
                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputTextArea
                            register={register}
                            errors={errors.product_other_info}
                            labelName={"Additional Information"}
                            placeholder="Additional Information"
                            name="product_other_info"
                            customInputValidation={true}
                            disabled={true}
                            customText={"removeInputBorder"}
                          />
                        </Grid.Column>
                        {productDetails?.sales_units?.length > 0 ? (
                          <Grid.Column computer={16} tablet={16} mobile={16}>
                            <TitleView title="Packaging Unit" />
                            <CommonTable tableHeaderData={PackagingColDetails}>
                              {loadTableData()}
                            </CommonTable>
                          </Grid.Column>
                        ) : null}
                      </Grid>
                    </>
                  }
                  bottomChild={
                    <MainBottomButtonView
                      cancelButtonStatus
                      cancelButton={() => setVisibleModal(false)}
                    />
                  }
                />
              </form>
            </div>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default ProductDetailModal;
