import { ReactFlowProvider, Node } from "reactflow";
import { createContext, useCallback, useEffect, useState } from "react";
import { Sidebar, Segment, Button, Icon } from "semantic-ui-react";
import "./DrawingTool.scss";
import CanvasMain from "./CanvasMain";
import AddingItemsPanel from "./addingNodes/AddingItemsPanel";
import { nodeCategories } from "../../config/drawingConstants";
import useNodesStateSynced from "./hooks/useNodesStateSynced";
import useEdgesStateSynced from "./hooks/useEdgesStateSynced";
import { DrawingsState } from "./models/DrawingState";

const initialState: DrawingsState = {
  nodeItemId: null,
  chartNodes: [],
  chartEdges: [],
  setChartNodes: () => {},
  setChartEdges: () => {},
  onNodesChange: () => {},
  onEdgesChange: () => {},
  setNodeItem: () => {},
  updateChartGroup: () => {},
  saveDrawing: () => {},
};

export const DrawingContext = createContext(initialState);

const DrawingTool = ({
  chartNodesData,
  chartEdgesData,
  sourceId,
  ownerId,
  actionBar,
  actionButtons,
  saveDrawing,
  updateNodeModal,
  contextProps,
}: any) => {
  // Drawing States
  const [chartNodes, setChartNodes, onNodesChange] = useNodesStateSynced();
  const [chartEdges, setChartEdges, onEdgesChange] = useEdgesStateSynced();
  const [nodeItemId, setNodeItem] = useState<any>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setChartNodes(chartNodesData);
    setChartEdges(chartEdgesData);
  }, [chartNodesData, chartEdgesData]);

  const updateChartGroup = useCallback(
    (groupClassName: string, node: Node) => {
      setChartNodes((nds) => {
        const data = [...nds];
        return data.map((n) => {
          if (n.type !== nodeCategories.special) {
            return {
              ...n,
              className: groupClassName,
            };
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            };
          }
          return { ...n };
        });
      });
    },
    [setChartNodes]
  );

  // Context Provider Values
  const value = {
    chartNodes,
    chartEdges,
    nodeItemId,
    ownerId,
    sourceId,
    setChartNodes,
    setChartEdges,
    onEdgesChange,
    onNodesChange,
    setNodeItem,
    updateChartGroup,
    saveDrawing,
    ...contextProps,
  };

  if (!sourceId) {
    return <>{actionBar}</>;
  }

  return (
    <DrawingContext.Provider value={value}>
      <ReactFlowProvider>
        <Sidebar.Pushable as={Segment} style={{ overflow: "hidden" }}>
          <AddingItemsPanel
            isVisible={isVisible}
            onClose={() => setIsVisible(!isVisible)}
          />
          {actionBar}
          <Button
            icon
            color="teal"
            className="open-side-menu-button"
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            <Icon name="angle double right" />
          </Button>
          <CanvasMain />
          {actionButtons(chartNodes, chartEdges)}

          {!!nodeItemId ? updateNodeModal() : null}
        </Sidebar.Pushable>
      </ReactFlowProvider>
    </DrawingContext.Provider>
  );
};

export default DrawingTool;
