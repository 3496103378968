import * as React from "react";
import { getPathName } from "../../utils/utils";
const ClientIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "/client-list"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M15 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 1.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2Zm0 7.1c2.67 0 8 1.33 8 4v3H7v-3c0-2.67 5.33-4 8-4Zm0 1.9c-3 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1ZM5 13.28l-2.5 1.49.68-2.81L1 10.08l2.87-.25L5 7.19l1.11 2.64 2.89.25-2.2 1.88.65 2.81L5 13.28Z"
      />
    </svg>
  );
};
export default ClientIcon;
