import { createContext, useReducer } from "react";
import { removeTokens } from "../utils/cacheStorage";

export const AuthContext = createContext({
  user: null,
  newNotification: false,
});

export const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      removeTokens();
      return  {
        ...state,
        user: null,
        token: null,
        refreshToken: null,
        company: null,
      };
    case "SET_COMPANY":
      localStorage.setItem("companyName", action.payload);
      return { ...state, userCompany: action.payload };
    case "NEW_NOTIFICATION":
      return { ...state, newNotification: Math.random() };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    newNotification: false,
  });

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
