import ClientImage from "./images/client.png";
import client_icon from "./images/client_icon.png";
import CloseIcon from "./images/closeIcon.png";
import cultivar_icon from "./images/cultivar_icon.png";
import cultivationImpact from "./images/Cultivation.svg";
import CultivationImage from "./images/cultivations.png";
import Dashboard from "./images/dashboard.png";
import DropDownIcon from "./images/dropDownIcon.png";
import farm_gate from "./images/farm_gate.png";
import IngredientImage from "./images/ingredients.png";
import ingredients_icon from "./images/ingredients_icon.png";
import IngredientSummery from "./images/ingredientSummery.png";
import LightClientImage from "./images/lightClient.png";
import LightCultivationImage from "./images/lightCultivation.png";
import LightDashboard from "./images/lightDashboard.png";
import LightIngredientImage from "./images/lightIngredient.png";
import LightIngredientSummery from "./images/lightIngredientSummery.png";
import LightProcessImage from "./images/lightProcess.png";
import LightProductImage from "./images/lightProduct.png";
import LightProductSummery from "./images/lightProductSummery.png";
import LightSupplierImage from "./images/lightSupplier.png";
import LightTransportImage from "./images/lightTransport.png";
import netZeroDefault from "./images/nerZeroDefault.png";
import notFound from "./images/not-found.jpg";
import Notification from "./images/notificationsIcon.png";
import package_icon from "./images/package_icon.png";
import packagingImpact from "./images/Packaging.svg";
import PackingProcess from "./images/PackingProcess.png";
import PlusIcon from "./images/plusIcon.png";
import PlusIconNew from "./images/PlusIconNew.png";
import ProcessImage from "./images/process.png";
import process_gate from "./images/process_gate.png";
import process_icon from "./images/process_icon.png";
import processingImpact from "./images/Processing.svg";
import ProductImage from "./images/product.png";
import productSummery from "./images/productSummery.png";
import SupplierImage from "./images/supplier.png";
import tetraBrik from "./images/tetraBrik.png";
import transportImage from "./images/transport.png";
import transport_icon from "./images/transport_icon.png";
import transportImpact from "./images/Transportation.svg";
import warehouse_icon from "./images/warehouse_icon.png";

export const images = {
  cultivationImpact,
  packagingImpact,
  processingImpact,
  transportImpact,
  ingredientImpact: ingredients_icon,
  notFound,
  ingredients_icon,
  transport_icon,
  process_icon,
  process_gate,
  warehouse_icon,
  package_icon,
  client_icon,
  farm_gate,
  cultivar_icon,
  transportImage,
  LightDashboard,
  LightProductImage,
  LightProductSummery,
  LightSupplierImage,
  LightProcessImage,
  LightIngredientImage,
  LightIngredientSummery,
  LightClientImage,
  LightCultivationImage,
  LightTransportImage,
  Dashboard,
  ProductImage,
  productSummery,
  SupplierImage,
  ProcessImage,
  IngredientImage,
  IngredientSummery,
  ClientImage,
  CultivationImage,
  PackingProcess,
  Notification,
  PlusIcon,
  CloseIcon,
  DropDownIcon,
  PlusIconNew,
  tetraBrik,
  netZeroDefault,
};
