import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import {
  Dimmer,
  Loader,
  Image,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Grid,
} from "semantic-ui-react";
import { useReactFlow } from "reactflow";
import _ from "lodash";
import { CustomButton, InputText, TitleView } from "../../../../../components";
import PackagingMethod from "../../../../packagingSimulation/component/packaging/packagingMachinery";
import Packaging from "../../../../packagingSimulation/component/packaging/pakaging";
import {
  useDeleteAllPackagingSimulation,
  useGetPackagingSimulationData,
  useUpdatePackagingSimulationDetails,
  useCreatePackagingSimulation,
  useUpdatePackagingSimulation,
  useCreatePackagingSimulationMethod,
  useUpdatePackagingSimulationMethod,
  useDeletePackagingSimulation,
  useDeletePackagingSimulationMethod,
  useGetPackagingSimulationTypes,
} from "../../../../../api/simulation/processGateSimulation/packaging";
import { useGetPackagingData } from "../../../../../api/processGate/packaging";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import { images } from "../../../../../assets/images";
import { useForm } from "react-hook-form";
import {
  PACKAGE_TYPES,
  PACKAGING_LEVELS,
} from "../../../../../config/constants";
import { useGetWasteManagementWasteTypes } from "../../../../../api/static-data";
import { DrawingContext } from "../../../../../components/drawing-tool/DrawingTool";

export const ProcessGatePackagingBase = ({ modalData }: any) => {
  const { packagingId, processGateId, nodeItem } = modalData;
  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);
  const { height } = useWindowDimensions();
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [newPackagingData, setNewPackagingData] = useState<
    PackagingData | undefined
  >();
  const { deleteElements } = useReactFlow();
  const {
    data: packagingTypeData,
    status: packagingTypeLoad,
    fetchStatus: packagingFetch,
  } = useGetPackagingSimulationTypes();
  const { data, status, fetchStatus, refetch } =
    useGetPackagingSimulationData(packagingId);
  const originalPackagingId = data?.originalId;
  const { data: originalPackagingData } =
    useGetPackagingData(originalPackagingId);
  const { data: wasteManagements, isLoading: isGetWasteManagementWasteTypes } =
    useGetWasteManagementWasteTypes();

  useEffect(() => {
    setNewPackagingData(data);
  }, [data]);

  const { mutate: mutatePackingDetails } =
    useUpdatePackagingSimulationDetails();
  const {
    mutate: mutateCreatePackagingSimulation,
    isLoading: isLoadingCreatePackagingSimulation,
  } = useCreatePackagingSimulation();
  const {
    mutate: mutateUpdatePackagingSimulation,
    isLoading: isLoadingUpdatePackagingSimulation,
  } = useUpdatePackagingSimulation();
  const {
    mutate: mutateDeletePackagingSimulation,
    isLoading: isLoadingDeletePackagingSimulation,
  } = useDeletePackagingSimulation();
  const {
    mutate: mutateCreatePackagingSimulationMethod,
    isLoading: isLoadingCreatePackagingSimulationMethod,
  } = useCreatePackagingSimulationMethod();
  const {
    mutate: mutateUpdatePackagingSimulationMethod,
    isLoading: isLoadingUpdatePackagingSimulationMethod,
  } = useUpdatePackagingSimulationMethod();
  const {
    mutate: mutateDeletePackagingSimulationMethod,
    isLoading: isLoadingDeletePackagingSimulationMethod,
  } = useDeletePackagingSimulationMethod();
  const { isLoading, mutate } = useDeleteAllPackagingSimulation();

  const updatePackagingName = (data: any) => {
    const dataOfPackageDetails = {
      data: { packagingName: data.packagingName, changeStatus: true },
      processGateId,
      packagingId,
    };
    mutatePackingDetails(dataOfPackageDetails, {
      onSuccess: (data) => {
        if (packagingId) refetch(packagingId);
        savePackingNodeData(data._id, data.packagingName);
      },
    });
  };

  const debounceMutatePackingDetails = useCallback(
    _.debounce(mutatePackingDetails, 1000),
    []
  );

  const updatePackingDetails = (data: any) => {
    const packageDetails = {
      processGateId,
      packagingId,
      data: {
        unitSize: data?.unitSize,
        numberOfBaseUnits: data?.numberOfBaseUnits,
        numberOfPacks: data?.numberOfPacks,
        numberOfCases: data?.numberOfCases,
        palletWidth: data?.palletWidth,
        palletLength: data?.palletLength,
        changeStatus: true,
      },
    };
    debounceMutatePackingDetails(packageDetails, {
      onSuccess: (data) => {
        if (packagingId) refetch(packagingId);
      },
    });
  };

  const savePackingNodeData = (id: any, packagingName?: string) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        ...(packagingName && { label: `Packaging Details (${packagingName})` }),
        reference: { packagingId: id },
        description: `Packaging Details`,
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) =>
      n.id === nodeItem?.id ? updatedNode : n
    );
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  const values = useMemo(
    () => ({
      packagingName: data?.packagingName,
    }),
    [nodeItem, data, processGateId]
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const isLoadingData =
    isLoadingCreatePackagingSimulation ||
    isLoadingUpdatePackagingSimulation ||
    isLoadingCreatePackagingSimulationMethod ||
    isLoadingUpdatePackagingSimulationMethod ||
    isLoading ||
    isGetWasteManagementWasteTypes ||
    isLoadingDeletePackagingSimulation ||
    isLoadingDeletePackagingSimulationMethod;

  if (
    (status === "loading" && fetchStatus === "fetching") ||
    (packagingTypeLoad === "loading" && packagingFetch === "fetching") ||
    isLoadingData
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <div>
      <div style={{ height: height * 0.9 - 100, overflowX: "hidden" }}>
        <Accordion>
          <AccordionTitle
            active={activeIndex === 1}
            index={1}
            onClick={() => setActiveIndex(activeIndex === 1 ? 0 : 1)}
          >
            <TitleView title="Please add information about how you package products at each level following the GS1 guidelines. Use this image as a reference." />
          </AccordionTitle>
          <AccordionContent active={activeIndex === 1}>
            <Image src={images.PackingProcess} />
          </AccordionContent>
        </Accordion>
        <TitleView title="Packaging Details" />
        <form onSubmit={handleSubmit(updatePackagingName)}>
          <Grid.Row divided>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Grid>
                {data?.packagingName ? (
                  <Grid.Column
                    floated="left"
                    computer={5}
                    tablet={8}
                    mobile={16}
                  >
                    <label className="textLabel">Packaging Name</label>
                    <br />
                    <label className="textLabel">{data?.packagingName}</label>
                  </Grid.Column>
                ) : (
                  <Grid.Column computer={8} tablet={8} mobile={16}>
                    <Grid>
                      <Grid.Column computer={10} tablet={12} mobile={12}>
                        <InputText
                          register={register}
                          labelName="Packaging Name"
                          name="packagingName"
                          errors={errors.packagingName}
                          required
                          errorMessage="Packaging Name is required"
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={4}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <CustomButton
                            theme="green"
                            title={"Update"}
                            type="submit"
                          />
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </form>
        {PACKAGE_TYPES.map((type) => {
          const packagingType = type as PackagingType;
          return (
            <Packaging
              key={packagingType}
              isSimiulationDrawing={data?.packagingName ? true : false}
              packagingData={originalPackagingData}
              packagingType={packagingType}
              newPackagingData={newPackagingData}
              setNewPackagingData={setNewPackagingData}
              packagingMaterials={packagingTypeData}
              createPackaging={(packagingData: any) => {
                mutateCreatePackagingSimulation({
                  data: { ...packagingData, changeStatus: true },
                  ...packagingData,
                  processGateId,
                  packagingId,
                });
              }}
              updatePackaging={(packagingData: any) => {
                mutateUpdatePackagingSimulation({
                  data: {
                    ...packagingData,
                    packagingId: packagingId,
                    changeStatus: true,
                  },
                  ...packagingData,
                  processGateId,
                  packagingId: packagingData._id,
                });
              }}
              deletePackaging={(packagingData: any) => {
                mutateDeletePackagingSimulation({
                  ...packagingData,
                  processGateId,
                  packagingId,
                });
              }}
              updatePackingDetails={updatePackingDetails}
              isView={false}
            />
          );
        })}
        <PackagingMethod
          title="Packaging Machinery"
          isSimiulationDrawing={data?.packagingName ? true : false}
          packagingData={originalPackagingData}
          newPackagingData={newPackagingData}
          setNewPackagingData={setNewPackagingData}
          packagingLevels={PACKAGING_LEVELS}
          wasteManagements={wasteManagements}
          createPackagingMethod={(packagingData: any) => {
            mutateCreatePackagingSimulationMethod({
              data: { ...packagingData, changeStatus: true },
              ...packagingData,
              processGateId,
              packagingId,
            });
          }}
          updatePackagingMethod={(packagingData: any) => {
            mutateUpdatePackagingSimulationMethod({
              data: {
                ...packagingData,
                packagingId: packagingId,
                changeStatus: true,
              },
              ...packagingData,
              processGateId,
              packagingMethodId: packagingData._id,
            });
          }}
          deletePackagingMethod={(packagingData: any) => {
            mutateDeletePackagingSimulationMethod({
              ...packagingData,
              processGateId,
              packagingId,
            });
          }}
          isView={false}
        />
      </div>
      <MainBottomButtonView
        deleteStatus={packagingId}
        deleteButton={() => setVisibleDeleteModal(true)}
        isSimulation={false}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => setVisibleDeleteModal(false)}
        approve={() => {
          const dataToDelete = { packagingId, processGateId };
          mutate(dataToDelete, {
            onSuccess(data) {
              onNodesDelete();
            },
          });
          setVisibleDeleteModal(false);
        }}
        title="Delete Process gate packaging"
        subTitle="Are you sure you want to remove the Process gate packaging? Please consider you cannot recover these data after remove"
      />
    </div>
  );
};
