import React, { useEffect, useState, useRef, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { CommonModal, NewImageUploader, TitleView } from "../../../components";
import {
  useGetIngredientPackaging,
  useGetIngredientsSupplierList,
  useGetIngredientWholeList,
  useUploadIngredientImage,
} from "../../../api/ingredients";
import IngredientPackagingView from "./IngredientPackageing";
import IngredientDataRegister from "./IngredientDataRegister";
import SupplerRegister from "./SupplierRegister";
import TransportationDetails from "../transportationDetails/TransportationDetails";
import CommonSplitContainer from "../../../components/commonSplitContainer/CommonSplitContainer";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import "./IngredientRegister.scss";
import { successMessage } from "../../../helpers/ErrorHandler";

interface IngredientRegisterModalProps {
  state?: any;
  visibleModal: boolean;
  setVisibleModal: (value: boolean) => void;
  ingredientList?: any[];
  refetchIngredients?: () => void;
}

interface HandleValidation {
  resetSupplierData(): void;
  saveData(): void;
}

const IngredientRegisterModalView = ({
  visibleModal,
  setVisibleModal,
  ingredientList,
  refetchIngredients,
}: IngredientRegisterModalProps) => {
  const [ingredientData, setIngredientData] = useState<any>(undefined);
  const [showSupplier, setShowSupplier] = useState<boolean>(false);
  const [imageData, setImageData] = useState<File | null>(null);
  const [loadPackageData, setLoadPackageData] = useState<any>(false);
  const [supplierData, setSupplierData] = useState<any>(undefined);
  const [hideIngredientView, setHideIngredientView] = useState<boolean>(false);
  const [viewEnvironmentalData, setViewEnvironmentalData] =
    useState<boolean>(false);
  const [viewPackage, setViewPackage] = useState<any>({
    processIngredient: false,
    produceIngredient: false,
    enableIngredient: false,
  });

  const ref = useRef<HandleValidation>();
  const packageDataView = viewPackage.enableIngredient ? true : false;
  const transportDataView = viewPackage.processIngredient ? true : false;

  let ingredientId = useMemo(() => {
    return (
      ingredientData?.ingredient_data?.ingredient_id ||
      ingredientData?.ingredient_data?._id
    );
  }, [ingredientData]);

  let supplierId = useMemo(() => {
    return (
      supplierData?.supplier_data?.supplier_id ||
      supplierData?.supplier_data?._id
    );
  }, [supplierData]);

  let getPackagingData = {
    ingredientId: ingredientId,
    supplierId: supplierId,
    loadPackageData: loadPackageData,
  };

  useEffect(() => {
    let getSupplierIngredientDetails = Array.isArray(
      supplierData?.supplier_data?.supplierIngredientDetails
    )
      ? supplierData.supplier_data.supplierIngredientDetails.find(
          (e: any) => e.ingredient_id == ingredientId
        )
      : supplierData?.supplier_data?.supplierIngredientDetails?.data_type;

    if (getSupplierIngredientDetails?.data_type) {
      setViewEnvironmentalData(true);
    }
  }, [supplierData]);

  useEffect(() => {
    if (!visibleModal) {
      setIngredientData(undefined);
      setSupplierData(undefined);
      setLoadPackageData(false);
      setHideIngredientView(false);
    }
  }, [visibleModal]);

  const {
    data: ingredientListByProduct,
    refetch: ingredientListByProductRefetch,
  } = useGetIngredientWholeList(true);

  const { data: ingredientSupplierList, refetch: supplerListByProductRefetch } =
    useGetIngredientsSupplierList();

  const { data: ingredientPackageData, refetch: ingredientPackageDataRefetch } =
    useGetIngredientPackaging(getPackagingData);

  const { isLoading: isLoadingProductImage, mutate: uploadIngredientImage } =
    useUploadIngredientImage();

  const handleCloseModal = () => {
    if (hideIngredientView) return;
    setVisibleModal(false);
  };
  const saveIngredientImage = (data: any) => {
    if (imageData) {
      const ingredientImageData = {
        ingredient_id: data?.ingredient_data?._id,
        file: imageData,
      };
      uploadIngredientImage(ingredientImageData, {
        onSuccess: () => {
          finishIngredientImageSave();
        },
      });
    }
  };

  const finishIngredientImageSave = () => {
    successMessage("Ingredient Image Save successfully");
  };

  const closeModal = () => {
    refetchIngredients?.();
    if (hideIngredientView) {
      ref?.current?.resetSupplierData();
    } else {
      handleCloseModal();
    }
  };

  const handleSave = () => {
    if (ref?.current) {
      ref?.current?.saveData();
    }
  };

  const enablePackageData = useMemo(() => {
    return loadPackageData && packageDataView && viewEnvironmentalData;
  }, [loadPackageData, packageDataView, viewEnvironmentalData]);

  const enableTransportData = useMemo(() => {
    const enableTransportDetails =
      loadPackageData && transportDataView && viewEnvironmentalData;
    return (
      enableTransportDetails || (!enableTransportDetails && packageDataView)
    );
  }, [loadPackageData, transportDataView, viewEnvironmentalData]);

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => closeModal()}
      centered={true}
      title="Add Ingredients"
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonSplitContainer
            currentHeight={0.94}
            topChild={
              <div>
                <TitleView
                  title={
                    !hideIngredientView ? "General Details" : "Add New Supplier"
                  }
                  CustomTitleViewMain="ingredientRegisterTitle"
                />
                <Grid>
                  {!hideIngredientView ? (
                    <Grid.Column
                      computer={16}
                      tablet={16}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <IngredientDataRegister
                        ingredientListByProduct={ingredientListByProduct}
                        ingredientRegisterData={setIngredientData}
                        refetchIngredient={ingredientListByProductRefetch}
                        currentIngredientData={ingredientData}
                        showSupplier={setShowSupplier}
                        setViewPackage={setViewPackage}
                        setImageData={setImageData}
                      />
                    </Grid.Column>
                  ) : null}
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <SupplerRegister
                      ref={ref}
                      ingredientSupplierList={ingredientSupplierList}
                      ingredientData={ingredientData}
                      supplierCurrentData={() => {}}
                      ingredientList={ingredientList}
                      refetchSupplier={supplerListByProductRefetch}
                      setIngredientData={setIngredientData}
                      setSupplier={setSupplierData}
                      loadPackageData={setLoadPackageData}
                      hideIngredientView={(status: any) =>
                        setHideIngredientView(status)
                      }
                      changeDataIngredient={setViewPackage}
                      disable={showSupplier}
                      setViewEnvironmentalData={setViewEnvironmentalData}
                      viewEnvironmentalData={viewEnvironmentalData}
                      saveIngredientImage={saveIngredientImage}
                    />
                  </Grid.Column>
                </Grid>
                {enablePackageData ? (
                  <IngredientPackagingView
                    ingredientId={ingredientId}
                    supplierId={supplierId}
                    refetchPackagingData={ingredientPackageDataRefetch}
                    ingredientPackageData={ingredientPackageData}
                  />
                ) : null}
                {enableTransportData ? (
                  <TransportationDetails ingredientId={ingredientId} />
                ) : null}
              </div>
            }
            bottomChild={
              <MainBottomButtonView
                customBottomContentMain={"productRegisterBottomContain"}
                cancelButtonStatus
                saveButtonStatus={showSupplier ? true : false}
                saveTitle={!hideIngredientView ? "Save" : "Save Supplier"}
                saveButton={() => handleSave()}
                cancelButton={() => closeModal()}
              />
            }
          />
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default IngredientRegisterModalView;
