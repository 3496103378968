export default {
  //common
  mainColor: "#71a950",
  mainTextColor: "#535E6D",
  mainTextInputHeader: "#535E6D",
  mainTextInptColor: "#707070",
  mainTextInputBorderColor: "#d2d7e0",
  commonModalHeader: "#71a950",
  closeIcon: "#71a950",
  mainTextInputColorWithDisable: "#212121",
  //title with bottom borader
  titleColor: "#71a950",
  titleBorderColor: "#71a950",
  //accordion
  titleActiveColor: "#ffffff",
  titleActiveBackground: "#71a950",
  //menu bar
  menuBarIconActive: "#71a950",
  menuBarIconDeactive: "#535E6D",
  //table
  tableHeaderColor: "#C9DDBD",
  tableHeaderText: "#267C38",
  tableEditIcon: "#71a950",
  //switch view
  switchActiveColor: "#71a950",
  //button
  buttonColor: "#71a950",
  //pageTitle
  pageTitleColor: "#71a950",
  //product summary
  pecentageSub: "#C9DDBD",
  //product summary header
  productSummaryTab: "#C9DDBD",
  activeProductTab: "#ffffff",
  cardHeader: "#267C38",
  nodeCardColor: "#F9F9F4",
  cardIconColor: "#277C38",
  digramSilder: "#FFFFFF",
  drawerSearchView: "#C9DDBD",
  drawingIconsSepia: "40%",
  drawingIconsHueRotate: "0deg",
  //mapping
  mappingImageBackground: "#C9DDBD",
  mappingIconColor: "#267C38",
  mappingIconTitleText: "#267C38",
  mappingIconTitleSubText: "#267C38",
  mappingTitleViewBackground: "#C9DDBD",
  mappingTitleText: "#267C38",
  mappingArrow: "#C9DDBD",
  mappingBottomTitleText: "#267C38",
  mappingResizeIcon: "#C9DDBD",
  mappingGroupTitle: "#267C38",
  mappingSidePanel: "#fff",
  mappingIconCard: "#fff",
  mappingSearch: "#C9DDBD",
  mappingSideOpenButton: "#C9DDBD",
  mappingCategoryTab: "#C9DDBD",
  mappingBottomButton: "#C9DDBD",
  //mapping modal
  modalCloseIcon: "#71a950",
  //popoverColor
  popOverContentBoarder: "#71a950",
  popOverContentArrow: "#71a950",
  //notification
  notificationShadow: "#71a950",
  notificationHeaderText: "#267C38",
  notificationTitleText: "#71a950",
  //noteDocument
  noteDocumentButton: "#71a950",
  //netZero
  netZeroHeaderTitle: "#71a950",
  netZeroCardBorder: "#71a950",
  netZeroCardTitle: "#71a950",
  netZeroCommonTitle: "#71a950",
};
