import { FaLeaf } from "react-icons/fa";
import { MdAgriculture, MdBackpack } from "react-icons/md";
import { ProOptions } from "reactflow";
import { images } from "../assets/images";
import { getCompanyLogo } from "../utils/utils";
import { MappingSourceType } from "./enums/MappingSourceType.enum";

const API_URL = "https://api.naturepreserve.app/graphql";
// const API_URL = "http://localhost:3002/graphql";
const NP_API_URL = "https://api.naturepreserve.app";
// const NP_API_URL = "http://localhost:3002";

const NP_SOCKET_API_URL = "wss://api.naturepreserve.app";

const NP_CALCULATOR_API_URL =
  "https://r99sbn0ct4.execute-api.eu-north-1.amazonaws.com/stage";

const drawerRoute = ["/drawer", "/drawing-ingredient"];

const DISABLE_CLOSE_MODEL_ID = ["6", "8", "10", "11"];

const COMMON_PROTECT_ROUTE_PATH = "/auth";

const EMAIL_ADDRESS = "hello@naturepreserve.co";

const NOT_APPLICABLE = "N/A";

const ImpactAssessmentMethodText =
  "´s impact assessment methodology strictly adheres to ISO 14040:2006 and ISO 14044:2006 standards for Life Cycle Assessment.";

// Alfa Laval for BLUE theme.
// Nature Preserve for GREEN theme.
const THEME = "GREEN";
const COMPANY_LOGO = getCompanyLogo(THEME);
const COMPANY_NAME = "Nature Preserve";
const proOptionsForReactFlow: ProOptions = {
  // account: "bs@naturepreserve.co",
  hideAttribution: true,
};

//common
const MENU_SIDE_BAR = [
  {
    mainTitle: "",
    subTitle: [
      {
        name: "dashboard",
        titleName: "Dashboard",
        path: "/",
        icon: images.Dashboard,
        activeIcon: images.LightDashboard,
      },
      {
        name: "product",
        titleName: "Products",
        path: "/product",
        icon: images.ProductImage,
        activeIcon: images.LightProductImage,
      },
      {
        name: "ingredient",
        titleName: "Ingredients",
        path: "/ingredient",
        icon: images.IngredientImage,
        activeIcon: images.LightIngredientImage,
      },
      {
        name: "supplier",
        titleName: "Suppliers",
        path: "/supplier",
        icon: images.SupplierImage,
        activeIcon: images.LightSupplierImage,
      },
      {
        name: "facility",
        titleName: "Facilities",
        path: "/facility",
        icon: images.transportImage,
        activeIcon: images.LightTransportImage,
      },
      {
        name: "mapping",
        titleName: "Mapping Tool",
        path: `/mapping/${MappingSourceType.product}`,
        icon: images.IngredientSummery,
        activeIcon: images.LightIngredientSummery,
      },
      {
        name: "drawingIngredient",
        titleName: "Ingredient Mapping",
        path: "/drawing/drawing-ingredient",
        icon: images.IngredientSummery,
        activeIcon: images.LightIngredientSummery,
      },
      {
        name: "productSummary",
        titleName: "Product Summary",
        path: "/product-summary",
        icon: images.productSummery,
        activeIcon: images.LightProductSummery,
      },
      {
        name: "clientList",
        titleName: "Client List",
        path: "/client-list",
        icon: images.ClientImage,
        activeIcon: images.LightClientImage,
      },
      {
        name: "drawingRequests",
        titleName: "Data Requests",
        path: "/drawing-requests",
        icon: images.Notification,
        activeIcon: images.Notification,
      },
    ],
  },
];

const HEADER_MENU_BAR = [
  {
    key: "1",
    text: "Company",
    value: "company",
    path: "/company",
  },
  {
    key: "2",
    text: "Users",
    value: "user",
    path: "/users",
  },
  {
    key: "3",
    text: "Static Config",
    value: "staticConfiguration",
    path: "/settings/static-configuration",
  },
  {
    key: "4",
    text: "Supplier Request",
    value: "staticConfiguration",
    path: "/supplierRequest",
  },
];

const ProductFilterData = [
  { key: Math.random(), text: "All", value: "All" },
  { key: Math.random(), text: "Farm Product", value: "Farm Product" },
  { key: Math.random(), text: "Processed", value: "Processed" },
  { key: Math.random(), text: "Custom/Filter", value: "Custom/Filter" },
];

const UserFilterData = [
  { key: Math.random(), text: "All", value: "All", skip: false },
  {
    key: Math.random(),
    text: "Super Admin",
    value: "Farm Product",
    skip: false,
  },
  { key: Math.random(), text: "Admin", value: "Processed", skip: false },
];

const HarvestSeasonData = [
  { key: Math.random(), text: "Unknown", value: "Unknown" },
  { key: Math.random(), text: "Spring", value: "Spring" },
  { key: Math.random(), text: "Summer", value: "Summer" },
  { key: Math.random(), text: "Fall", value: "Fall" },
  { key: Math.random(), text: "Winter", value: "Winter" },
];

const FertilizerNameData = [
  { key: 1, text: "Manure-General", value: "Manure-General", name: "general" },
  { key: 2, text: "Manure-Cattle", value: "Manure-Cattle", name: "cattle" },
  { key: 3, text: "Manure-Poultry", value: "Manure-Poultry", name: "poultry" },
  { key: 4, text: "Manure-Pig", value: "Manure-Pig", name: "pig" },
  { key: 5, text: "Nitrogen (N)", value: "Nitrogen (N)", name: "nitrogen" },
  {
    key: 6,
    text: "Phosphorus (P)",
    value: "Phosphorus (P)",
    name: "phosphorus",
  },
  { key: 7, text: "Potassium (K)", value: "Potassium (K)", name: "potassium" },
];
const SipContents = [
  {
    name: "Manufacturer",
    value: "",
    unit: "None",
    typeName: "manufacturer",
  },
  {
    name: "Model Number",
    value: "",
    unit: "None",
    typeName: "modelNumber",
  },
  {
    name: "Energy Consumption",
    value: "",
    unit: "kWh",
    typeName: "energyConsumption",
  },
  {
    name: "Steam Pressure",
    value: "",
    unit: "bar",
    typeName: "steamPressure",
  },
  {
    name: "Steam Flow",
    value: "",
    unit: "kg/hr",
    typeName: "steamFlow",
  },

  {
    name: "Water Consumption",
    value: "",
    unit: "L",
    typeName: "waterConsumption",
  },
  {
    name: "Waste water",
    value: "",
    unit: "L",
    typeName: "wasteWater",
  },
  {
    name: "Hydrogen Peroxide Concentration",
    value: "",
    unit: "%",
    typeName: "hydrogenPeroxideConcentration",
  },
  {
    name: "Hydrogen Peroxide Consumption",
    value: "",
    unit: "L per round",
    typeName: "hydrogenPeroxideConsumption",
  },
  {
    name: "Caustic Soda Concentration",
    value: "",
    unit: "%",
    typeName: "causticSodaConcentration",
  },
  {
    name: "Caustic Soda Consumption",
    value: "",
    unit: "L per round",
    typeName: "causticSodaConsumption",
  },
];

const ProductTableContent = [
  {
    name: {
      id: "122",
    },
    climate: 3,
    reg: 123,
  },
  {
    name: "Name",
    climate: 3,
    reg: 123,
  },
  {
    name: "Name",
    climate: 3,
    reg: 123,
  },
  {
    name: "Name",
    climate: 3,
    reg: 123,
  },
];

const switchSize = {
  mini: "mini",
  tiny: "tiny",
  small: "small",
  medium: "medium",
  large: "large",
  big: "big",
  huge: "huge",
  massive: "massive",
};

const packagingFieldMap = {
  singleUnitPackaging: "single-unit",
  salesUnitPackaging: "sales-unit",
  bulkUnitPackaging: "bulk-unit",
  transportUnitPackaging: "transport-unit",
};

const CycleStage = [
  {
    title: "Agriculture",
    icon: MdAgriculture,
  },
  {
    title: "Transformation",
    icon: FaLeaf,
  },
  {
    title: "Transformation",
    icon: MdBackpack,
  },
  {
    title: "Transformation",
    icon: MdAgriculture,
  },
];

const IngredientImpact = [
  {
    title: "Milk",
  },
  {
    title: "Strawberry",
  },
  {
    title: "White Suger",
  },
  {
    title: "Other Steps",
  },
];

const ProductSummaryTitle = [
  {
    text: "Ingredient Impact",
    skip: true,
  },
  {
    text: "Supply Chain Overview",
    skip: true,
  },
  {
    text: "KPI",
    skip: true,
  },
  {
    text: "Impact Reduction",
    skip: true,
  },
  {
    text: "Reports",
    skip: true,
  },
  {
    text: "Certificates",
    skip: true,
  },
  {
    text: "Product Mapping",
    skip: false,
  },
  {
    text: "Product Simulation",
    skip: false,
  },
  {
    text: "Packaging Simulation",
    skip: true,
  },
  {
    text: "Product History",
    skip: false,
  },
];

const newNodes = [
  {
    id: "1",
    image: images.ingredients_icon,
    cardHeader: "Ingredients",
    cardExtra: "Ingredients types",
  },
  {
    id: "2",
    image: images.transport_icon,
    cardHeader: "Transportation",
    cardExtra: "Transportation by Flight",
  },
  {
    id: "3",
    image: images.process_icon,
    cardHeader: "Process",
    cardExtra: "Precessed Ingredients",
  },
  {
    id: "4",
    image: images.warehouse_icon,
    cardHeader: "Warehouse",
    cardExtra: "Ingredients types",
  },
  {
    id: "5",
    image: images.package_icon,
    cardHeader: "Packing",
    cardExtra: "Ingredients types",
  },
  {
    id: "6",
    image: images.client_icon,
    cardHeader: "Client",
    cardExtra: "Ingredients types",
  },
];

const NoteDocumentTable = [
  {
    name: "",
    col: 1,
  },
  {
    name: "Document Name",
    col: 7,
  },
  {
    name: "Upload on",
    col: 4,
  },
  {
    name: "",
    col: 4,
  },
];

const TransportTypeCol = [
  {
    name: "Transportation Type",
    col: 6,
  },
  {
    name: "Distance",
    col: 6,
  },
  {
    name: "",
    col: 4,
  },
];

const TransportSimulateCol = [
  {
    name: "Chemical name",
    col: 4,
  },
  {
    name: "Chemical Amount (kg/kg input)",
    col: 4,
  },
  {
    name: "Transportation Type",
    col: 4,
  },
  {
    name: "Distance",
    col: 4,
  },
];

const WasteManagementDataCol = [
  {
    name: "Management Name",
    col: 4,
  },
  {
    name: "Management Type",
    col: 4,
  },
  {
    name: "Distribution (kg)",
    col: 4,
  },
  {
    name: "",
    col: 4,
  },
];

const DATA_TYPES = [
  { key: 0, text: "Provided by Supplier", value: "SUPPLIER" },
  { key: 1, text: "Generic", value: "GENERIC" },
  { key: 2, text: "Provided by User", value: "USER" },
];

const INGREDIENT_TYPE = [
  {
    key: 1,
    text: "RAW",
    value: "RAW",
  },
  {
    key: 2,
    text: "PROCESSED",
    value: "PROCESSED",
  },
];

const ENERGY_UNIT_TYPE = [
  {
    key: 1,
    text: "kWh",
    value: 1,
  },
  {
    key: 2,
    text: "MJ",
    value: 2,
  },
  {
    key: 3,
    text: "thm",
    value: 3,
  },
  {
    key: 4,
    text: "btu",
    value: 4,
  },
];

const PER_TYPES = [
  {
    key: "day",
    text: "day",
    value: "day",
  },
  {
    key: "week",
    text: "week",
    value: "week",
  },
  {
    key: "month",
    text: "month",
    value: "month",
  },
  {
    key: "year",
    text: "year",
    value: "year",
  },
];

const PER_WEIGHT_UNITS = [
  {
    key: "kg",
    text: "kg",
    value: "kg",
  },
  {
    key: "ton",
    text: "ton",
    value: "ton",
  },
];

const NotificationCol = [
  {
    name: "Ingredient Name",
    col: 4,
  },
  {
    name: "Company Name",
    col: 4,
  },
  {
    name: "Product Name",
    col: 4,
  },
  {
    name: "",
    col: 2,
  },
  {
    name: "",
    col: 2,
  },
];

const NotificationTableCok = [
  {
    name: "Title",
    col: 6,
  },
  {
    name: "Description",
    col: 6,
  },
  {
    name: "Date",
    col: 4,
  },
];

const SupplierRequestStatus = {
  Requested: 1,
  Accepted: 2,
  InProgress: 3,
  Completed: 4,
  Updated: 5,
};

const SimulationImpactFarm = [
  { key: "cultivations", name: "internalStorageName" },
  { key: "internalStorage", name: "internalStorageName" },
  { key: "internalTransportation", name: "internalTransportName" },
  { key: "packaging", name: "packingName" },
  { key: "processing", name: "processingName" },
];

const SimulationImpactProcess = [
  { key: "internalStorage", name: "internalStorageName" },
  { key: "internalTransportation", name: "internalTransportName" },
  { key: "packaging", name: "packingName" },
  { key: "processing", name: "processingName" },
];

const SimulationNetZeroDataTable = [
  {
    name: "",
    col: 1,
  },
  {
    name: "Simulation Name",
    col: 3,
  },
  {
    name: "Original Impact",
    col: 3,
  },
  {
    name: "Simulated Impact",
    col: 2,
  },
  {
    name: "Impact Change %",
    col: 3,
  },
  {
    name: "Detail",
    col: 3,
  },
  {
    name: "Net-zero journey",
    col: 1,
  },
];

const SimulationDataTable = [
  {
    name: "Simulation Name",
    col: 4,
  },
  {
    name: "Original Impact",
    col: 4,
  },
  {
    name: "Simulated Impact",
    col: 4,
  },
  // TODO:- Hide for demo
  // {
  //   name: "Step Impact Change %",
  //   col: 4,
  // },
  {
    name: "Impact Change %",
    col: 4,
  },
];

const PACKAGE_TYPES = [
  "base-unit",
  "sales-unit",
  "bulk-unit",
  "transport-unit",
];

const SupplierNotificationRequestStatus: Record<number, string> = {
  1: "Requested",
  2: "Accepted",
  3: "InProgress",
  4: "Completed",
  5: "Updated",
};

export * from "./optionsConstants";
export * from "./tableColConstant";

const noResultIngredient = {
  id: 0,
  title: "+ New",
};

const modelContentType = {
  view: 1,
  edit: 2,
};
const customButtonUpdatetitle = "Update";

const modelContentUpdateType = {
  add: 1,
  delete: 2,
};

const IngredientViewTabs = [
  { name: "Ingredient Summary", size: 3 },
  { name: "Environmental Footprint", size: 3 },
  { name: "Supply Chain Mapping", size: 3 },
  { name: "History", size: 2 },
  { name: "Unit Price & Annual Volume", size: 3 },
  { name: "Sales Volumes", size: 2 },
];

const TAB_KEYS = {
  SUMMARY: 0,
  ENVIRONMENTAL_FOOTPRINT: 1,
  SUPPLY_CHAIN_MAPPING: 2,
  HISTORY: 3,
  UNIT_PRICE_ANNUAL_VOLUME: 4,
  SALES_VOLUMES: 5,
};

const calculationStatus = ["Not Calculated", "Calculated", ""];

export {
  API_URL,
  calculationStatus,
  COMMON_PROTECT_ROUTE_PATH,
  COMPANY_LOGO,
  COMPANY_NAME,
  customButtonUpdatetitle,
  CycleStage,
  DISABLE_CLOSE_MODEL_ID,
  drawerRoute,
  EMAIL_ADDRESS,
  FertilizerNameData,
  HarvestSeasonData,
  HEADER_MENU_BAR,
  ImpactAssessmentMethodText,
  IngredientImpact,
  IngredientViewTabs,
  MENU_SIDE_BAR,
  modelContentType,
  modelContentUpdateType,
  newNodes,
  noResultIngredient,
  NOT_APPLICABLE,
  NoteDocumentTable,
  NotificationCol,
  NotificationTableCok,
  NP_API_URL,
  NP_CALCULATOR_API_URL,
  NP_SOCKET_API_URL,
  PACKAGE_TYPES,
  packagingFieldMap,
  ProductFilterData,
  ProductSummaryTitle,
  ProductTableContent,
  proOptionsForReactFlow,
  SimulationDataTable,
  SimulationImpactFarm,
  SimulationImpactProcess,
  SimulationNetZeroDataTable,
  SipContents,
  SupplierNotificationRequestStatus,
  SupplierRequestStatus,
  switchSize,
  TAB_KEYS,
  THEME,
  UserFilterData,
};
