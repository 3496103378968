import * as React from "react";
import { getPathName } from "../../utils/utils";

const FacilityIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "/facility"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="m16.879 8.728-4.492-2.625a.75.75 0 0 0-.756-.001L7.123 8.727a.75.75 0 0 0-.373.648v5.25a.75.75 0 0 0 .373.648l4.508 2.625A.719.719 0 0 0 12 18c.13 0 .27-.034.387-.103l4.492-2.625a.75.75 0 0 0 .371-.647v-5.25a.75.75 0 0 0-.371-.647Zm-4.87-1.11 3.005 1.757-3.006 1.756-3.017-1.756 3.017-1.757ZM8.25 10.68l3 1.747v3.514l-3-1.747V10.68Zm4.5 5.269v-3.513l3-1.753v3.512l-3 1.754Z"
      />
      <path
        fill={
          pathName == "/facility"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M12 .75a.75.75 0 0 0-.378.102l-9 5.25a.75.75 0 0 0-.372.648v10.5a.75.75 0 0 0 .372.648l9 5.25a.75.75 0 0 0 .756 0l8.25-4.813-.756-1.295L12 21.631 3.75 16.82V7.18L12 2.368l8.25 4.813v5.569h1.5v-6a.75.75 0 0 0-.372-.648l-9-5.25A.75.75 0 0 0 12 .75Z"
      />
    </svg>
  );
};
export default FacilityIcon;
