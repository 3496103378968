import { useEffect, useState } from "react";

import { TitleBar } from "../../components/titleBar/Titlebar";
import "./ProductSimulation.scss";
import ProductSimulationTable from "./component/SimulationTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetProductPayload } from "../../api/productSimulation";
import ConfirmModal from "../../components/confirmViewModal/ConfirmModal";
import { useGetSimulationDrawing } from "../../api/simulation";
import { Dimmer, Loader } from "semantic-ui-react";
import { warningMessage } from "../../helpers/ErrorHandler";
import { isEmpty } from "lodash";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProductSimulation = () => {
  const [salesUnitId, setSalesUnitId] = useState("");
  const [productId, setProductId] = useState("");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [simulationData, setSimulationData] = useState<DrawingSimulationData[]>(
    []
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalSimulations, setTotalSimulations] = useState(0);
  const [currentDrawingId, setCurrentDrawingId] = useState(null);

  // Query params
  const query = useQuery();

  // History
  const navigate = useNavigate();

  const { data: productPayload, isLoading: isProductPayloadLoading } =
    useGetProductPayload({
      salesUnitId,
    });

  const {
    data: drawingSimulationData,
    isFetching: isDrawingSimulationsLoading,
  } = useGetSimulationDrawing({
    pageNumber,
    pageSize,
    ownerId: productId,
    sourceId: salesUnitId,
  });

  useEffect(() => {
    const salesUnitId = query.get("salesUnitId");
    const productId = query.get("productId");
    if (salesUnitId && productId) {
      setSalesUnitId(salesUnitId);
      setProductId(productId);
    }
  }, []);

  useEffect(() => {
    if (drawingSimulationData) {
      setSimulationData(drawingSimulationData.result || []);
      setPageNumber(drawingSimulationData.page);
      setPageSize(drawingSimulationData.pageSize);
      setTotalSimulations(drawingSimulationData.total);
    }
  }, [drawingSimulationData]);

  const navigateToSimulateDrawing = (itemId: string, navigationId?: number) => {
    switch (navigationId) {
      case 1:
        return navigate(
          `/mapping/simulate-mapping/${productId}/${salesUnitId}?simulateDrawingId=${itemId}`
        );
      default:
        return !isEmpty(currentDrawingId)
          ? navigate(
              `/simulations/product-simulation-net-zero?salesUnitId=${salesUnitId}&productId=${productId}&simulationDrawingId=${currentDrawingId}`
            )
          : warningMessage("Please select net zero simulation");
    }
  };

  const isLoading = Boolean(isProductPayloadLoading);

  if (isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <div>
      <TitleBar
        enableTitle={true}
        titleName={
          (productPayload &&
            `${productPayload?.productName} - ${productPayload?.salesUnitName}`) ||
          ""
        }
        customButtonData={[
          {
            text: "Net Zero Journey",
            skip: false,
          },
          {
            text: "Go to Simulation",
            skip: false,
          },
        ]}
        enableCustomButton
        buttonSelectView={false}
        customPageButtonContainer="product-title-container"
        customPageTitle="product-simulation-title"
        customButtonMain="title-button"
        customButtonAction={(index: any) => {
          navigateToSimulateDrawing("", index);
        }}
      />
      <ProductSimulationTable
        data={simulationData}
        onEdit={(item) => {
          navigateToSimulateDrawing(item._id, 1);
        }}
        onDelete={(simulation: DrawingSimulationData) => {
          setSimulationData([simulation]);
          setVisibleDeleteModal(true);
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalSimulations={totalSimulations}
        isGetProductSimulationsLoading={isDrawingSimulationsLoading}
        currentDrawingId={(id: any) => setCurrentDrawingId(id)}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {}}
        title="Delete Simulation Data"
        subTitle="Are you sure you want to delete this simulation data?"
      />
    </div>
  );
};

export default ProductSimulation;
