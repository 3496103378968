import React, { ReactNode } from "react";
import { Grid, Button, Icon } from "semantic-ui-react";

import { ImageView } from "../ImageView/ImageView";
import { images } from "../../assets/images";

import "./button.scss";

interface CustomButtonProps {
  computer?: any;
  tablet?: any;
  mobile?: any;
  theme?: "green" | "dark" | "blue" | "red";
  title?: string;
  customColumnStyle?: string;
  customButtonStyle?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  circular?: boolean;
  icon?: any;
  iconSize?: "mini" | "tiny" | "small" | "large" | "big" | "huge" | "massive";
  buttonOutLine?: boolean;
  iconWithText?: boolean;
  className?: string;
  bounceEnable?: boolean;
  iconChild?: ReactNode;
  iconChildCustom?: any;
  iconChildTextCustom?: any;
  iconChildMainCustomContainer?: any,
  customBase?:any,
  customOutLine?:any
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  computer,
  tablet,
  mobile,
  theme = "dark",
  title = "",
  customColumnStyle = "",
  customButtonStyle = "",
  onClick,
  type = "button",
  disabled = false,
  circular = false,
  icon,
  iconSize = "large",
  buttonOutLine = false,
  iconWithText = false,
  bounceEnable = false,
  className = "",
  iconChild,
  iconChildCustom,
  iconChildTextCustom,
  iconChildMainCustomContainer,
  customBase,
  customOutLine
}) => {
  const themeText = () => {
    const baseClass = disabled ? `disableButtonMain ${customBase}` : `commonButton ${customBase}`;
    const outlineClass = buttonOutLine ? `Outline ${customOutLine}` : "";
    const themeClasses: Record<string, string> = {
      green: disabled ? "disableButtonOutlineMain buttonGreenOutline" : `buttonGreen${outlineClass}`,
      dark: disabled ? "disableButtonOutlineMain buttonDarkOutline" : `buttonDark${outlineClass}`,
      blue: disabled ? "disableButtonOutlineMain buttonBlueOutline" : `buttonBlue${outlineClass}`,
      red: disabled ? "disableButtonOutlineMain buttonRedOutline" : `buttonRed${outlineClass}`,
    };

    return `${baseClass} ${themeClasses[theme] || themeClasses.green}`;
  };

  const renderButtonContent = () => {
    if (icon) {
      return iconChild ? iconChild : <Icon name={icon} size={iconSize} />;
    }
    if (iconWithText) {
      return (
        <div className={`iconChildMainContainer ${iconChildMainCustomContainer}`}>
          <div className={`iconChildMain ${iconChildCustom}`}>
            {iconChild}
          </div>
          <p className={`iconChildTextMain ${iconChildTextCustom}`} >{title}</p>
        </div>
      );
    }
    return title;
  };

  return (
    <Grid.Column
      computer={computer}
      tablet={tablet}
      mobile={mobile}
      className={`${customColumnStyle} ${bounceEnable ? "bouncing-button" : ""}`}
    >
      <Button
        className={`${themeText()} ${customButtonStyle} ${className}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
        circular={circular}
        icon={!!icon}
      >
        {renderButtonContent()}
      </Button>
    </Grid.Column>
  );
};
