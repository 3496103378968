import { Button, Modal, Segment } from "semantic-ui-react";
import { DropDown } from "../../components";
import { useState } from "react";
import {
  useCopyDrawingToolData,
  useGetProductSalesUnitsNotSavedDrawing,
} from "../../api/drawingTool";
import { MappingSourceType } from "../../config/enums/MappingSourceType.enum";

const CopyMappingModal = ({
  viewModal = false,
  closeModal = () => {},
  drawingId,
  onSuccessCopy,
}: any) => {
  const [searchKey, setSearchKey] = useState("");
  const [toCopyData, setToCopyData] = useState<any>();

  const { data: salesUnits, isLoading: isSalesUnitsLoading } =
    useGetProductSalesUnitsNotSavedDrawing({
      mappingType: MappingSourceType.product,
      searchKey,
    });

  // Data for dropdown
  const salesUnitsValues: DropdownOption[] = (salesUnits || []).map(
    ({ productName, salesUnitName, salesUnitId, productId }) => ({
      key: salesUnitId,
      text: `${productName} - ${salesUnitName}`,
      value: salesUnitId,
      product: productId,
    })
  );

  const { mutate: copyDrawing, isLoading: isLoadingCopyDrawing } =
    useCopyDrawingToolData(MappingSourceType.product);

  const pasteDrawing = () => {
    copyDrawing(
      {
        drawingId,
        ownerId: toCopyData.product,
        sourceId: toCopyData.value,
      },
      {
        onSuccess: () => {
          onSuccessCopy(toCopyData.value);
        },
      }
    );
  };

  return (
    <Modal
      centered={false}
      size={"small"}
      open={true}
      onClose={() => closeModal()}
      dimmer="blurring"
    >
      <Modal.Header>Copy Mapping!</Modal.Header>
      <Modal.Content as={Segment} loading={isLoadingCopyDrawing}>
        <DropDown
          currentData={salesUnitsValues}
          defaultValue={toCopyData?.value}
          handleChangeState={(e: any, data: any) => {
            const values = salesUnitsValues.find((ps) => ps.key === data.value);
            setToCopyData(values);
          }}
          onSearchChange={(e: any, data: any) => {
            setSearchKey(data.searchQuery);
          }}
          loading={isSalesUnitsLoading}
          search
        />
        <h3>
          Please select the product or create the product if product is not
          available from the dropdown.
        </h3>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button positive onClick={() => pasteDrawing()}>
          Copy
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CopyMappingModal;
