import React, { useEffect, useRef } from "react";
import { Grid } from "semantic-ui-react";
import * as echarts from "echarts";

import NetZeroCard from "../netZeroCard/NetZeroCard";
import {
  generateRandomColors,
  getImpactChangePercentage,
} from "../../../../utils/utils";

import "./NetZeroDetails.scss";

const NetZeroDetails = ({
  currentSimulationData,
  drawingSimulationData,
}: any) => {
  const chartRef = useRef(null);
  const [getCurrentNames, getCurrentValues] = drawingSimulationData.reduce(
    (
      [names, values]: [string[], number[]],
      { _id, name, totalImpact }: any
    ) => {
      if (_id !== currentSimulationData.originalSimulationId) {
        names.push(name);
        values.push(
          getImpactChangePercentage(
            currentSimulationData.productSummary?.totalImpact?.climateChange
              ?.measure,
            totalImpact
          )
        );
      }
      return [names, values];
    },
    [[], []]
  );

  useEffect(() => {
    if (!chartRef.current) return;
    const myChart = echarts.init(chartRef.current);
    const options = {
      title: {
        text: "CO₂ Reduction",
        left: "center",
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params: any) => {
          return `${params.name}<br/>${params.marker} ${params.value}%`;
        },
      },
      xAxis: {
        type: "category",
        data: getCurrentNames,
        axisLabel: {
          rotate: 30,
        },
      },
      yAxis: {
        type: "value",
        name: "Impact Reduction as percentage",
      },
      series: [
        {
          type: "bar",
          data: getCurrentValues,
          itemStyle: {
            color: (params: any) => {
              return generateRandomColors(getCurrentValues.length || 0)[
                params.dataIndex
              ];
            },
          },
          label: {
            show: true,
            position: "top",
            formatter: "{b}",
          },
        },
      ],
    };

    myChart.setOption(options);
    return () => {
      myChart.dispose();
    };
  }, [drawingSimulationData]);

  return (
    <Grid>
      <Grid.Column computer={4} tablet={16} className="netZeroCardViewMain">
        <NetZeroCard currentSimulationData={currentSimulationData} />
      </Grid.Column>
      <Grid.Column computer={12} tablet={16}>
        <div className="netZeroChatView" ref={chartRef} />
      </Grid.Column>
    </Grid>
  );
};

export default NetZeroDetails;
