import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Grid, Popup } from "semantic-ui-react";
import Select from 'react-select';

import { CustomButton, TitleView } from "../../../components";
import FilterIcon from "../../../assets/commonIcon/FilterIcon";
import { useGetProductData } from "../../../api/product";

import "./productList.scss";

const ProductFilter = ({ refetchWithFilters }: any) => {
  const { data } = useGetProductData({
    product_status: "ACTIVE",
    isAllData: true,
  });

  const [selectProduct, setSelectProduct] = useState<any>([] as any);
  const [selectSaleUnit, setSelectSaleUnit] = useState<any>([] as any);
  const [sortBy, setSortBy] = useState<number>(3);

  useEffect(() => {
    const currentProductId = localStorage.getItem('currentFilterProductIds');
    const salesUnitIds = localStorage.getItem('currentFilterSalesUnitIds');
    const calculateStatus = localStorage.getItem('calculateStatus');
    const availableProductId = currentProductId ? JSON.parse(currentProductId) : [];
    const availableSalesUnitId = salesUnitIds ? JSON.parse(salesUnitIds) : [];

    if (availableProductId || availableSalesUnitId) {
      setSelectProduct(availableProductId);
      setSelectSaleUnit(availableSalesUnitId)
      setSortBy(Number(calculateStatus))
    }
  }, []);

  const currentProductData = data?.result?.map((item: any) => {
    return {
      value: item._id,
      label: item.product_name,
    };
  })

  const currentSaleUnitData = useMemo(() => {
    const currentProductValue = selectProduct.map((e: any) => e.value)
    if (Array.isArray(currentProductValue) && currentProductValue.length > 0) {
      return data?.result
        ?.filter((item: any) => currentProductValue.includes(item._id))
        .flatMap((item: any) => item.sales_units)
        .map((item: any) => ({
          label: item.sales_unit_name,
          value: item._id,
        }));
    }
    return [];
  }, [selectProduct, data?.result]);

  const resetValue = () => {
    setSelectProduct([])
    setSelectSaleUnit([])
    setSortBy(3)
  }

  const filterValue = () => {
    localStorage.setItem('currentFilterProductIds', JSON.stringify(selectProduct))
    localStorage.setItem('currentFilterSalesUnitIds', JSON.stringify(selectSaleUnit))
    localStorage.setItem('calculateStatus', `${sortBy}`)

    const currentSelectProduct = selectProduct.map((e: any) => e.value)
    const currentSelectSalesUnit = selectSaleUnit.map((e: any) => e.value)
    refetchWithFilters(currentSelectProduct, currentSelectSalesUnit, sortBy)
  }

  return (
    <Popup on="click" size="large" position={"bottom left"} trigger={<Grid>
      <CustomButton className="filterButton" iconWithText iconChild={<FilterIcon />} buttonOutLine theme="green" title="Filters" iconChildTextCustom={"productCustomBase"} />
    </Grid>
    }>
      <Grid className="productFilterMainView">
        <Grid.Column>
          <TitleView title={"Sort By"} />
          <Grid>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <p className={`productFilterTitle`}>
                Product Name
              </p>
              <Select
                value={selectProduct}
                onChange={(e: any) => {
                  setSelectProduct(e);
                }}
                isMulti
                options={currentProductData}
              />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <p className={`productFilterTitle`}>
                Sales Unit
              </p>
              <Select
                value={selectSaleUnit}
                onChange={(e: any) => {
                  setSelectSaleUnit(e);
                }}
                isMulti
                options={currentSaleUnitData || []}
              />
            </Grid.Column>
          </Grid>
          <p className="productFilterSortResultTitle mt-1">Sort Result</p>
          <Grid className="pt-0">
            <Grid.Column computer={5} tablet={8} mobile={16} className="pt-0">
              <Checkbox onClick={() => setSortBy(1)} checked={sortBy === 1} radio label='With calculation' />
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16} className="pt-0">
              <Checkbox onClick={() => setSortBy(2)} checked={sortBy === 2} radio label='With out calculation' />
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16} className="pt-0">
              <Checkbox onClick={() => setSortBy(3)} checked={sortBy === 3} radio label='Not relevant' />
            </Grid.Column>
          </Grid>
          <Grid className="pt-0">
            <Grid.Column computer={16} tablet={16} mobile={16} className="productFilterButton">
              <CustomButton buttonOutLine customBase="productFilterCustomButton" theme="green" title="Reset" onClick={resetValue} />
              <CustomButton theme="green" title="Apply" onClick={filterValue} />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Popup>
  );
};

export default ProductFilter;
