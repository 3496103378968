import * as React from "react";
import { getPathName } from "../../utils/utils";

const IngredientMapping = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "/drawing-ingredient"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        stroke="#535D6D"
        strokeWidth={1.563}
        d="M22.73 1.267c.651.65.651 1.703 0 2.353l-2.902 2.903-.229.229V23.12a.097.097 0 0 1-.097.097H.878a.097.097 0 0 1-.097-.097V4.496c0-.053.044-.097.097-.097H17.246l.23-.229 2.902-2.903a1.666 1.666 0 0 1 2.353 0Zm-4.106 21.756h.782V6.947l-1.334 1.333-4.404 4.405-.008.008-.008.008-.007.007c-.046.026-.15.078-.405.194-.228.104-.546.246-1.003.449-.602.268-1.445.643-2.642 1.181-.03.014-.046.012-.055.01a.11.11 0 0 1-.052-.03.11.11 0 0 1-.03-.053c-.002-.009-.005-.024.01-.055l1.044-2.33c.307-.686.498-1.113.625-1.388.091-.2.138-.294.161-.338l.007-.006.006-.007 4.408-4.407 1.334-1.334H.975V23.023H18.624ZM23.284.715ZM13.028 11.944l.552.552.552-.552 8.461-8.46a1.47 1.47 0 1 0-2.079-2.08l-8.46 8.46-.553.553.552.553.975.974Z"
      />
      <g clipPath="url(#a)">
        <path
          fill={
            pathName == "/drawing-ingredient"
              ? "var(--menuBarIconActive)"
              : "var(--menuBarIconDeactive)"
          }
          d="M16.231 16.843v3.75h-.337v-3.75h.337Zm-.637-.637v.337h-3.75v-.337h3.75Zm0 4.687v.337h-3.75v-.337h3.75Zm-1.003-2.878v1.406a.169.169 0 0 1-.17.169h-1.405a.169.169 0 0 1-.17-.169v-1.406c0-.093.076-.169.17-.169h1.406c.093 0 .169.076.169.169Zm-.338 1.237v-1.068h-1.069v1.068h1.07Zm-2.71-2.409v3.75h-.337v-3.75h.338Zm-1.105 1.706v.338H8.562v-.338h1.876ZM7.793 8.405v3.75h-.338v-3.75h.338Zm0 8.438v3.75h-.338v-3.75h.338Zm-.638-9.075v.337h-3.75v-.338l3.75.001Zm0 4.688v.337h-3.75v-.338h3.75Zm0 3.75v.337h-3.75v-.337h3.75Zm0 4.687v.337h-3.75v-.337h3.75ZM6.153 9.577v1.407a.169.169 0 0 1-.169.168H4.578a.169.169 0 0 1-.169-.168V9.577c0-.093.076-.168.17-.168h1.405c.094 0 .17.075.17.168Zm-.337 1.238V9.747h-1.07v1.068h1.07Zm.337 7.2v1.406a.169.169 0 0 1-.169.169H4.578a.169.169 0 0 1-.169-.169v-1.406c0-.093.076-.169.17-.169h1.405c.094 0 .17.076.17.169Zm-.337 1.237v-1.068h-1.07v1.068h1.07Zm-.366-5.69v1.875h-.338v-1.875h.338ZM3.106 8.405v3.75H2.77v-3.75h.337Zm0 8.438v3.75H2.77v-3.75h.337Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill={
              pathName == "/drawing-ingredient"
                ? "var(--menuBarIconActive)"
                : "var(--menuBarIconDeactive)"
            }
            d="M2 22V7h15v15z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IngredientMapping;
