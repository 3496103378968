import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WebrtcProvider } from "y-webrtc";
import { NP_SOCKET_API_URL } from "../../../config/constants";
import ydoc from "../../../api/socket";

export const useRTCProvider = (baseId: string | null, callback: () => void) => {
  useEffect(() => {
    callback();
    return () => {
      ydoc.destroy();
    };
  }, []);

  useEffect(() => {
    ydoc.destroy();
    if (baseId) {
      const provider = new WebrtcProvider(`${baseId}`, ydoc, {
        signaling: [NP_SOCKET_API_URL],
        filterBcConns: true,
      });
      if (provider.connected) {
        ydoc.load();
      }
    }
  }, [baseId]);
};
