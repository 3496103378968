import { Doc } from "yjs";
// For this example we use the WebrtcProvider to synchronize the document
// between multiple clients. Other providers are available.
// You can find a list here: https://docs.yjs.dev/ecosystem/connection-provider
import { io } from "socket.io-client";
import { NP_SOCKET_API_URL } from "../config/constants";

const token = localStorage.getItem("IdToken");

export const socket = io(NP_SOCKET_API_URL, {
  autoConnect: false,
  extraHeaders: {
    Authorization: `Bearer ${token}`,
  },
});
const ydoc = new Doc();

export default ydoc;
