import { useCallback, useMemo, useState } from "react";
import { Button, Dimmer, Loader, Popup } from "semantic-ui-react";
import Lottie from "lottie-react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import UpdateNodeModal from "./UpdateNodes/UpdateNodeModal";
import {
  useCreateDrawing,
  useGetDrawingToolData,
  usePutDrawingToolDataToQueue,
  useUpdateDrawing,
  useSendErrorDetails,
} from "../../api/drawingTool";
import CopyMappingModal from "./CopyMappingModal";
import DrawingErrorsModal from "./MappingErrorsModal";
import calculatorLoading from "../../assets/animations/CalculatorLoading.json";
import { errorView, successMessage } from "../../helpers/ErrorHandler";
import { getUserRolesInLocal } from "../../utils/cacheStorage";
import { ROLES } from "../../config/permission-maps";
import DrawingTool from "../../components/drawing-tool/DrawingTool";
import useUploadMappingImage from "../../hooks/useUploadMappingImage";
import ConfirmModal from "../../components/confirmViewModal/ConfirmModal";
import { CustomButton, TitleActionBar } from "../../components";
import ProcessGateFormModal from "./UpdateNodes/ProcessGate/components/ProcessGateFormModal";
import { getStringInsideBrackets } from "../../utils/utils";
import { NodeProps } from "reactflow";
import {
  useCreateProcessGate,
  useUpdateProcessGate,
} from "../../api/processGate/processGate";
import { useRTCProvider } from "./hooks/useRTCProvider";
import { MappingSourceType } from "../../config/enums/MappingSourceType.enum";
import { useDrawingInitialData } from "./hooks/useDrawingInitialData";

const MappingTool = () => {
  // Drawing States
  const [searchParams, setSearchParams] = useSearchParams();
  const paramId = searchParams.get("sourceId");

  const { mappingType: paramMappingType } = useParams<string>();
  const mappingType = paramMappingType as keyof typeof MappingSourceType;
  const mappingSourceType = MappingSourceType[mappingType];

  const [isVisibleCopyModal, setIsVisibleCopyModal] = useState(false);
  const [processGateNode, setProcessGateNode] = useState<NodeProps | null>();
  const [isProcessGateCreated, setIsProcessGateCreated] =
    useState<boolean>(false);
  const [productData, saveProductData] = useState<any>({});

  // Dropdown States
  const [searchKey, setSearchKey] = useState("");
  const [sourceId, setSourceId] = useState<string>("");
  const [calculateModalOpen, setCalculateModalOpen] = useState<boolean>(false);
  const [putDataToQueueErrors, setPutDataToQueueErrors] = useState<string[]>(
    []
  );

  const [calculateErrorMessage, setCalculateErrorMessage] =
    useState<Boolean>(false);

  const navigate = useNavigate();

  useRTCProvider(paramId, () => {
    const sourceId = searchParams.get("sourceId");
    if (sourceId) {
      setSourceId(sourceId);
    }
  });

  const { mutate: createProcessGateMutate } = useCreateProcessGate();

  const {
    ownerId: rawOwnerId,
    selectionData,
    isSourceLoading,
  } = useDrawingInitialData({
    searchKey,
    sourceId,
    saveProductData,
    mappingSourceType: mappingSourceType,
  });

  const ownerId = typeof rawOwnerId === "string" ? rawOwnerId : "";

  const {
    data: drawingToolData,
    isLoading: isDrawingToolLoading,
    refetch: reFetchDrawingTool,
  } = useGetDrawingToolData(mappingSourceType, sourceId);

  const [isLoadingImageUpload, uploadImage] = useUploadMappingImage(
    drawingToolData?.chartNodes || []
  );

  const { mutate: updateProcessGateAction } = useUpdateProcessGate();

  const { mutate: createDrawing, isLoading: isLoadingCreateDrawing } =
    useCreateDrawing(mappingSourceType);

  const { mutate: updateDrawing, isLoading: isLoadingUpdateDrawing } =
    useUpdateDrawing(mappingSourceType);

  const {
    isLoading: isLoadingPutDataToQueue,
    mutate: putDrawingToolDataToQueueAsync,
  } = usePutDrawingToolDataToQueue(mappingSourceType);

  const { mutate: sendErrorDetails } = useSendErrorDetails(mappingSourceType);

  const saveDrawing = useCallback(
    (chartEdges: any, chartNodes: any) => {
      if (drawingToolData?._id) {
        updateDrawing(
          {
            ...drawingToolData,
            chartEdges: chartEdges,
            chartNodes: chartNodes,
          },
          {
            onSuccess: () => {
              reFetchDrawingTool();
            },
          }
        );
        return;
      }
      const newDrawing = {
        sourceId,
        ownerId,
        chartEdges: chartEdges,
        chartNodes: chartNodes,
      };
      createDrawing(newDrawing, {
        onSuccess: () => {
          reFetchDrawingTool();
        },
      });
    },
    [drawingToolData, sourceId, updateDrawing, createDrawing]
  );

  // Email handler
  const handleClickEmail = async () => {
    const companyName = localStorage.getItem("companyName");
    const mappingUrl = window.location.href;
    setCalculateModalOpen(false);

    if (!ownerId || !sourceId || !companyName || !mappingUrl) {
      return;
    }

    sendErrorDetails(
      {
        ownerId,
        sourceId,
        companyName,
        mappingUrl,
      },
      {
        onSuccess: () => {
          setCalculateErrorMessage(false);
        },
        onError: (error) => {
          errorView(`Error sending details: ${error}`);
        },
      }
    );
  };

  const uploadMappingImage = () => {
    if (sourceId) {
      uploadImage(sourceId, () => {
        navigate(
          `/product-summary?salesUnitId=${sourceId}&productId=${ownerId}`
        );
      });
    }
  };

  // Calculate handler
  const calculateHandleApprove = () => {
    putDrawingToolDataToQueueAsync(drawingToolData, {
      onError: (error) => {
        setPutDataToQueueErrors(error.errors || []);
        setCalculateErrorMessage(true);
      },
      onSuccess: () => {
        if (!calculateErrorMessage) {
          setCalculateModalOpen(false);
          setCalculateErrorMessage(false);
          setTimeout(() => {
            uploadMappingImage();
          }, 500);
        }
      },
    });
  };

  const toggleProcessGateModel = (
    node: NodeProps | null,
    isCreated: boolean
  ) => {
    setIsProcessGateCreated(isCreated);
    setProcessGateNode(node);
  };

  //update process gate data
  const updateProcessGate = (processGateName: string, newNode: NodeProps) => {
    const facilityId = newNode.data.facilityId;
    let processGate = {
      processGateId: processGateNode?.data.reference.processGateId,
      processGateName,
      facilityId,
      ownerId,
      sourceId,
      userCompletedStage: "RECIPE",
    };
    updateProcessGateAction(processGate, {
      onSuccess(data) {
        saveProcessGateData(newNode, {
          processGateId: data._id,
          ownerId: data?.ownerId,
          sourceId: data?.sourceId,
        });
        toggleProcessGateModel(null, false);
      },
    });
  };

  //create process gate data
  const createProcessGate = (processGateName: string, newNode: NodeProps) => {
    const facilityId = newNode.data.facilityId;
    let processGate = {
      processGateName,
      facilityId,
      ownerId,
      sourceId,
      userCompletedStage: "RECIPE",
      processing: [],
      internalTransportation: [],
      internalStorage: [],
      packaging: [],
    };
    createProcessGateMutate(processGate, {
      onSuccess(data) {
        saveProcessGateData(newNode, {
          processGateId: data._id,
          ownerId: data?.ownerId,
          sourceId: data?.sourceId,
        });
        toggleProcessGateModel(null, false);
      },
    });
  };

  //save transport node data
  const saveProcessGateData = (nodeItem: any, processData: any) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        reference: processData,
      },
    };
    const chartNodes = drawingToolData?.chartNodes || [];
    const chartEdges = drawingToolData?.chartEdges || [];
    const updatedChartNodes = [...chartNodes, updatedNode];
    saveDrawing(chartEdges, updatedChartNodes);
    successMessage("Update process gate successfully");
  };

  // Data for dropdown

  const updateSourceId = (data: any) => {
    const id = data.value as string;
    setSourceId(id);
    searchParams.set("sourceId", id);
    setSearchParams(searchParams);
  };

  const cancel = () => {
    setCalculateModalOpen(false);
    setCalculateErrorMessage(false);
  };

  const checkDrawingButtonStatus = useMemo(
    () => getUserRolesInLocal(),
    [getUserRolesInLocal]
  );

  const checkUserIsSupplier = checkDrawingButtonStatus?.[0] === ROLES.supplier;

  const calculationTile = checkUserIsSupplier
    ? "Are you sure you have entered all the data for complete calculation?"
    : "Are you sure you have entered all the data for calculation?";

  if (!sourceId && mappingType === MappingSourceType.product) {
    return (
      <>
        <TitleActionBar
          title={"Product Name"}
          currentData={selectionData}
          defaultValue={sourceId}
          handleChangeState={(e: any, data: any) => {
            updateSourceId(data);
          }}
          onSearchChange={(e: any, data: any) => {
            setSearchKey(data.searchQuery);
          }}
          loading={isSourceLoading}
          search
        />
        <div className="product-empty-description">
          <h3>
            Please select the product or create the product if product is not
            available from the dropdown.
          </h3>
        </div>
      </>
    );
  }

  // Loading state check
  if (
    isDrawingToolLoading ||
    isLoadingCreateDrawing ||
    isLoadingUpdateDrawing ||
    isLoadingImageUpload
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  if (isLoadingPutDataToQueue) {
    return (
      <Dimmer active>
        <div className="loading-calculation">
          <Lottie animationData={calculatorLoading} loop={true} />
        </div>
      </Dimmer>
    );
  }

  return (
    <>
      <DrawingTool
        sourceId={sourceId}
        ownerId={ownerId}
        chartNodesData={drawingToolData?.chartNodes || []}
        chartEdgesData={drawingToolData?.chartEdges || []}
        saveDrawing={saveDrawing}
        actionBar={
          mappingType === MappingSourceType.product ? (
            <TitleActionBar
              title={"Product Name"}
              currentData={selectionData}
              defaultValue={sourceId}
              handleChangeState={(e: any, data: any) => {
                updateSourceId(data);
              }}
              onSearchChange={(e: any, data: any) => {
                setSearchKey(data.searchQuery);
              }}
              loading={isSourceLoading}
              search
            />
          ) : null
        }
        updateNodeModal={() => (
          <UpdateNodeModal sourceId={sourceId} ownerId={ownerId} />
        )}
        actionButtons={(chartNodes: any, chartEdges: any) => {
          return [
            drawingToolData?._id &&
            mappingSourceType !== MappingSourceType.ingredient ? (
              <Popup
                content="Copy Mapping."
                size="mini"
                trigger={
                  <Button
                    icon
                    color="teal"
                    className="drawing-copy-button"
                    onClick={() => {
                      setIsVisibleCopyModal(true);
                    }}
                  >
                    Duplicate
                  </Button>
                }
              />
            ) : null,

            <Popup
              content="Save Mapping."
              size="mini"
              trigger={
                <Button
                  icon
                  color="teal"
                  className="drawing-save-button"
                  onClick={() => {
                    saveDrawing(chartEdges, chartNodes);
                  }}
                >
                  Save
                </Button>
              }
            />,

            <Popup
              content="Calculate Mapping Data."
              size="mini"
              trigger={
                <Button
                  icon
                  color="teal"
                  className="drawing-calculate-button"
                  onClick={() => {
                    setCalculateModalOpen(true);
                  }}
                >
                  {checkUserIsSupplier ? "Complete" : "Calculate"}
                </Button>
              }
            />,
          ];
        }}
        contextProps={{
          productData,
          mappingSourceType,
          toggleProcessGateModel,
          saveProductData,
        }}
      />
      <ConfirmModal
        viewModal={calculateModalOpen}
        closeModal={() => {
          setCalculateModalOpen(false);
          setCalculateErrorMessage(false);
        }}
        cancel={() => cancel()}
        approve={calculateHandleApprove}
        title={"Calculation Mapping Data"}
        subTitle={
          calculateErrorMessage
            ? "Are you sure you have entered all the required data for calculations? Please check the data again or contact Nature Preserve."
            : calculationTile
        }
        otherConditionCheck={calculateErrorMessage}
        otherConditionBottomButton={
          <div className="customCalculationModalButton">
            <CustomButton
              theme="green"
              title="Check Data"
              onClick={cancel}
              buttonOutLine={true}
            />
            <Button onClick={handleClickEmail} positive>
              Send Details About the Issue
            </Button>
          </div>
        }
      />
      {isVisibleCopyModal ? (
        <CopyMappingModal
          drawingId={drawingToolData?._id}
          closeModal={() => {
            setIsVisibleCopyModal(false);
          }}
          onSuccessCopy={(sourceId: any) => {
            setSourceId(sourceId);
          }}
        />
      ) : null}
      <DrawingErrorsModal
        errors={putDataToQueueErrors}
        setPutDataToQueueErrors={setPutDataToQueueErrors}
      />
      {processGateNode ? (
        <ProcessGateFormModal
          setProcessGateNode={toggleProcessGateModel}
          newNode={processGateNode}
          edit={isProcessGateCreated}
          processGateName={getStringInsideBrackets(
            processGateNode?.data?.label
          )}
          updateProcessGate={updateProcessGate}
          createProcessGate={createProcessGate}
        />
      ) : null}
    </>
  );
};

export default MappingTool;
