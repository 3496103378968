import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DrawingContext } from "../../../components/drawing-tool/DrawingTool";
import { SearchValue, InputText } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import {
  useGetIngredientWholeList,
  usePutIngredientData,
  usePutSupplierData,
} from "../../../api/ingredients";
import { Dimmer, Loader } from "semantic-ui-react";
import { useReactFlow } from "reactflow";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import { successMessage } from "../../../helpers/ErrorHandler";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { useForm } from "react-hook-form";
import { without } from "lodash";

const initialIngredient = {
  id: null,
  title: "",
  suppliers: [],
  productIds: [],
};

const initialSupplier = {
  id: null,
  title: "",
  supplierData: {
    ingredientIds: [],
    productIds: [],
  },
};

export const IngredientBase = ({ modalData }: any) => {
  const {
    nodeItemId,
    chartEdges,
    chartNodes,
    ownerId,
    setNodeItem,
    setChartNodes,
    saveDrawing,
  } = useContext(DrawingContext);

  // get chart node item from node id
  const { deleteElements, getNode } = useReactFlow();
  const [ingredient, setIngredient] = useState(initialIngredient);
  const [supplier, setSupplier] = useState(initialSupplier);
  const [visible, setVisible] = useState(false);

  const values = useMemo(() => {
    return {
      ingredientName: ingredient.title,
      supplierName: supplier.title,
    };
  }, [nodeItemId, ingredient, supplier]);

  const { register } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const nodeItem = useMemo(() => getNode(nodeItemId || ""), [nodeItemId]);

  const {
    data: ingredientListByProduct,
    isLoading: isIngredientListByProductLoading,
    isSuccess,
  } = useGetIngredientWholeList(false);

  const {
    isLoading: updateIngredientDataLoading,
    mutateAsync: mutatePutIngredient,
  } = usePutIngredientData();

  const { mutateAsync: mutatePutSupplier } = usePutSupplierData();

  useEffect(() => {
    if (isSuccess) {
      const ingredient = ingredients?.find(
        (ing: any) => ing?.id === nodeItem?.data?.reference?.ingredientId
      );
      const supplier = ingredient?.suppliers?.find(
        (sup: any) => sup?._id === nodeItem?.data?.reference?.supplierId
      );
      setIngredient(ingredient || initialIngredient);
      setSupplier({
        id: supplier?._id || null,
        title: supplier?.supplier_name || "",
        supplierData: supplier,
      });
    }
  }, [nodeItem, isSuccess]);

  const { height } = useWindowDimensions();

  const ingredients = useMemo(() => {
    return ingredientListByProduct?.map((pi: any) => {
      return {
        id: pi.ingredientData?._id,
        title: pi.ingredientData?.ingredient_name,
        suppliers: pi.ingredientData?.suppliers,
        productIds: pi.ingredientData?.productIds,
      };
    });
  }, [ingredientListByProduct]);

  const suppliers = useMemo(() => {
    return ingredient?.suppliers?.map((pi: any) => ({
      id: pi._id,
      title: pi.supplier_name,
      supplierData: pi,
    }));
  }, [ingredient]);

  const updateIngredient = (status: any) => {
    let checkProductIngredientAvailable = ingredient?.productIds.some(
      (e: any) => e === ownerId
    );

    if (checkProductIngredientAvailable && !status) return;
    const withoutProduct = without(ingredient.productIds, ownerId);
    let updateProduct = [...ingredient.productIds, ownerId];
    const updateIngredientData = {
      _id: ingredient.id,
      ingredient_name: ingredient.title,
      ownerIds: status ? withoutProduct : updateProduct,
    };
    mutatePutIngredient(updateIngredientData, {
      onSuccess(data: any) {
        successMessage("Update Ingredient");
      },
    });
  };

  const updateSupplierData = (status: any) => {
    let checkProductIngredientAvailable =
      supplier?.supplierData?.ingredientIds?.some(
        (e: any) => e === ingredient.id
      );

    let checkProductAvailable = supplier?.supplierData?.productIds?.some(
      (e: any) => e === ownerId
    );

    if (checkProductIngredientAvailable && checkProductAvailable && !status)
      return;

    const withoutProduct = without(ingredient.productIds, ownerId);

    let ownerIds = status
      ? withoutProduct
      : [...supplier.supplierData.productIds, ownerId];

    let supplierDataUpdate = {
      _id: supplier.id,
      supplier_name: supplier?.title,
      productIds: ownerIds,
    };
    mutatePutSupplier(supplierDataUpdate, {
      onSuccess(data: any) {
        successMessage("Supplier update successfully");
      },
    });
  };

  const deleteIngredient = () => {
    setVisible(true);
  };

  const deleteIngredientNode = () => {
    updateIngredient(true);
    updateSupplierData(true);
    deleteElements({ nodes: [{ id: nodeItem?.id || "" }] });
    setNodeItem(null);
  };

  // submit callback function
  const onSubmit = useCallback(() => {
    updateIngredient(false);
    updateSupplierData(false);
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        label: ingredient.title,
        // reference is mongoose mix type so you can set any type of here please set necessary reference only
        reference: {
          ingredientId: ingredient.id,
          supplierId: supplier.id,
        },
        description: `${ingredient.title} ${supplier.title}`,
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });

    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
  }, [nodeItem, chartEdges, chartNodes, ingredient, supplier]);

  // Loading state check
  if (isIngredientListByProductLoading || updateIngredientDataLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const handleIngredientValidation = (status: any) => {
    if (status) {
      setIngredient(initialIngredient);
      setSupplier(initialSupplier);
    }
  };

  const handleSupplierValidation = (status: any) => {
    if (status) {
      setSupplier(initialSupplier);
    }
  };

  const disableEdit =
    ingredient.id && nodeItem?.data.reference && supplier.id ? true : false;

  return (
    <>
      <div
        style={{
          height: height * 0.9 - 100,
          overflowX: "hidden",
        }}
      >
        {disableEdit ? (
          <>
            <InputText
              register={register}
              placeholder="Ingredient Name"
              name="ingredientName"
              labelName={"Ingredient Name*"}
              customGridMain="customIngredientNameView"
              disabled={true}
            />
            <InputText
              register={register}
              placeholder="Supplier Name"
              name="supplierName"
              labelName={"Supplier Name*"}
              disabled={true}
            />
          </>
        ) : (
          <>
            <SearchValue
              searchDataValues={ingredients}
              title={"Ingredient Name*"}
              validationMessage="Please Select ingredient"
              checkOther={(status: any) => {
                handleIngredientValidation(status);
              }}
              defaultValue={ingredient.title}
              selectDetails={({ result }: any) => {
                setIngredient(result?.other);
              }}
              disable={disableEdit}
              customMainView="customMainViewIngredientSearch"
            />
            {ingredient.id ? (
              <SearchValue
                searchDataValues={suppliers}
                title={"Supplier Name*"}
                validationMessage="Please Select supplier"
                checkOther={(status: any) => {
                  handleSupplierValidation(status);
                }}
                defaultValue={supplier.title}
                selectDetails={({ result }: any) => {
                  setSupplier(result?.other);
                }}
                disable={disableEdit}
              />
            ) : null}
          </>
        )}
      </div>
      <MainBottomButtonView
        saveButtonStatus={true}
        deleteStatus={disableEdit}
        saveTitle={
          nodeItem?.data.reference && ingredient.id ? "Update" : "Save"
        }
        saveButton={() => onSubmit()}
        deleteButton={() => deleteIngredient()}
        type="submit"
      />
      <ConfirmModal
        viewModal={visible}
        closeModal={() => setVisible(false)}
        cancel={() => {
          setVisible(false);
        }}
        approve={() => {
          deleteIngredientNode();
        }}
        title="Delete Ingredient"
        subTitle="Are you sure you want to remove the ingredient data?"
      />
    </>
  );
};
