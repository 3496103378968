import React, { ReactNode, useState } from "react";
import { Grid, Icon, Label, Menu, MenuItem } from "semantic-ui-react";
import { PopOver } from "../popover/PopOver";
import { CustomButton } from "../button/Button";

import "./NotificationDropdown.scss";
import { useNavigate } from "react-router-dom";
import NotificationWithLabel from "../../assets/commonIcon/NotificationWithLabelIcon";
import NotificationIcon from "../../assets/commonIcon/NotificationIcon";
interface NotificationProps {
  children?: ReactNode;
  content?: ReactNode;
  notification?: any;
  onClick?:any
}

export const NotificationDropdown: React.FC<NotificationProps> = ({
  notification,
  onClick,
}) => {
  const [visibility,setVisibility] = useState(false);
  const notificationList = notification?.list || [];
  const navigate: any = useNavigate();

  return (
    <PopOver
      mainContentView={"notificationContentMain"}
      topArrowStyle="notificationArrow"
      customPopOverContent="notificationCustomPopOver"
      visibility={visibility}
      currentVisibility={setVisibility}
      children={
        <Menu compact>
          <MenuItem>
            {notification?.unReadCount > 0 ?
              <>
                <NotificationWithLabel />
                <Label color="red" floating>
                  {notification?.unReadCount}
                </Label>
              </>
              : <NotificationIcon />
            }
          </MenuItem>
        </Menu>
      }
      content={
        <div className="notificationCardMain">
          <Grid className="notificationHeader">
            <Grid.Column>
              <p className="notificationHeaderText">Your Notification</p>
            </Grid.Column>
          </Grid>

          <div className="notificationContentView">
            {notificationList?.map((item: any) => {
              const date = new Date(item.createdAt);

              const formattedDate = date.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });

              return (
                <Grid
                  className={
                    item.isRead
                      ? "notificationCardItemRead"
                      : "notificationCardItem"
                  }
                  onClick={() => onClick(item?._id)}
                >
                  <Grid.Row>
                    <Grid.Column computer={10}>
                      <p className="notificationTitle">{item.title}</p>
                    </Grid.Column>
                    <Grid.Column computer={6}>
                      <p className="notificationDate">{formattedDate}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="pt-0">
                    <Grid.Column>
                      <p>{item.body}</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              );
            })}
          </div>
          <Grid className="notificationBottom">
            <Grid.Column>
              <CustomButton
                title="View All"
                theme="green"
                onClick={() => {
                  setVisibility(false)
                  navigate("/notification")}}
                buttonOutLine
              />
            </Grid.Column>
          </Grid>
        </div>
      }
    />
  );
};
