import React, { useState, useEffect, useMemo } from "react";
import { Grid, Table } from "semantic-ui-react";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";

import {
  CustomButton,
  InputText,
  TitleView,
  CommonTable,
  DropDown,
  CommonModal,
} from "../../../components";
import { CommonCol, UNIT_TYPES } from "../../../config/constants";

const IngredientContent = ({
  visibleModal,
  setVisibleModal,
  activeProcessData,
  getIngredientData,
  ingredientData,
}: any) => {
  const values = useMemo(() => {
    return {
      ingredientData: ingredientData,
      processData: activeProcessData,
    };
  }, [visibleModal]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const { fields: ingredientFields } = useFieldArray({
    control,
    name: "ingredientData",
  });

  const { fields: processFields, update } = useFieldArray({
    control,
    name: "processData",
  });

  const onSubmit = (data: any) => {
    const dataView = {
      ingredientData: data.ingredientData,
      processData: data.processData,
    };
    getIngredientData(dataView);
    setVisibleModal(false);
  };

  const loadIngredientTableData = () => {
    return ingredientFields.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.ingredientName}</p>
          </Table.Cell>
          <Table.Cell>
            <InputText
              register={register}
              name={`ingredientData.${index}.content`}
              errorMessage={"Please enter content"}
              type="number"
              valueAsNumber={true}
            />
          </Table.Cell>
          <Table.Cell>
            <p>{data.unit}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadProcessTableData = () => {
    return processFields?.map((data: any, index: any) => {
      let unitData = data?.unit;
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.name}</p>
          </Table.Cell>
          <Table.Cell>
            <InputText
              register={register}
              placeholder={data.content}
              name={`processData.${index}.content`}
              type="number"
              valueAsNumber={true}
            />
          </Table.Cell>
          <Table.Cell>
          <div style={{marginTop: 18}}>
            <DropDown
              placeholder="Type"
              currentData={UNIT_TYPES}
              defaultValue={unitData}
              customGridColumn={"customAddProcessModal"}
              handleChangeState={(e: any, { value }: any) => {
                let tempData = data;
                delete tempData.id;
                update(index, {
                  ...tempData,
                  unit: value,
                });
              }}
            />
            </div>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          reset();
        }}
        centered={true}
        title="Add Ingredients"
      >
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <TitleView CustomTitleViewMain="packingMn" title="Ingredients" />
            <CommonTable tableHeaderData={CommonCol}>
              {loadIngredientTableData()}
            </CommonTable>
          </Grid.Column>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <TitleView CustomTitleViewMain="packingMn" title="Process" />
            <CommonTable tableHeaderData={CommonCol}>
              {loadProcessTableData()}
            </CommonTable>
          </Grid.Column>
        </Grid>

        <div className="sipButtonContent">
          <div className="sipCancel">
            <CustomButton
              onClick={() => setVisibleModal(false)}
              title="Cancel"
              theme="green"
              buttonOutLine={true}
            />
          </div>
          <div className="sipAdd">
            <CustomButton
              onClick={handleSubmit(onSubmit)}
              title="Save"
              theme="green"
            />
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default IngredientContent;
