import { useMemo, useState } from "react";
import {
  CustomButton,
  CommonTable,
  InputText,
  DropDown,
  AccordionTitleView,
} from "../../../components";
import { useForm } from "react-hook-form";
import { SipCol, PER_TYPES, PER_WEIGHT_UNITS } from "../../../config/constants";
import { InputNumber } from "../../../components/InputText/InputNumber";
import {
  Grid,
  AccordionTitle,
  Accordion,
  AccordionContent,
  Table,
} from "semantic-ui-react";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import "./Processing.scss";
import { AccordionNames } from "../../../helpers/AccordionHelper";

interface SipContentProps {
  chemicalDataType: any;
  getSipData: (data: unknown) => void;
  handleCIPValidation: () => boolean;
  currentCipSipData: any;
  currentAccordionStatus: (
    activeStatus: boolean,
    requestType: AccordionNames
  ) => boolean;
}

const SipContentContent = ({
  chemicalDataType,
  getSipData,
  handleCIPValidation = () => true,
  currentCipSipData,
  currentAccordionStatus,
}: SipContentProps) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);
  const [defaultPerTypeFrequency, setDefaultPerTypeFrequency] =
    useState("week");
  const [defaultPerTypePA, setDefaultPerTypePA] = useState("week");
  const [defaultWeightUnit, setDefaultWeightUnit] = useState("kg");
  const [defaultChemicalType, setDefaultChemicalType] = useState<any>("");
  const [filteredChemicals, setFilteredChemicals] = useState(chemicalDataType);
  const [chemicalData, setChemicalData] = useState<any>([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [enableUpdateButton, setEnableUpdateButton] = useState<any>(false);

  const values: any = useMemo(() => {
    setDefaultPerTypeFrequency(
      currentCipSipData?.[0]?.frequency?.per || "week"
    );
    setDefaultPerTypePA(
      currentCipSipData?.[0]?.productionAmount?.per || "week"
    );
    setDefaultWeightUnit(
      currentCipSipData?.[0]?.productionAmount?.perUnit || "kg"
    );

    setChemicalData(currentCipSipData?.[0]?.chemical || []);
    setFilteredChemicals(chemicalDataType);
    return {
      energyConsumption: currentCipSipData?.[0]?.energyConsumption,
      manufacturer: currentCipSipData?.[0]?.manufacturer,
      modelNumber: currentCipSipData?.[0]?.modelNumber,
      steamFlow: currentCipSipData?.[0]?.steamFlow,
      steamPressure: currentCipSipData?.[0]?.steamPressure,
      wasteWater: currentCipSipData?.[0]?.wasteWater,
      waterConsumption: currentCipSipData?.[0]?.waterConsumption,
      frequency: {
        amount: currentCipSipData?.[0]?.frequency?.amount || 0,
        per: currentCipSipData?.[0]?.frequency?.per || "week",
      },
      productionAmount: {
        amount: currentCipSipData?.[0]?.productionAmount?.amount || 0,
        per: currentCipSipData?.[0]?.productionAmount?.per || "week",
        perUnit: currentCipSipData?.[0]?.productionAmount?.perUnit || "kg",
      },
      concentration: 0,
      consumption: 0,
    };
  }, [accordionEnabled]);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const onSubmit = (data: any) => {
    data.frequency.per = defaultPerTypeFrequency;
    data.productionAmount.per = defaultPerTypePA;
    data.productionAmount.perUnit = defaultWeightUnit;
    data.chemical = chemicalData;
    delete data.concentration;
    delete data.consumption;

    getSipData([data]);
    currentAccordionStatus(false, AccordionNames.CIP_SIP);
    setAccordionEnabled(!accordionEnabled);
  };

  const cancelHandler = () => {
    currentAccordionStatus(false, AccordionNames.CIP_SIP);
    setAccordionEnabled(false);
  };

  const handleClick = () => {
    if (!handleCIPValidation()) return;
    const status = currentAccordionStatus(
      !accordionEnabled,
      AccordionNames.CIP_SIP
    );
    setAccordionEnabled(status);
  };

  const handleDeleteCancel = () => {};

  const handleDeleteApprove = () => {
    const updatedChemicalData = chemicalData.filter(
      (item: any, index: any) => index !== deleteIndex
    );
    setChemicalData(updatedChemicalData);
    setVisibleDeleteModal(false);
  };

  const addNewChemical = () => {
    const createNewChemicalData = () => ({
      chemicalName: filteredChemicals.find(
        (item: any) => item.value === defaultChemicalType
      )?.text,
      concentration: getValues("concentration"),
      consumption: getValues("consumption"),
    });

    const newChemicalData = createNewChemicalData();

    if (enableEdit) {
      const updatedChemicalData = chemicalData.map((item: any, index: number) =>
        index === deleteIndex ? { ...item, ...newChemicalData } : item
      );
      setChemicalData(updatedChemicalData);
      setEnableEdit(false);
    } else {
      setChemicalData((prevData: any) => [...prevData, newChemicalData]);
    }

    setValue("concentration", 0);
    setValue("consumption", 0);
    setDefaultChemicalType("1");
  };

  const onEditHandler = (item: any, index: number) => {
    setEnableEdit(true);
    setDeleteIndex(index);
    setValue("concentration", item.concentration);
    setValue("consumption", item.consumption);
    setDefaultChemicalType(
      chemicalDataType.find((data: any) => data.text === item.chemicalName)
        ?.value
    );
  };

  return (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={"CIP / SIP"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <Grid>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="paddingRemoveBottom"
              >
                <InputNumber
                  labelName={"Frequency"}
                  checkValidation={true}
                  transform={{
                    input: (value: any) =>
                      isNaN(value) || /^\d*\.\d+$/.test(value)
                        ? ""
                        : value.toString(),
                    output: (e: any) => {
                      return e;
                    },
                  }}
                  control={control}
                  placeholder={"0"}
                  name={`frequency.amount`}
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="paddingRemoveBottom"
              >
                <DropDown
                  labelName={"Time(s) per"}
                  customLabelText={"customLabelText"}
                  currentData={PER_TYPES}
                  defaultValue={defaultPerTypeFrequency}
                  customGridColumn={"customGridColomnType"}
                  handleChangeState={(e: any, { value }: any) => {
                    setDefaultPerTypeFrequency(value);
                  }}
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="paddingRemoveBottom"
              >
                <InputNumber
                  labelName={"Production Amount"}
                  transform={{
                    input: (value: any) =>
                      isNaN(value) ? "" : value.toString(),
                    output: (e: any) => {
                      return e;
                    },
                  }}
                  control={control}
                  placeholder={"0"}
                  defaultValue={
                    values?.cipData?.[values?.cipData.length - 1]?.value?.amount
                  }
                  name={`productionAmount.amount`}
                />
              </Grid.Column>
              <Grid.Column
                computer={4}
                tablet={16}
                mobile={16}
                className="paddingRemoveBottom"
              >
                <DropDown
                  labelName={"Unit"}
                  customLabelText={"customLabelText"}
                  currentData={PER_WEIGHT_UNITS}
                  defaultValue={defaultWeightUnit}
                  customGridColumn={"customGridColomnType"}
                  handleChangeState={(e: any, { value }: any) => {
                    setDefaultWeightUnit(value);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <DropDown
                  labelName={"Per"}
                  customLabelText={"customLabelText"}
                  currentData={PER_TYPES}
                  defaultValue={defaultPerTypePA}
                  customGridColumn={"customGridColomnType"}
                  handleChangeState={(e: any, { value }: any) => {
                    setDefaultPerTypePA(value);
                  }}
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <InputText
                  labelName={"Manufacturer"}
                  register={register}
                  placeholder="Manufacturer"
                  name="manufacturer"
                  errorMessage={"Please enter manufacturer"}
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <InputText
                  labelName={"Model Number"}
                  register={register}
                  placeholder="Model Number"
                  name="modelNumber"
                  errorMessage={"Please enter model number"}
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <InputNumber
                  labelName={"Energy Consumption"}
                  transform={{
                    input: (value: any) =>
                      isNaN(value) || value === 0 ? "" : value.toString(),
                    output: (e: any) => {
                      return e;
                    },
                  }}
                  control={control}
                  placeholder={"0"}
                  name={`energyConsumption`}
                  rightContentEnable={true}
                  rightContent={"kWh"}
                />
              </Grid.Column>
              <Grid.Column
                computer={4}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <InputNumber
                  labelName={"Steam Flow"}
                  transform={{
                    input: (value: any) =>
                      isNaN(value) || value === 0 ? "" : value.toString(),
                    output: (e: any) => {
                      return e;
                    },
                  }}
                  control={control}
                  placeholder={"0"}
                  name={`steamFlow`}
                  rightContentEnable={true}
                  rightContent={"kg/hr"}
                />
              </Grid.Column>
              <Grid.Column
                computer={4}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <InputNumber
                  labelName={"Steam Pressure"}
                  transform={{
                    input: (value: any) =>
                      isNaN(value) || value === 0 ? "" : value.toString(),
                    output: (e: any) => {
                      return e;
                    },
                  }}
                  control={control}
                  placeholder={"0"}
                  name={`steamPressure`}
                  rightContentEnable={true}
                  rightContent={"bar"}
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <InputNumber
                  labelName={"Water Consumption"}
                  transform={{
                    input: (value: any) =>
                      isNaN(value) || value === 0 ? "" : value.toString(),
                    output: (e: any) => {
                      return e;
                    },
                  }}
                  control={control}
                  placeholder={"0"}
                  name={`waterConsumption`}
                  rightContentEnable={true}
                  rightContent={"L"}
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <InputNumber
                  labelName={"Waste Water"}
                  transform={{
                    input: (value: any) =>
                      isNaN(value) || value === 0 ? "" : value.toString(),
                    output: (e: any) => {
                      return e;
                    },
                  }}
                  control={control}
                  placeholder={"0"}
                  name={`wasteWater`}
                  rightContentEnable={true}
                  rightContent={"L"}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>

        <div>
          {chemicalData.length > 0 && (
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className="pesticideTable mt-2 mb-2"
            >
              <CommonTable tableHeaderData={SipCol} className="pesticideTable">
                {chemicalData.map((item: any, index: any) => (
                  <Table.Row key={`fertilizer_${item.key}`}>
                    <Table.Cell>
                      <p>{item?.chemicalName ?? ""}</p>
                    </Table.Cell>
                    <Table.Cell>
                      <p>{item?.concentration ?? ""}</p>
                    </Table.Cell>
                    <Table.Cell>
                      <p>{item?.consumption ?? ""}</p>
                    </Table.Cell>
                    <Table.Cell>
                      <Grid>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          <MdModeEditOutline
                            cursor={"pointer"}
                            size={24}
                            color="var(--tableEditIcon)"
                            onClick={() => {
                              onEditHandler(item, index);
                              setEnableUpdateButton(true);
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          <MdDeleteOutline
                            cursor={"pointer"}
                            size={24}
                            color="var(--tableEditIcon)"
                            onClick={() => {
                              setVisibleDeleteModal(true);
                              setDeleteIndex(index);
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </CommonTable>
            </Grid.Column>
          )}
          <Grid>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <DropDown
                required={true}
                labelName="Chemical Name"
                placeholder="Select Chemical"
                currentData={chemicalDataType}
                defaultValue={defaultChemicalType}
                handleChangeState={(e, data: any) => {
                  setDefaultChemicalType(data?.value);
                }}
                onSearchChange={(e, data: any) => {
                  const filteredArray = chemicalDataType?.filter(
                    (chemical: any) =>
                      chemical?.text
                        ?.toLowerCase()
                        .includes(data.searchQuery?.toLowerCase())
                  );
                  setFilteredChemicals(filteredArray);
                }}
                loading={false}
                search
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={6} mobile={16}>
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Concentration"}
                placeholder={"0"}
                name="concentration"
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={6} mobile={16}>
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Consumption"}
                placeholder={"0"}
                name="consumption"
              />
            </Grid.Column>
            <Grid.Column
              computer={2}
              tablet={2}
              mobile={16}
              className="pesticideTable mt-1 mb-1"
            >
              <CustomButton
                theme="green"
                title={enableUpdateButton ? "Update" : ""}
                icon={!enableUpdateButton ? "plus" : null}
                customColumnStyle={"plusButton"}
                disabled={!defaultChemicalType}
                onClick={() => {
                  addNewChemical();
                  setEnableUpdateButton(false);
                  setDefaultChemicalType("");
                }}
              />
            </Grid.Column>
          </Grid>
        </div>

        <div className="sipContentButton">
          <div className="sipCancel">
            <CustomButton
              onClick={() => {
                cancelHandler();
              }}
              title="Cancel"
              theme="green"
              buttonOutLine={true}
            />
          </div>
          <div className="sipAdd">
            <CustomButton
              onClick={handleSubmit(onSubmit)}
              title="Save"
              theme="green"
            />
          </div>
        </div>
        <ConfirmModal
          viewModal={visibleDeleteModal}
          closeModal={() => setVisibleDeleteModal(false)}
          cancel={() => {
            handleDeleteCancel();
          }}
          approve={() => {
            handleDeleteApprove();
          }}
          title="Delete Chemical Data"
          subTitle="Are you sure you want to delete chemical data?"
        />
      </AccordionContent>
    </Accordion>
  );
};

export default SipContentContent;
