import * as React from "react"
const FilterIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        {...props}
    >
        <path
            fill="#71A950"
            d="M16.5 13.954a.563.563 0 0 1-.563.562h-3.824a2.198 2.198 0 0 1-4.245 0H2.061a.563.563 0 0 1 0-1.125h5.806a2.198 2.198 0 0 1 4.245 0h3.825a.562.562 0 0 1 .562.563Zm0-9.908a.563.563 0 0 1-.563.563H14.1a2.198 2.198 0 0 1-4.245 0H2.062a.562.562 0 0 1 0-1.125h7.793a2.198 2.198 0 0 1 4.245 0h1.838a.555.555 0 0 1 .562.562Zm0 4.95a.555.555 0 0 1-.563.563H7.164a2.198 2.198 0 0 1-4.245 0h-.856a.563.563 0 1 1 0-1.125h.856a2.198 2.198 0 0 1 4.244 0h8.776a.562.562 0 0 1 .562.562Z"
        />
    </svg>
)
export default FilterIcon
