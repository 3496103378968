const ProductFilterData = [
  { key: Math.random(), text: "All", value: "All" },
  { key: Math.random(), text: "Farm Product", value: "Farm Product" },
  { key: Math.random(), text: "Processed", value: "Processed" },
  { key: Math.random(), text: "Custom/Filter", value: "Custom/Filter" },
];

const FARMING_TYPES = [
  { key: 0, text: "Unknown", value: "Unknown" },
  { key: 1, text: "Organic", value: "Organic" },
  { key: 2, text: "Conventional", value: "Conventional" },
];

const FIELD_TYPES = [
  { key: 0, text: "Unknown", value: "Unknown" },
  { key: 1, text: "Open field", value: "OpenField" },
  { key: 2, text: "Greenhouse", value: "Greenhouse" },
];

const CULTIVATION_TYPES = [
  { key: 0, text: "Supplier", value: "Supplier" },
  { key: 1, text: "Generic", value: "Generic" },
];

const PROCESSING_TYPES = [
  {
    key: 1,
    text: "Sorting",
    value: "Sorting",
  },
  {
    key: 2,
    text: "washing",
    value: "washing",
  },
  {
    key: 3,
    text: "filling",
    value: "filling",
  },
  {
    key: 4,
    text: "cutting",
    value: "cutting",
  },
  {
    key: 5,
    text: "crushing",
    value: "crushing",
  },
  {
    key: 6,
    text: "blanching",
    value: "blanching",
  },
  {
    key: 7,
    text: "boiling",
    value: "boiling",
  },
  {
    key: 8,
    text: "can filling",
    value: "can filling",
  },
  {
    key: 9,
    text: "cooking",
    value: "cooking",
  },
  {
    key: 10,
    text: "electric boiling",
    value: "electric boiling",
  },
  {
    key: 11,
    text: "seaming",
    value: "seaming",
  },
  {
    key: 12,
    text: "slicing",
    value: "slicing",
  },
  {
    key: 13,
    text: "sterilization",
    value: "sterilization",
  },
  {
    key: 14,
    text: "conveyor belt",
    value: "conveyor belt",
  },
  {
    key: 15,
    text: "Balance tank",
    value: "Balance tank",
  },
];

const UNIT_TYPES = [
  {
    key: 1,
    text: "L",
    value: "L",
  },
  {
    key: 2,
    text: "ml",
    value: "ml",
  },
  {
    key: 3,
    text: "g",
    value: "g",
  },
  {
    key: 4,
    text: "kg",
    value: "kg",
  },
  {
    key: 5,
    text: "ton",
    value: "ton",
  },
];

const HEAT_SOURCE_UNIT_TYPES = [
  {
    key: 1,
    text: "kg",
    value: "kg",
  },
  {
    key: 2,
    text: "L",
    value: "L",
  },
  {
    key: 3,
    text: "gm3",
    value: "gm3",
  },
];

const REFRIGERANT_UNIT_TYPES = [
  {
    key: 1,
    text: "kg",
    value: "kg",
  },
  {
    key: 2,
    text: "L",
    value: "L",
  },
];

const TransportationType = [
  {
    key: 0,
    text: "freight, lorryfreight (Road)",
    value: "freight, lorryfreight (Road)",
  },
  {
    key: 1,
    text: "lorry with refrigeration machinefreight (Road)",
    value: "lorry with refrigeration machinefreight (Road)",
  },
  {
    key: 2,
    text: "light commercial vehicle (Road)",
    value: "light commercial vehicle (Road)",
  },
  {
    key: 3,
    text: "passenger car (Road)",
    value: "passenger car (Road)",
  },
  {
    key: 4,
    text: "electric bicycle (Road)",
    value: "electric bicycle (Road)",
  },
  {
    key: 5,
    text: "motor scooter (Road)",
    value: "motor scooter (Road)",
  },
  {
    key: 6,
    text: "freight train (Road)",
    value: "freight train (Road)",
  },
  {
    key: 7,
    text: "freight, sea, container ship (water)",
    value: "freight, sea, container ship (water)",
  },
  {
    key: 8,
    text: "freight, ferry  (water)",
    value: "freight, ferry  (water)",
  },
  {
    key: 9,
    text: "freight, inland waterways, barge  (water)",
    value: "freight, inland waterways, barge  (water)",
  },
  {
    key: 10,
    text: "freight, aircraft  (Air)",
    value: "freight, aircraft  (Air)",
  },
];

const UserType = [
  { key: 1, text: "Admin", value: "Admin" },
  { key: 2, text: "Super Admin", value: "Super Admin" },
  { key: 3, text: "Manager", value: "Manager" },
];

const FACILITY_TYPES = [
  {
    key: 1,
    text: "Processing Facility",
    value: "FACTORY",
  },
  {
    key: 2,
    text: "Storage Facility",
    value: "STORAGE",
  },
];

const FACILITY_OWNERSHIP = [
  {
    key: 1,
    text: "Internal",
    value: "Internal",
  },
  {
    key: 2,
    text: "External",
    value: "External",
  },
];

const PACKAGING_LEVELS = [
  {
    key: 1,
    text: "Base unit packaging",
    value: "Base unit packaging",
  },
  {
    key: 2,
    text: "Inner pack packaging",
    value: "Inner pack packaging",
  },
  {
    key: 3,
    text: "Case packaging",
    value: "Case packaging",
  },
  {
    key: 4,
    text: "Pallet packaging",
    value: "Pallet packaging",
  },
];

const DATA_TYPES = [
  { key: 0, text: "Provided by Supplier", value: "SUPPLIER" },
  { key: 1, text: "Generic", value: "GENERIC" },
  { key: 2, text: "Provided by User", value: "USER" },
];

const INGREDIENT_TYPE = [
  {
    key: 1,
    text: "RAW",
    value: "RAW",
  },
  {
    key: 2,
    text: "PROCESSED",
    value: "PROCESSED",
  },
];

const Yes_No = [
  { key: 0, text: "Yes", value: "Yes" },
  { key: 1, text: "No", value: "No" },
];

const ENERGY_UNIT_TYPE = [
  {
    key: 1,
    text: "kWh",
    value: 1,
  },
  {
    key: 2,
    text: "MJ",
    value: 2,
  },
  {
    key: 3,
    text: "thm",
    value: 3,
  },
  {
    key: 4,
    text: "btu",
    value: 4,
  },
];

const PER_TYPES = [
  {
    key: "day",
    text: "day",
    value: "day",
  },
  {
    key: "week",
    text: "week",
    value: "week",
  },
  {
    key: "month",
    text: "month",
    value: "month",
  },
  {
    key: "year",
    text: "year",
    value: "year",
  },
];

const PER_WEIGHT_UNITS = [
  {
    key: "kg",
    text: "kg",
    value: "kg",
  },
  {
    key: "ton",
    text: "ton",
    value: "ton",
  },
];

const SimulationImpactFarm = [
  { key: "cultivations", name: "internalStorageName" },
  { key: "internalStorage", name: "internalStorageName" },
  { key: "internalTransportation", name: "internalTransportName" },
  { key: "packaging", name: "packingName" },
  { key: "processing", name: "processingName" },
];

const SimulationImpactProcess = [
  { key: "internalStorage", name: "internalStorageName" },
  { key: "internalTransportation", name: "internalTransportName" },
  { key: "packaging", name: "packingName" },
  { key: "processing", name: "processingName" },
];

export {
  CULTIVATION_TYPES,
  DATA_TYPES,
  ENERGY_UNIT_TYPE,
  FACILITY_OWNERSHIP,
  FACILITY_TYPES,
  FARMING_TYPES,
  FIELD_TYPES,
  HEAT_SOURCE_UNIT_TYPES,
  INGREDIENT_TYPE,
  PACKAGING_LEVELS,
  PER_TYPES,
  PER_WEIGHT_UNITS,
  PROCESSING_TYPES,
  ProductFilterData,
  REFRIGERANT_UNIT_TYPES,
  SimulationImpactFarm,
  SimulationImpactProcess,
  TransportationType,
  UNIT_TYPES,
  UserType,
  Yes_No,
};
