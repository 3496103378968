import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiArrowDownDropCircleOutline,
  mdiArrowRightDropCircleOutline,
} from "@mdi/js";
import { Table } from "semantic-ui-react";
import { format } from "date-fns";

import "./customSupplierRequest.scss";
import { isArray } from "lodash";
import { SupplierNotificationRequestStatus, SupplierRequestStatus } from "../../config/constants";
import { warningMessage } from "../../helpers/ErrorHandler";

interface CustomSupplierRequest {
  data: any;
}

const CustomSupplierRequest: React.FC<CustomSupplierRequest> = ({ data }) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const handleRowClick = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);
    setExpandedRows(newExpandedRows);
  };

  const renderItemCaret = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return mdiArrowDownDropCircleOutline;
    } else {
      return mdiArrowRightDropCircleOutline;
    }
  };
  const renderItem = (item: any, index: number) => {
    const defaultStatus: number = item?.status?.[0]?.[0] || 1;
    const defaultStatusTime: string = format(
      new Date(item?.status?.[0]?.[1] || new Date()),
      "dd/MM/yyyy"
    );
    const checkStatusIsAvailable =
      isArray(item.status) && item.status.length > 1;

    const itemRows = [
      <Table.Row key={`row-data-${index}`}>
        <Table.Cell
          onClick={() => {
            checkStatusIsAvailable
              ? handleRowClick(index)
              : warningMessage("Still not available more status");
          }}
        >
          <Icon
            className="table-icon"
            color="var(--tableEditIcon)"
            path={renderItemCaret(index)}
            size={1.2}
          />
        </Table.Cell>
        <Table.Cell>{item.supplier.supplier_name}</Table.Cell>
        <Table.Cell>{item.ingredients.ingredient_name}</Table.Cell>
        <Table.Cell>{SupplierNotificationRequestStatus[defaultStatus]}</Table.Cell>
        <Table.Cell>{defaultStatusTime}</Table.Cell>
      </Table.Row>,
    ];
    if (
      isArray(item.status) &&
      item.status.length > 1 &&
      expandedRows.includes(index)
    ) {
      item.status.slice(1).map((data: any, index: any) => {
        const statusNo:any = data[0] || 1;
        const statusData = format(
          new Date(data[1] || new Date()),
          "dd/MM/yyyy"
        );

        return itemRows.push(
          <Table.Row key={"row-expanded-" + index}>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>{SupplierNotificationRequestStatus[statusNo]}</Table.Cell>
            <Table.Cell>{statusData}</Table.Cell>
          </Table.Row>
        );
      });
    }
    return itemRows;
  };

  return (
    <Table singleLine className="custom-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Supplier Name</Table.HeaderCell>
          <Table.HeaderCell>Ingredient name</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data?.map((item: any, index: any) => renderItem(item, index))}
      </Table.Body>
    </Table>
  );
};

export default CustomSupplierRequest;
