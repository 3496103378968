import { Grid, Table } from "semantic-ui-react";

import { TitleView, CommonTable } from "../../../components";
import { SupplierIngredientTable } from "../../../config/constants";

import "./supplierDetails.scss";
interface Ingredient {
    _id: string;
    ingredient_name?: string;
}
interface IngredientDetail {
    ingredient_id: string;
    unit_size?: string;
    unit_type?: string;
    ean_bar_code?: string;
}
interface SupplierIngredientListProps {
    ingredients: Ingredient[];
    ingredientDetails: IngredientDetail[];
}

const SupplierIngredientList: React.FC<SupplierIngredientListProps> = ({
    ingredients,
    ingredientDetails,
}) => {
    const loadTableData = () => {
        return ingredients.map((data, index) => {
            const ingredientDetail = ingredientDetails.find(
                (ingredient) => ingredient.ingredient_id === data._id
            );
            return (
                <Table.Row className="tbleR" key={index}>
                    <Table.Cell>
                        <p>{data?.ingredient_name ?? ""}</p>
                    </Table.Cell>
                    <Table.Cell>
                        <p>{ingredientDetail?.unit_size ?? ""}</p>
                    </Table.Cell>
                    <Table.Cell>
                        <p>{ingredientDetail?.unit_type ?? ""}</p>
                    </Table.Cell>
                    <Table.Cell>
                        <p>{ingredientDetail?.ean_bar_code ?? ""}</p>
                    </Table.Cell>
                    <Table.Cell>
                        <Grid></Grid>
                    </Table.Cell>
                </Table.Row>
            );
        });
    };

    return (
        <>
            {ingredients.length > 0 && (
                <TitleView
                    title="Ingredient List"
                    CustomTitleViewMain="supplierAddCustomIngredientTitle"
                />
            )}
            <Grid>
                <Grid.Column computer={16} tablet={16} mobile={16} className="userBankDetailsMain">
                    <div>
                        <Grid.Column computer={16} tablet={16} mobile={16} className="productRegisterTable">
                            {ingredients.length > 0 && (
                                <CommonTable tableHeaderData={SupplierIngredientTable}>
                                    {loadTableData()}
                                </CommonTable>
                            )}
                        </Grid.Column>
                    </div>
                </Grid.Column>
            </Grid>
        </>
    );
};

export default SupplierIngredientList;
