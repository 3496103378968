import React from "react";

import Login from "../screens/login/Login";
import PasswordReset from "../screens/passwordReset/PasswordReset";
import PasswordForgot from "../screens/forgotPassword/ForgotPassword";
import { adminAccess, userAccess,supplierAccess } from "./useRoleAccess";
import Unauthorized from "../screens/unauthorized/Unauthorized";
import MetaDashboard from "../screens/dashboard/MetaDashboard";
import SupplierDrawingRequests from "../screens/supplier_drawing_request/SupplierDrawingRequests";
import NotificationList from "../screens/notificationList/NotificationList";
import SupplierRequest from "../screens/supplierRequest/SupplierRequest";


export const CommonRoutes = [
  {
    path: "/auth",
    route: <Login />,
    protectRoutes: false,
  },
  {
    path: "/password-reset",
    route: <PasswordReset />,
    protectRoutes: false,
  },
  {
    path: "/password-forgot",
    route: <PasswordForgot />,
    protectRoutes: false,
  },
  {
    path: "/unauthorized",
    route: <Unauthorized />,
    protectRoutes: false,
  },
  {
    path: "/",
    route: <MetaDashboard />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "/drawing-requests",
    route: <SupplierDrawingRequests />,
    protectRoutes: true,
    roleGrantAccess: [...supplierAccess, ...adminAccess, ...userAccess],
  },
  {
    path: "/notification",
    route: <NotificationList />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "/supplierRequest",
    route: <SupplierRequest />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
