import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Popup } from "semantic-ui-react";

import { MENU_SIDE_BAR } from "../../config/constants";
import useWindowDimensions from "../../hooks/windowsSize";
import PermissionsGate from "../PermissionGate";
import { SCOPES } from "../../config/permission-maps";
import { menuBarIconMap } from "../../utils/menuBar";
import DashboardIcon from "../../assets/menuIcon/dashboardIcon";
import { getPathName } from "../../utils/utils";

import "./menuBar.scss";

const MenuBar = ({ modalStatus = () => {} }: any) => {
  const navigate = useNavigate();
  const pathName = getPathName();

  const { height } = useWindowDimensions();

  const loadMenuIcon = (iconName: string): JSX.Element => {
    const SelectedIcon = menuBarIconMap[iconName] || DashboardIcon;
    return <SelectedIcon />;
  };

  return (
    <div onMouseEnter={() => modalStatus()}>
      <div
        className="mainMenuView"
        style={{
          height: height,
        }}
      >
        {MENU_SIDE_BAR.map((data, mainIndex) => {
          return (
            <div key={mainIndex} className="mainBarView">
              {data.subTitle.map((sub) => {
                const isSelectCurrentPath = pathName === sub.path;
                return (
                  <PermissionsGate
                    scopes={[SCOPES.canAccessInSideMenu]}
                    feature={sub.name}
                  >
                    <div
                      className="sideBarIconDefault"
                      onClick={() => navigate(sub.path)}
                    >
                      <Grid>
                        <Grid.Column
                          computer={16}
                          textAlign="center"
                          className="pt-0 pb-0"
                        >
                          {loadMenuIcon(sub.name)}
                        </Grid.Column>
                        <Grid.Column
                          computer={16}
                          textAlign="center"
                          className="pt-0"
                        >
                          <p
                            className={`menuIconTitle ${
                              isSelectCurrentPath && "menuIconTitleSelect"
                            }`}
                          >
                            {sub.titleName}
                          </p>
                        </Grid.Column>
                      </Grid>
                    </div>
                  </PermissionsGate>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MenuBar;
