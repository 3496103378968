import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  ReactElement,
} from "react";
import { Grid } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import _ from "lodash";
import "./inputText.scss";

interface HandleValidation {
  checkCustomRequired(data: any): void;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  computer?: any;
  tablet?: any;
  mobile?: any;
  name?: string;
  register?: any;
  errors?: any;
  required?: boolean;
  placeholder?: string | number | any;
  labelName?: string;
  errorMessage?: string;
  customLabelText?: string;
  customGridMain?: string;
  customGridColumn?: string;
  customText?: string;
  customErrorText?: string;
  emptyCheck?: any;
  availableValueCheck?: any;
  checkValue?: boolean;
  defaultValues?: any;
  validateHandle?: any;
  disabled?: boolean;
  pattern?: string;
  onChangeFunction?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  callOnBlur?: () => any;
  customInputValidation?: boolean;
  type?: string;
  valueAsNumber?: boolean;
  minNumber?: number;
  step?: number;
  id?: string;
  customRequired?: boolean;
  rightContentEnable?: boolean;
  rightContent?: any;
}

export const InputText = forwardRef<HandleValidation, InputProps>(
  (
    {
      computer,
      tablet,
      mobile,
      name,
      register = () => {},
      errors,
      required = false,
      placeholder,
      labelName,
      errorMessage = "This field is required",
      customLabelText = "",
      customGridMain = "",
      customGridColumn = "",
      customText = "",
      customErrorText = "",
      emptyCheck,
      availableValueCheck,
      checkValue = false,
      defaultValues,
      validateHandle = () => {},
      disabled = false,
      pattern,
      onChangeFunction = () => {},
      onFocus = () => {},
      onBlur = () => {},
      customInputValidation = false,
      type = "text",
      valueAsNumber = false,
      minNumber = -1000,
      step,
      id,
      customRequired = false,
      rightContentEnable = false,
      rightContent = "",
    },
    ref
  ) => {
    const [customValidation, setCustomValidation] = useState(false);

    useImperativeHandle(ref, () => ({
      checkCustomRequired(data: any) {
        setCustomValidation(isEmpty(data) && customRequired);
      },
    }));

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        valueAsNumber &&
        e.target.value.charAt(0) === "0" &&
        e.target.value.length > 1 &&
        e.target.value[1] !== "."
      ) {
        e.target.value = e.target.value.slice(1);
      }
      onChangeFunction(e);
    };

    const hasError = errors || customValidation;
    const errorClass = hasError
      ? customValidation
        ? "textInputFormCustomError"
        : "textInputFormError"
      : "textInputFormText";
    const errorTextClass = hasError
      ? customValidation
        ? "textInputCustomError"
        : "textInputError"
      : "";

    const errorMessageText =
      customValidation && customRequired ? errorMessage : errors?.message;

    return (
      <>
        {labelName && (
          <label className={`inputTextLable ${customLabelText}`}>
            {labelName}
          </label>
        )}
        <Grid className={customGridMain}>
          <Grid.Column
            computer={computer}
            tablet={tablet}
            mobile={mobile}
            className={`inputTextContanier ${customGridColumn}`}
          >
            <div className="inputWrapper">
              {(checkValue
                ? availableValueCheck || isEmpty(emptyCheck)
                : true) && (
                <input
                  id={id}
                  className={`${errorClass} ${customText} ${
                    rightContentEnable ? "customInputDataView" : ""
                  }`}
                  autoComplete="off"
                  placeholder={placeholder}
                  defaultValue={defaultValues}
                  disabled={disabled}
                  {...register(name, {
                    onChange: handleOnChange,
                    required,
                    validate: validateHandle,
                    valueAsNumber,
                  })}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  pattern={pattern}
                  type={type}
                  min={minNumber}
                  step={step}
                />
              )}
              {rightContentEnable && (
                <div className="inputRightContent">
                  <p className="rightContentText">{rightContent}</p>
                </div>
              )}
            </div>

            {hasError && (
              <span className={`${errorTextClass} ${customErrorText}`}>
                {errorMessage}
              </span>
            )}
          </Grid.Column>
        </Grid>
      </>
    );
  }
);
