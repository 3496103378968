import React from "react";

import { CommonModal } from "../../components";
import { BarChart } from "../../components/charts";
import CommonSplitContainer from "../../components/commonSplitContainer/CommonSplitContainer";
import MainBottomButtonView from "../../components/mainBottomButtonView/MainBottomButtonView";


const ProductSummaryBarChart = ({ visibleModal, setVisibleModal, barChatValue, loading }: any) => {
  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
        }}
        centered={true}
      >
        <CommonSplitContainer
          currentHeight={0.95}
          topChild={
            <BarChart
              title="Processing Impacts"
              yAxisName="Impact kgCO2 per unit"
              isLoading={loading}
              values={barChatValue}
            />
          }
          bottomChild={
            <MainBottomButtonView
              cancelButtonStatus
              cancelButton={() => setVisibleModal(false)}
            />
          }
        />

      </CommonModal>
    </>
  );
};

export default ProductSummaryBarChart;
