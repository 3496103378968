import React from "react";

const HiddenPasswordIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#C1C7D0"
      d="M9 14.833a9.79 9.79 0 0 0 2.165-.234l-1.464-1.464a9.036 9.036 0 0 1-.701.032c-4.46 0-6.187-3.205-6.605-4.167a7.167 7.167 0 0 1 1.257-1.914L2.487 5.92C1.205 7.31.719 8.709.71 8.737a.833.833 0 0 0 0 .527c.018.055 1.93 5.57 8.29 5.57ZM9 3.167c-1.53 0-2.788.33-3.837.817L2.09.911.911 2.089l15 15 1.178-1.178-2.766-2.766c2.179-1.626 2.956-3.846 2.968-3.88a.832.832 0 0 0 0-.528c-.018-.056-1.93-5.57-8.291-5.57Zm4.143 8.798-1.9-1.9A2.42 2.42 0 0 0 11.5 9c0-1.367-1.133-2.5-2.5-2.5-.382 0-.74.098-1.064.258L6.429 5.25A7.75 7.75 0 0 1 9 4.833c4.46 0 6.187 3.205 6.605 4.167a7.133 7.133 0 0 1-2.462 2.965Z"
    />
  </svg>
);
export default HiddenPasswordIcon;
