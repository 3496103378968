import { useMutation, useQuery } from "@tanstack/react-query";
import { successMessage } from "../helpers/ErrorHandler";
import { ApiServiceErr, MutOptions, axiosApi } from "./apiService";

export const useProcessCalculateData = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.put(`/process-module/queue/${id}`);
    successMessage(response.data);
    return response.data;
  }, opt);

export const usePostProcessGateSimulationInternalTransportData = (
  data?: any
) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/process-gate/internal-transport?processGateId=${data?.processGateId}`,
      data?.transportData
    );
    return response.data;
  });
};

export const usePutProcessGateSimulationInternalTransportData = (
  data?: any
) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/process-gate/internal-transport/${data?.internalTransportId}?processGateId=${data?.processGateId}`,
      data?.transportData
    );
    return response.data;
  });
};

export const useDeleteProcessGateSimulationInternalTransportData = (
  data?: any
) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/process-gate/internal-transport/${data?.internalTransportId}?processGateId=${data?.processGateId}`
    );
    return response.data;
  });
};

export const useGetProcessGateSimulationInternalTransportById = (
  processGateInternalTransportId: any
) =>
  useQuery(
    ["getProcessGateSimulationInternalTransportById"],
    async () => {
      const response = await axiosApi.get(
        `/process-gate/internal-transport/${processGateInternalTransportId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!processGateInternalTransportId,
    }
  );

export const usePostProcessGateInternalTransportData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/process-gate/internal-transport?processGateId=${data?.processGateId}`,
      data?.transportData
    );
    return response.data;
  });
};

export const usePutProcessGateInternalTransportData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/process-gate/internal-transport/${data?.internalTransportId}?processGateId=${data?.processGateId}`,
      data?.transportData
    );
    return response.data;
  });
};

export const useDeleteProcessGateInternalTransportData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/process-gate/internal-transport/${data?.internalTransportId}?processGateId=${data?.processGateId}`
    );
    return response.data;
  });
};

export const useGetProcessGateInternalTransportById = (
  processGateInternalTransportId: any
) =>
  useQuery(
    ["getProcessGateInternalTransportById"],
    async () => {
      const response = await axiosApi.get(
        `/process-gate/internal-transport/${processGateInternalTransportId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!processGateInternalTransportId,
    }
  );

export const usePostProcessGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/process-gate/internal-storage?processGateId=${data?.processGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const usePutProcessGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/process-gate/internal-storage/${data?.internalStorageId}?processGateId=${data?.processGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const useDeleteProcessGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/process-gate/internal-storage/${data?.internalStorageId}?ProcessGate=${data?.processGateId}`
    );
    return response.data;
  });
};

export const useGetProcessGateInternalStorageById = (
  processGateInternalStorageId: any
) =>
  useQuery(
    ["getProcessGateInternalStorageById"],
    async () => {
      const response = await axiosApi.get(
        `/process-gate/internal-storage/${processGateInternalStorageId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

export const usePostProcessGateProcessData = () => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/process-gate/processing/${data?.processGateId}`,
      data
    );
    return response.data;
  });
};

export const usePutProcessGateProcessData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/process-gate/processing/${data?.processId}`,
      data
    );
    return response.data;
  });
};

export const useDeleteProcessGateProcessData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/process-gate/processing/${data?.processId}?processGateId=${data?.processGateId}`
    );
    return response.data;
  });
};

export const useGetProcessGateProcessDataById = (processGateProcessId: any) =>
  useQuery(
    ["getProcessGateProcessDataById"],
    async () => {
      const response = await axiosApi.get(
        `/process-gate/processing/${processGateProcessId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!processGateProcessId,
    }
  );

export const usePostProcessGateRecipeData = () => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/${data.type}/recipe/${data?.processId}`,
      data
    );
    return response.data;
  });
};

export const useGetProcessRecipeByProcessId = (data: {
  recipeId: string;
  type: string;
}) =>
  useQuery(
    ["getProcessRecipeByProcessId"],
    async () => {
      const response = await axiosApi.get(
        `/${data.type}/recipe/${data.recipeId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!data.recipeId,
    }
  );
