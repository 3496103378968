import MappingTool from "../screens/mapping_tool/MappingTool";
import SimulateDrawingTool from "../screens/simulate_drawing_tool/SimulateDrawingTool";
import { adminAccess, supplierAccess, userAccess } from "./useRoleAccess";

export const MappingRoutes = [
  {
    path: ":mappingType",
    route: <MappingTool />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess, ...supplierAccess],
  },
  {
    path: "simulate-mapping/:productId/:salesUnitId",
    route: <SimulateDrawingTool />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
