import React, { useMemo, useState } from "react";
import { Dropdown, Grid, Icon } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import { InputNumber } from "../../../../components/InputText/InputNumber";

import "./NetZeroCard.scss";
import { getImpactChangePercentage } from "../../../../utils/utils";

const NetZeroCard = ({ currentSimulationData }: any) => {
  const IMPACT_CATEGORY = [{ key: "1", text: "Packaging", value: "packaging" }];
  const [impactCategory, setImpactCategory] = useState<string>("Packaging");
  const currentImpactChange =
    currentSimulationData?.productSummary?.totalImpact?.climateChange
      ?.measure || 0;

  const simulateImpactChange =
    currentSimulationData?.simulationProductSummary[0]?.totalImpact || 0;

  const values = useMemo(() => {
    return {
      size: 1000,
      currentImpactChange,
      simulateImpactChange,
    };
  }, [currentSimulationData]);

  const {
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const productSize = watch("size");
  const simulateChangedImpact = watch("simulateImpactChange");

  const { currentImpact, simulateImpact } = useMemo(() => {
    return {
      currentImpact: currentImpactChange * productSize,
      simulateImpact: simulateImpactChange * productSize,
    };
  }, [productSize, simulateChangedImpact]);

  const impactChange = getImpactChangePercentage(
    currentImpactChange,
    simulateImpactChange
  )

  return (
    <Grid>
      <Grid.Column computer={16} className="pl-0 pr-0">
        <p className="netZeroTitle">Environmental Impact Category</p>
        <Dropdown
          placeholder="Select Impact Category"
          fluid
          selection
          options={IMPACT_CATEGORY}
          defaultValue={impactCategory}
          value={impactCategory}
          onChange={(e: any, data: any) => {
            setImpactCategory(data.value);
          }}
        />
      </Grid.Column>
      <Grid.Column computer={16} className="netZeroCardMain">
        <div className="netZeroTopView">
          <p className="netZeroCardTitle">Change in Climate Impact</p>
          <p className="netZeroCardSubTitle">(kgCO2eq pr unit)</p>
        </div>
        <Grid>
          <Grid.Column computer={8}>
            <p className="netZeroCurrentImpact">Current Impact​</p>
            <p className="netZeroCurrentImpactValue">
              {currentImpactChange.toFixed(2)}
            </p>
          </Grid.Column>
          <Grid.Column computer={8}>
            <Grid>
              <Grid.Column computer={7}>
                <p className="netZeroCommonTitle">Impact Change</p>
              </Grid.Column>
              <Grid.Column
                computer={9}
                textAlign="center"
                verticalAlign="middle"
                className="netZeroImpactReductionValueMain"
              >
                <div className="netZeroImpactReductionValue">
                  <p className="mb-0 netZeroReduction">{`${impactChange}%`}</p>
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column computer={8} className="pt-0">
            <p className="netZeroCommonTitle">Simulated Impact​</p>
            <p className="netZeroCurrentSimulatedImpact">
              {simulateImpactChange.toFixed(2)}
            </p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column computer={16} className="netZeroProdSize">
            <div className="bottomMiddleTitle">
              <p className="netZeroMiddleCardTitle">
                kg CO2 eq pr production size
              </p>
            </div>
            <InputNumber
              control={control}
              errors={errors.size}
              labelName={null}
              placeholder="Production Size"
              name="size"
            />
            <Grid>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <p className="netZeroCurrentBottomImpact">Current Impact​</p>
                <p className="netZeroCurrentBottomImpactValue">
                  {currentImpact.toFixed(2)}
                </p>
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="pt-0"
              >
                <p className="netZeroCommonBottomTitle">Simulated Impact​</p>
                <p className="netZeroCurrentBottomSimulatedImpact">
                  {simulateImpact.toFixed(2)}
                </p>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  );
};

export default NetZeroCard;
