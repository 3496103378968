import { Node, Edge, NodeProps } from "reactflow";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Popup, Dimmer } from "semantic-ui-react";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import "./SimulateDrawingTool.scss";
import UpdateNodeModal from "./UpdateNodes/UpdateNodeModal";
import { useCreateDrawing, useGetDrawingToolData } from "../../api/drawingTool";
import {
  useGetSimulationDrawingById,
  useUpdateSimulateDrawing,
  usePutSimulationMappingDataToQueue,
} from "../../api/simulation";
import calculatorLoading from "../../assets/animations/CalculatorLoading.json";
import Lottie from "lottie-react";
import SimulationDrawingSaveModal from "./SimulationDrawingSaveModal";
import DrawingErrorsModal from "../mapping_tool/MappingErrorsModal";
import { MappingSourceType } from "../../config/enums/MappingSourceType.enum";
import DrawingTool from "../../components/drawing-tool/DrawingTool";
import ProcessGateFormModal from "./UpdateNodes/ProcessGate/components/ProcessGateFormModal";
import { getStringInsideBrackets } from "../../utils/utils";
import {
  useCreateProcessGate,
  useUpdateProcessGate,
} from "../../api/processGate/processGate";
import { successMessage } from "../../helpers/ErrorHandler";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const SimulateDrawingTool = () => {
  // Drawing States
  const [chartNodes, setChartNodes] = useState<Node[]>();
  const [chartEdges, setChartEdges] = useState<Edge[]>();
  const [productData, saveProductData] = useState<any>({});
  const [isOpenSavingModal, setIsOpenSavingModal] = useState(false);
  const [processGateNode, setProcessGateNode] = useState<NodeProps | null>();
  const [simulationDrawingId, setSimulationDrawingId] = useState<string | null>(
    null
  );
  const [isProcessGateCreated, setIsProcessGateCreated] =
    useState<boolean>(false);
  const [queryParam, setQueryParam] = useSearchParams();
  const navigate = useNavigate();

  const [calculateModalOpen, setCalculateModalOpen] = useState<boolean>(false);
  const [putDataToQueueErrors, setPutDataToQueueErrors] = useState<string[]>(
    []
  );

  const { productId, salesUnitId } = useParams();
  const query = useQuery();

  // Get Original Product Drawing
  const { data: originalDrawingToolData, isLoading: isDrawingToolLoading } =
    useGetDrawingToolData(MappingSourceType.product, salesUnitId);

  const { mutate: updateProcessGateAction } = useUpdateProcessGate();
  const { mutate: createProcessGateMutate } = useCreateProcessGate();

  // Get Simulated Product Drawing
  const {
    data: simulateDrawingToolData,
    isLoading: isSimulateDrawingToolLoading,
    refetch: reFetchDrawingTool,
  } = useGetSimulationDrawingById(simulationDrawingId);

  // Create Simulated Product Drawing
  const { mutate: createSimulateDrawing, isLoading: isLoadingCreateDrawing } =
    useCreateDrawing(MappingSourceType.simulation);

  // Update Simulated Product Drawing
  const { mutate: updateSimulateDrawing, isLoading: isLoadingUpdateDrawing } =
    useUpdateSimulateDrawing();

  const {
    isLoading: isLoadingPutDataToQueue,
    error: putDataToQueueError,
    mutate: putMappingToolDataToQueue,
  } = usePutSimulationMappingDataToQueue();

  const setDrawingNodesAndEdges = (drawingToolData: any) => {
    setChartNodes(drawingToolData?.chartNodes || []);
    setChartEdges(drawingToolData?.chartEdges || []);
  };

  useEffect(() => {
    const simulateDrawingId = query.get("simulateDrawingId");
    if (!simulateDrawingId) {
      setIsOpenSavingModal(true);
      setDrawingNodesAndEdges(originalDrawingToolData);
    }
    setSimulationDrawingId(simulateDrawingId);
  }, [originalDrawingToolData]);

  useEffect(() => {
    if (putDataToQueueError?.errors) {
      setPutDataToQueueErrors(putDataToQueueError.errors);
    }
  }, [putDataToQueueError]);

  const saveSimulateDrawing = useCallback(
    (chartEdges: any, chartNodes: any, title: string, note: string) => {
      const newDrawing = {
        ownerId: productId,
        sourceId: salesUnitId,
        drawingName: title,
        drawingNote: note,
        parentId: originalDrawingToolData?._id,
        chartEdges: chartEdges,
        chartNodes: chartNodes,
      };
      createSimulateDrawing(newDrawing, {
        onSuccess: (data) => {
          setSimulationDrawingId(data?._id);
          setQueryParam({
            simulateDrawingId: data?._id,
          });
          setDrawingNodesAndEdges(data);
          setIsOpenSavingModal(false);
        },
      });
    },
    [salesUnitId, productId, originalDrawingToolData] //updateDrawing, createDrawing
  );

  const updateSimulateDrawingChart = useCallback(
    (chartEdges: any, chartNodes: any) => {
      const updateDrawing = {
        drawingName: simulateDrawingToolData?.drawingName,
        drawingNote: simulateDrawingToolData?.drawingNote,
        chartEdges: chartEdges,
        chartNodes: chartNodes,
      };
      updateSimulateDrawing(
        { data: updateDrawing, simulationDrawingId },
        {
          onSuccess: () => {
            reFetchDrawingTool();
          },
        }
      );
    },
    [salesUnitId, productId, simulationDrawingId, simulateDrawingToolData]
  );

  const updateSimulationAndCalculate = useCallback(
    (
      chartEdges: any,
      chartNodes: any,
      drawingName: string,
      drawingNote: string
    ) => {
      const updateDrawing = {
        drawingName,
        drawingNote,
        chartEdges: chartEdges,
        chartNodes: chartNodes,
      };
      updateSimulateDrawing({ data: updateDrawing, simulationDrawingId });
      putMappingToolDataToQueue(simulationDrawingId, {
        onSuccess: () => {
          navigate(
            `/simulations/product-simulation?salesUnitId=${salesUnitId}&productId=${productId}`
          );
        },
      });
    },
    [salesUnitId, productId, simulationDrawingId]
  );

  const saveDrawing = useCallback(
    (chartEdges: any, chartNodes: any) => {
      if (simulationDrawingId) {
        updateSimulateDrawingChart(chartEdges, chartNodes);
        return;
      }
      setIsOpenSavingModal(true);
      setChartNodes(chartNodes);
      setChartEdges(chartEdges);
    },
    [salesUnitId, productId, simulationDrawingId]
  );

  const onClickApprove = useCallback(
    (title: string, note: string) => {
      if (calculateModalOpen) {
        updateSimulationAndCalculate(chartEdges, chartNodes, title, note);
        setCalculateModalOpen(false);
        return;
      }
      saveSimulateDrawing(chartEdges, chartNodes, title, note);
    },
    [calculateModalOpen, chartNodes, chartEdges]
  );

  const saveProcessGateData = (nodeItem: any, processData: any) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        reference: processData,
      },
    };
    const chartNodes = simulateDrawingToolData?.chartNodes || [];
    const chartEdges = simulateDrawingToolData?.chartEdges || [];
    const updatedChartNodes = [...chartNodes, updatedNode];
    saveDrawing(chartEdges, updatedChartNodes);
    successMessage("Update process gate successfully");
  };

  const toggleProcessGateModel = (
    node: NodeProps | null,
    isCreated: boolean
  ) => {
    setIsProcessGateCreated(isCreated);
    setProcessGateNode(node);
  };

  const updateProcessGate = (processGateName: string, newNode: NodeProps) => {
    const facilityId = newNode.data.facilityId;
    let processGate = {
      processGateId: processGateNode?.data.reference.processGateId,
      processGateName,
      facilityId,
      ownerId: productId,
      sourceId: salesUnitId,
      userCompletedStage: "RECIPE",
    };
    updateProcessGateAction(processGate, {
      onSuccess(data) {
        saveProcessGateData(newNode, {
          processGateId: data._id,
          ownerId: data?.ownerId,
          sourceId: data?.sourceId,
        });
        toggleProcessGateModel(null, false);
      },
    });
  };

  //create process gate data
  const createProcessGate = (processGateName: string, newNode: NodeProps) => {
    const facilityId = newNode.data.facilityId;
    let processGate = {
      processGateName,
      facilityId,
      ownerId: productId,
      sourceId: salesUnitId,
      userCompletedStage: "RECIPE",
      processing: [],
      internalTransportation: [],
      internalStorage: [],
      packaging: [],
    };
    createProcessGateMutate(processGate, {
      onSuccess(data) {
        saveProcessGateData(newNode, {
          processGateId: data._id,
          ownerId: data?.ownerId,
          sourceId: data?.sourceId,
        });
        toggleProcessGateModel(null, false);
      },
    });
  };

  const closeSaveModal = useCallback(() => {
    if (calculateModalOpen) {
      setCalculateModalOpen(false);
      return;
    }
    setIsOpenSavingModal(false);
    navigate(-1);
  }, [calculateModalOpen]);

  if (
    isDrawingToolLoading ||
    isLoadingCreateDrawing ||
    (isSimulateDrawingToolLoading && simulationDrawingId) ||
    isLoadingUpdateDrawing ||
    isLoadingPutDataToQueue
  ) {
    return (
      <Dimmer active>
        <div className="loading-calculation">
          <Lottie animationData={calculatorLoading} loop={true} />
        </div>
      </Dimmer>
    );
  }

  return (
    <>
      <DrawingTool
        sourceId={salesUnitId}
        ownerId={productId}
        chartNodesData={simulateDrawingToolData?.chartNodes || []}
        chartEdgesData={simulateDrawingToolData?.chartEdges || []}
        saveDrawing={saveDrawing}
        actionBar={null}
        updateNodeModal={() => (
          <UpdateNodeModal sourceId={salesUnitId} ownerId={productId} />
        )}
        actionButtons={(chartNodes: any, chartEdges: any) => {
          return [
            <Popup
              content={`${simulationDrawingId ? "Update" : "Save"} Mapping.`}
              size="mini"
              trigger={
                <Button
                  icon
                  color="teal"
                  className="drawing-save-button"
                  onClick={() => {
                    if (simulationDrawingId) {
                      saveDrawing(chartEdges, chartNodes);
                      return;
                    }
                    setIsOpenSavingModal(true);
                  }}
                >
                  {simulationDrawingId ? "Update" : "Save"}
                </Button>
              }
            />,

            <Popup
              content="Calculate Mapping Data."
              size="mini"
              trigger={
                <Button
                  icon
                  color="teal"
                  className="drawing-calculate-button"
                  onClick={() => {
                    setCalculateModalOpen(true);
                  }}
                >
                  Calculate
                </Button>
              }
            />,
          ];
        }}
        contextProps={{
          productData,
          toggleProcessGateModel,
          saveProductData,
        }}
      />

      {isOpenSavingModal || calculateModalOpen ? (
        <SimulationDrawingSaveModal
          title={simulateDrawingToolData?.drawingName}
          note={simulateDrawingToolData?.drawingNote}
          isCalculation={calculateModalOpen}
          closeModal={() => {
            closeSaveModal();
          }}
          onSave={(title: string, note: string) => {
            onClickApprove(title, note);
          }}
        />
      ) : null}
      <DrawingErrorsModal
        errors={putDataToQueueErrors}
        setPutDataToQueueErrors={setPutDataToQueueErrors}
      />
      {processGateNode ? (
        <ProcessGateFormModal
          setProcessGateNode={toggleProcessGateModel}
          newNode={processGateNode}
          edit={isProcessGateCreated}
          processGateName={getStringInsideBrackets(
            processGateNode?.data?.label
          )}
          updateProcessGate={updateProcessGate}
          createProcessGate={createProcessGate}
        />
      ) : null}
    </>
  );
};

export default SimulateDrawingTool;
