import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "./apiService";

export const useGetTransportList = (ingredientId: string) =>
  useQuery(["useGetTransportList", ingredientId], async () => {
    const response = await axiosApi.get(
      `/ingredient/transport/${ingredientId}`
    );
    return response.data;
  }, {
    enabled: !!ingredientId,
  })

export const usePostTransportData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/ingredient/transport`, data);
    return response.data;
  });
};

export const usePutTransportDetails = (transport?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (transport) => {
    const response = await axiosApi.put(
      `/ingredient/transport/${transport.ingredientTransportId}`,
      transport
    );
    return response.data;
  });
};

export const useDeleteTransportDetails = (ingredientTransportId?: any) => {
  return useMutation<string, ApiServiceErr, any>(
    async (ingredientTransportId) => {
      const response = await axiosApi.delete(
        `/ingredient/transport/${ingredientTransportId}`
      );
      return response.data;
    }
  );
};
