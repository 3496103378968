import { Buffer } from "buffer";
import { toPng } from "html-to-image";
import { getNodesBounds, getViewportForBounds, Node } from "reactflow";
import { v4 as uuidV4 } from "uuid";
import { useUploadMappingImageAction } from "../api/drawingTool";
import { MappingSourceType } from "../config/enums/MappingSourceType.enum";
export function useUploadMappingImage(
  chartNodes: Node[]
): [boolean, (salesUnitId: string, callBack: () => void) => void] {
  const imageWidth = 2048;
  const imageHeight = 1024;

  const { mutate, isLoading } = useUploadMappingImageAction(
    MappingSourceType.product
  );

  const uploadImage = (
    imageFile: Blob,
    fileName: string,
    salesUnitId: string | undefined,
    callBack: () => void
  ) => {
    const formData = new FormData();
    formData.append("file", imageFile, fileName);
    formData.append("salesUnitId", salesUnitId || "");
    mutate(formData, {
      onSuccess: () => {
        callBack();
      },
    });
  };

  const prepareImage = (salesUnitId: string, callBack: () => void) => {
    const nodesBounds = getNodesBounds(chartNodes);

    const viewport = getViewportForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0,
      5
    );

    const element = document.querySelector(
      ".react-flow__viewport"
    ) as HTMLElement;

    toPng(element, {
      backgroundColor: "#ffffff",
      width: imageWidth,
      height: imageHeight,
      style: {
        width: `${imageWidth}`,
        height: `${imageHeight}`,
        transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
      },
    }).then((dataUrl) => {
      const fileName = `${uuidV4()}.png`;
      const file = base64ToFileLocal(dataUrl, fileName);
      uploadImage(file, fileName, salesUnitId, callBack);
    });
  };

  return [isLoading, prepareImage];
}

export function base64ToFileLocal(base64String: string, fileName: string) {
  // Extract Base64 data (if it contains metadata like "data:image/png;base64,")
  const base64Data = base64String.replace(/^data:.+;base64,/, "");

  // Decode Base64 string to binary data using Buffer
  const binaryData = Buffer.from(base64Data, "base64");

  // Create a Blob-like object (not natively available in Node.js)
  const mimeType =
    base64String.match(/^data:(.+);base64,/)?.[1] || "application/octet-stream";
  const blob = new Blob([binaryData], { type: mimeType });
  return blob;
}

export default useUploadMappingImage;
