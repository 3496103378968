import { useEffect, useMemo, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";

import { TitleBar } from "../../components/titleBar/Titlebar";
import DrawingSimulationData from "./component/simulationTable/SimulationTable";
import {
  useGetProductPayload,
  useGetSimulationDrawingData,
} from "../../api/productSimulation";
import NetZeroDetails from "./component/netZeroDetailsView/NetZeroDetails";

import "./ProductSimulation.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProductSimulationNetZero = () => {
  const query = useQuery();
  const currentSalesUnitId = query.get("salesUnitId");
  const currentProductId = query.get("productId");
  const currentDrawingId = query.get("simulationDrawingId");

  const [salesUnitId, setSalesUnitId] = useState("");
  const [productId, setProductId] = useState("");
  const [drawingData, setDrawingData] = useState<DrawingSimulationData[]>([]);
  const [drawingId, setDrawingId] = useState("");

  // History
  const navigate = useNavigate();

  const { data: productPayload, isLoading: isProductPayloadLoading } =
    useGetProductPayload({
      salesUnitId,
    });

  const { data: currentSimulationData, isLoading: isSimulationLoading } =
    useGetSimulationDrawingData({
      drawingId,
    });

  const initiatedData = () => {
    if (currentSalesUnitId && currentProductId && currentDrawingId) {
      setSalesUnitId(currentSalesUnitId);
      setProductId(currentProductId);
      setDrawingId(currentDrawingId);
    }
    if (currentSimulationData) {
      setDrawingData(currentSimulationData || []);
    }
  };

  useEffect(() => {
    initiatedData();
  }, [currentSalesUnitId, currentProductId, currentSimulationData]);

  const navigateToSimulateDrawing = () => {
    return navigate(
      `/simulations/product-simulation?salesUnitId=${salesUnitId}&productId=${productId}`
    );
  };

  const isLoading = Boolean(isProductPayloadLoading);

  const drawingSimulationData = useMemo(() => {
    return (currentSimulationData?.simulationProductSummary || []).sort(
      (a: any, b: any) => b.totalChanges - a.totalChanges
    );
  }, [currentSimulationData]);

  if (isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <div>
      <TitleBar
        enableTitle={true}
        titleName={
          (productPayload &&
            `${productPayload?.productName} - ${productPayload?.salesUnitName}`) ||
          ""
        }
        customButtonData={[
          {
            text: "Back to simulations",
            skip: false,
          },
        ]}
        enableCustomButton
        buttonSelectView={false}
        customPageButtonContainer="product-title-container"
        customPageTitle="product-simulation-title"
        customButtonMain="title-button"
        customButtonAction={(index) => {
          navigateToSimulateDrawing();
        }}
      />
      <NetZeroDetails
        currentSimulationData={currentSimulationData}
        drawingSimulationData={drawingSimulationData}
      />
      <DrawingSimulationData
        currentSimulationData={currentSimulationData}
        simulationDrawingData={drawingSimulationData}
        isGetProductSimulationsLoading={isSimulationLoading}
      />
    </div>
  );
};

export default ProductSimulationNetZero;
