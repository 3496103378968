import React from "react";
import Icon from "@mdi/react";
import { mdiFileSearchOutline, mdiPencil, mdiDeleteOutline } from "@mdi/js";
import {
  Grid,
  PaginationProps,
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
  Table,
} from "semantic-ui-react";
import "./simulationTable.scss";
import { CommonTable } from "../../../components";
import { SimulationPackagingTable } from "../../../config/tableColConstant";
import PaginationView from "../../../components/pagination/Pagination";

interface SimulationTableProps {
  data: SimulationData[];
  onEdit: (data: SimulationData, isView?: boolean) => void;
  onDelete: (data: SimulationData) => void;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  pageNumber: number;
  pageSize: number;
  totalSimulations: number;
  isGetProductSimulationsLoading: boolean;
}

const LoadingComponent = () => (
  <Placeholder fluid>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
  </Placeholder>
);

const SimulationTable: React.FC<SimulationTableProps> = ({
  data,
  onEdit,
  onDelete,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
  totalSimulations,
  isGetProductSimulationsLoading,
}) => {
  const handlePagination = (currentPage: number) => {
    setPageNumber(currentPage);
  };

  const getCurrentImpact = (
    originalImpact: number,
    simulatedImpact: number
  ) => {
    return Math.fround(
      ((simulatedImpact - originalImpact) / originalImpact) * 100
    ).toFixed(4);
  };

  return (
    <>
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={SimulationPackagingTable}>
            {isGetProductSimulationsLoading && (
              <Table.Row>
                <Table.Cell colSpan="7">
                  <LoadingComponent />
                </Table.Cell>
              </Table.Row>
            )}
            {!isGetProductSimulationsLoading &&
              data.map((item: SimulationData, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{item.category}</Table.Cell>
                  <Table.Cell>{item.simulationName}</Table.Cell>
                  <Table.Cell>
                    {item.originalImpact?.toLocaleString()}
                  </Table.Cell>
                  <Table.Cell>
                    {item.simulatedImpact?.toLocaleString()}
                  </Table.Cell>
                  <Table.Cell>
                    {getCurrentImpact(
                      item.originalImpact,
                      item.simulatedImpact
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Grid>
                      <Grid.Column computer={3} tablet={3} mobile={3}>
                        <div
                          onClick={() => {
                            onEdit(item, true);
                          }}
                        >
                          <Icon
                            className="table-icon"
                            color="var(--tableEditIcon)"
                            path={mdiFileSearchOutline}
                            size={1.2}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={3} mobile={3}>
                        <div
                          onClick={() => {
                            onEdit(item);
                          }}
                        >
                          <Icon
                            className="table-icon"
                            color="var(--tableEditIcon)"
                            path={mdiPencil}
                            size={1.2}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={3} mobile={3}>
                        <div
                          onClick={() => {
                            onDelete(item);
                          }}
                        >
                          <Icon
                            className="table-icon"
                            color="var(--tableEditIcon)"
                            path={mdiDeleteOutline}
                            size={1.2}
                          />
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
              ))}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <PaginationView
        changePageSize={setPageSize}
        pageSize={pageSize}
        totalPages={Math.ceil(totalSimulations / pageSize)}
        currentPageNo={pageNumber}
        currentSelectPage={handlePagination}
      />
    </>
  );
};

export default SimulationTable;
