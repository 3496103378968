import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dimmer,
  Grid,
  GridRow,
  Table,
  Accordion,
  AccordionContent,
  Loader,
  AccordionTitle,
} from "semantic-ui-react";
import { useForm, useWatch } from "react-hook-form";

import {
  InputText,
  CustomButton,
  CommonTable,
  DropDown,
  TitleView,
  AccordionTitleView,
} from "../../../components";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { CultivationPackagingView } from "../../../config/constants";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import "./packaging.scss";
import { valueGraterThanZero } from "../../../utils/utils";
import _ from "lodash";
import {
  useDeleteIngredientPackaging,
  usePostCreateIngredientPackaging,
  usePostUpdateIngredientPackagingUnit,
  usePutUpdateIngredientPackaging,
} from "../../../api/ingredients";

type PackageData = {
  contentWeight: number;
  materialCost: number;
  materialWeight: number;
  packageMaterial: number;
  packageName: string;
  totalWeightWithPackaging: number;
  unitSize: string;
};

type SelectData = {
  contentWeight: number;
  createdAt: string;
  materialCost: number;
  materialWeight: number;
  packageMaterial: number;
  packageName: string;
  totalWeightWithPackaging: number;
  updatedAt: string;
  __v: number;
  _id: string;
};

const Packaging = ({
  packagingData,
  packagingType,
  packagingTypeData,
  totalWeightOfItem = 0,
  unitSizes,
  ingredientId,
  supplierId,
  refetchPackagingData,
  packagingId,
  dataView,
}: any) => {
  const [enableSizeEditStatus, setEnableSizeEditStatus] =
    useState<boolean>(false);
  const [defaultType, setDefaultType] = useState<number>(0);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [selectData, setSelectData] = useState<SelectData | null>(null);
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const { mutateAsync: mutatePostIngredient, isLoading } =
    usePostCreateIngredientPackaging();

  const { mutateAsync: mutatePutIngredient, isLoading: isLoadingUpdate } =
    usePutUpdateIngredientPackaging();

  const { mutateAsync: mutatePostPackageUnit, isLoading: isLoadingUnitValues } =
    usePostUpdateIngredientPackagingUnit();

  const { mutateAsync: mutateDeletePackageUnit, isLoading: isDeletePackaging } =
    useDeleteIngredientPackaging();

  const {
    register,
    formState: { errors: errorsUnit },
    handleSubmit: handleSubmitSingleUnit,
    control,
    reset,
    setValue,
    setError,
  } = useForm();

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };

  useEffect(() => {
    if (getValuesByPackagingType()?.name) {
      setValue(`${getValuesByPackagingType()?.name}`, unitSizes);
    }
  }, [unitSizes, selectData]);

  const setUnitSizeUpdate = (data: any) => {
    setValue("packageName", data.packageName);
    setValue("materialWeight", data.materialWeight);
    setValue("materialCost", data.materialCost);
    setDefaultType(data?.packageMaterial || 1);
    setSelectData(data);
  };

  //delete package data
  const deletePackageData = (data: any) => {
    let updatePackingData = {
      subPackagingId: selectData?._id,
      packagingType: packagingType,
      packagingId: packagingId,
      ingredientId: ingredientId,
    };
    mutateDeletePackageUnit(updatePackingData, {
      onSuccess() {
        successMessage("Packaging data removed successfully");
        refetchPackagingData();
      },
    });
  };

  const commentResetValue = (statusMessage: string) => {
    reset();
    setDefaultType(1);
    setEnableSizeEditStatus(false);
    successMessage(statusMessage);
  };

  //api call add to package data
  const addPackageData = (packageData: any) => {
    mutatePostIngredient(packageData, {
      onSuccess: (data) => {
        commentResetValue("Package created successfully");
        setSelectData(null);
        setDefaultType(0);
        refetchPackagingData();
      },
    });
  };

  //api call edit to package data
  const editPackageData = (packageData: any) => {
    mutatePutIngredient(packageData, {
      onSuccess: () => {
        commentResetValue("Package updated successfully");
        setSelectData(null);
        setDefaultType(0);
        refetchPackagingData();
      },
    });
  };

  const handlePackageValidation = () => {
    const packagingName = getValuesByPackagingType()?.name || "";
    const packagingNameDetails = getValuesByPackagingType()?.title || "";
    if (unitSizes === 0) {
      setError(`${packagingName}`, {
        type: "required",
        message: `${packagingNameDetails} is required`,
      });
      errorView(`${packagingNameDetails} is required`);
      return false;
    }
    return true;
  };

  //handle package data view
  const packageDataInsert = (data: PackageData, status: string) => {
    data.packageMaterial = defaultType;
    data.contentWeight = 0;
    data.totalWeightWithPackaging = 0;
    data.materialWeight = Number(data.materialWeight);
    data.materialCost = Number(data.materialCost);
    let packageData = {
      data,
      packagingType,
      ingredientId: ingredientId,
      supplierId: supplierId,
      packagingId: selectData?._id || packagingId,
    };
    if (handlePackageValidation()) {
      if (status === "add") {
        addPackageData(packageData);
      } else {
        editPackageData(packageData);
      }
    }
  };

  const getValuesByPackagingType = () => {
    switch (packagingType) {
      case "single-unit":
        return {
          title: "Base unit packaging",
          label: "Unit size (kg)",
          type: "Base unit",
          name: "unitSize",
        };
      case "sales-unit":
        return {
          title: "Pack or inner pack packaging",
          label: "Number of Base Units",
          type: "Pack",
          name: "numberOfBaseUnits",
        };
      case "bulk-unit":
        return {
          title: "Case packaging",
          label: "Number of Packs",
          type: "Case",
          name: "numberOfPacks",
        };
      case "transport-unit":
        return {
          title: "Pallet packaging",
          label: "Number of Cases",
          type: "Pallet",
          name: "numberOfCases",
        };
    }
  };

  const unitSizeChange = useWatch({
    control,
    name: `${getValuesByPackagingType()?.name}`,
    defaultValue: 0,
  });

  const checkValueISChange = useCallback(() => {
    return (
      Number(unitSizeChange) !== 0 &&
      Number(unitSizeChange) !== Number(unitSizes)
    );
  }, [unitSizeChange]);

  const onChangeUnitData = () => {
    let unitType: any = getValuesByPackagingType()?.name;
    let updatePackingData = {
      data: {
        [unitType]: unitSizeChange,
      },
      ingredientId: ingredientId,
      supplierId: supplierId,
      packagingId: packagingId,
    };

    mutatePostPackageUnit(updatePackingData, {
      onSuccess(data) {
        refetchPackagingData();
      },
    });
  };

  const loadTableData = () => {
    return packagingData.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.packageName ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{packagingTypeData?.[data?.packageMaterial || 0]?.text || ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.materialWeight ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.materialCost ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            {!dataView ? (
              <Grid>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  <MdModeEditOutline
                    cursor={"pointer"}
                    size={24}
                    color="var(--tableEditIcon)"
                    onClick={() => {
                      setUnitSizeUpdate(data);
                      setEnableSizeEditStatus(true);
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  <MdDeleteOutline
                    cursor={"pointer"}
                    size={24}
                    color="var(--tableEditIcon)"
                    onClick={() => {
                      setSelectData(data);
                      setVisibleDeleteModal(true);
                    }}
                  />
                </Grid.Column>
              </Grid>
            ) : null}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  if (
    isLoading ||
    isLoadingUpdate ||
    isDeletePackaging ||
    isLoadingUnitValues
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={getValuesByPackagingType()?.title}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <>
          <div className="packagingMainView">
            {!dataView ? (
              <Grid>
                <GridRow>
                  <Grid.Column
                    floated="left"
                    computer={5}
                    tablet={8}
                    mobile={16}
                  >
                    <InputNumber
                      id={getValuesByPackagingType()?.name}
                      transform={{
                        input: (value: number) =>
                          isNaN(value) || value === 0 ? "" : value,
                        output: (e: number) => {
                          return e;
                        },
                      }}
                      control={control}
                      errors={errorsUnit[`${getValuesByPackagingType()?.name}`]}
                      labelName={getValuesByPackagingType()?.label}
                      type="number"
                      placeholder={unitSizes > 0 ? unitSizes : "0"}
                      name={getValuesByPackagingType()?.name}
                      otherValidation={{
                        validate: {
                          valueCheck: (v: number) =>
                            valueGraterThanZero(v) ||
                            `${
                              getValuesByPackagingType()?.label
                            } must be grater than 0`,
                        },
                      }}
                    />
                  </Grid.Column>
                  {checkValueISChange() ? (
                    <Grid.Column
                      floated="left"
                      computer={5}
                      tablet={8}
                      mobile={16}
                    >
                      <CustomButton
                        theme="green"
                        customColumnStyle={"addUnitSize"}
                        title={unitSizes > 0 ? "Update" : "Save"}
                        onClick={() => {
                          onChangeUnitData();
                        }}
                      />
                    </Grid.Column>
                  ) : null}

                  <Grid.Column
                    floated="right"
                    computer={5}
                    tablet={8}
                    mobile={16}
                  >
                    <label className={`textLabel`}>{`Total weight of ${
                      getValuesByPackagingType()?.type
                    }`}</label>
                    <br />
                    <label className={`textLabel`}>{totalWeightOfItem}</label>
                  </Grid.Column>
                </GridRow>
                <GridRow className="contentMargin">
                  <Grid.Column computer={4} tablet={8} mobile={16}>
                    <InputText
                      register={register}
                      errors={errorsUnit.packageName}
                      labelName={"Packaging Name"}
                      placeholder="Packaging Name"
                      name="packageName"
                      required={true}
                      errorMessage="Packaging name is required"
                    />
                  </Grid.Column>
                  <Grid.Column computer={4} tablet={8} mobile={16}>
                    <DropDown
                      labelName={"Packaging Material"}
                      placeholder="Packaging Material"
                      currentData={packagingTypeData}
                      defaultValue={
                        packagingTypeData?.[defaultType]?.value || null
                      }
                      customGridColumn={"customGridColomnType"}
                      handleChangeState={(e: any, { value }: any) => {
                        let getIndex = packagingTypeData?.findIndex(
                          (e: any) => e?.value === value
                        );
                        setDefaultType(getIndex);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column computer={3} tablet={8} mobile={16}>
                    <InputNumber
                      control={control}
                      errors={errorsUnit.materialWeight}
                      labelName={"Material Weight(kg)"}
                      placeholder="0.00"
                      name="materialWeight"
                      required={true}
                      errorMessage="Material Weight is required"
                      otherValidation={{
                        validate: {
                          valueCheck: (v: number) =>
                            valueGraterThanZero(v) ||
                            "Material weight must be grater than 0",
                        },
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column computer={3} tablet={8} mobile={16}>
                    <InputNumber
                      control={control}
                      errors={errorsUnit.materialCost}
                      labelName={"Material Cost per unit"}
                      placeholder="0.00"
                      name="materialCost"
                      required={false}
                      errorMessage="Material Cost is required"
                    />
                  </Grid.Column>
                  <Grid.Column computer={2} tablet={8} mobile={16}>
                    {enableSizeEditStatus ? (
                      <CustomButton
                        theme="green"
                        customColumnStyle={"addUnitSize"}
                        title="Update"
                        onClick={handleSubmitSingleUnit((data) => {
                          packageDataInsert(data as PackageData, "edit");
                        })}
                      />
                    ) : (
                      <CustomButton
                        theme="green"
                        customColumnStyle={"addUnitSize"}
                        icon="plus"
                        onClick={handleSubmitSingleUnit((data) => {
                          packageDataInsert(data as PackageData, "add");
                        })}
                      />
                    )}
                  </Grid.Column>
                </GridRow>
              </Grid>
            ) : null}

            {packagingData?.length > 0 ? (
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                className="packagingDetailsView"
              >
                <CommonTable tableHeaderData={CultivationPackagingView}>
                  {loadTableData()}
                </CommonTable>
              </Grid.Column>
            ) : (
              <Accordion>
                <AccordionContent>
                  <p>{getValuesByPackagingType()?.title} is not available</p>
                </AccordionContent>
              </Accordion>
            )}
          </div>
          <ConfirmModal
            viewModal={visibleDeleteModal}
            closeModal={() => setVisibleDeleteModal(false)}
            cancel={() => {
              setVisibleDeleteModal(false);
              setSelectData(null);
            }}
            approve={() => {
              setVisibleDeleteModal(false);
              deletePackageData(selectData);
            }}
            title="Delete package data"
            subTitle="Are you sure you want to delete packaging data?"
          />
        </>
      </AccordionContent>
    </Accordion>
  );
};

export default Packaging;
