const ProductTableCol = [
  {
    name: "Name",
    col: 4,
  },
  {
    name: "Sales Unit",
    col: 3,
    information: "Click sales unit to see product mapping!",
  },
  {
    name: "Climate Impact",
    col: 3,
  },
  {
    name: "Register Number",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const CompanyTableCol = [
  {
    name: "Name ",
    col: 3,
  },
  {
    name: "Email",
    col: 4,
  },
  {
    name: "Status",
    col: 4,
  },
  {
    name: "",
    col: 5,
  },
];
const UserTableCol = [
  {
    name: "Pic",
    col: 1,
  },
  {
    name: "First Name",
    col: 2,
  },
  {
    name: "Last Name",
    col: 2,
  },
  {
    name: "Email",
    col: 2,
  },
  {
    name: "Status",
    col: 1,
  },
  {
    name: "Role",
    col: 1,
  },
  {
    name: "",
    col: 3,
  },
];

const IngredientTableColView = [
  {
    name: "Ingredient Name",
    col: 4,
  },
  {
    name: "Supplier Name",
    col: 4,
  },
  {
    name: "Climate Impact",
    col: 3,
  },
  {
    name: "",
    col: 4,
  },
];

const ClientTableCol = [
  {
    name: "Client Name",
    col: 7,
  },
  {
    name: "Product Name",
    col: 7,
  },
  {
    name: "",
    col: 3,
  },
];
const ClientListTableCol = [
  {
    name: "Client Name",
    col: 6,
  },
  {
    name: "Email",
    col: 6,
  },
  {
    name: "Phone Number",
    col: 4,
  },
];

const TransportTableCol = [
  {
    name: "Product Name",
    col: 4,
  },
  {
    name: "Client Name",
    col: 4,
  },
  {
    name: "Process Type",
    col: 2,
  },
  {
    name: "Climate Impact",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const PesticideTableHeader = [
  {
    name: "Pesticide Name",
    col: 6,
  },
  {
    name: "kg/ha",
    col: 6,
  },
  {
    name: "",
    col: 4,
  },
];

const IrrigationTableHeader = [
  {
    name: "Irrigation Method",
    col: 3,
  },
  {
    name: "Power Source",
    col: 3,
  },
  {
    name: "Water Amount (m3)",
    col: 3,
  },
  {
    name: "Applied Portion (%)",
    col: 3,
  },
  {
    name: "",
    col: 4,
  },
];

const LandUsageTableHeader = [
  {
    name: "Year of Change",
    col: 3,
  },
  {
    name: "Allocation (%)",
    col: 3,
  },
  {
    name: "Land usage from",
    col: 3,
  },
  {
    name: "Land usage to",
    col: 3,
  },
  {
    name: "",
    col: 4,
  },
];

const EnergySourceData = [
  {
    name: "Energy Source",
    col: 4,
  },
  {
    name: "Energy Used ",
    col: 4,
  },
  {
    name: "Energy Unit type",
    col: 4,
  },
  {
    name: "",
    col: 4,
  },
];

const FertilizerTableHeader = [
  {
    name: "Fertilizer Name",
    col: 6,
  },
  {
    name: "kg/ha",
    col: 6,
  },
  {
    name: "",
    col: 4,
  },
];

const ProcessHeatTableHeader = [
  {
    name: "Heat Source",
    col: 7,
  },
  {
    name: "Heating Amount (MJ/kg)",
    col: 6,
  },
  {
    name: "",
    col: 4,
  },
];

const CommonCol = [
  {
    name: "Name",
    col: 8,
  },
  {
    name: "Content",
    col: 5,
  },
  {
    name: "Unit",
    col: 3,
  },
];

const SipCol = [
  {
    name: "Chemical Name",
    col: 4,
  },
  {
    name: "Concentration",
    col: 4,
  },
  {
    name: "Consumption",
    col: 4,
  },
  {
    name: "",
    col: 4,
  },
];

const PackagingColDetails = [
  {
    name: "Product Name",
    col: 3,
  },
  {
    name: "Unit Size ",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 4,
  },
  {
    name: "EAN Barcode",
    col: 3,
  },
  {
    name: "",
    col: 4,
  },
];

const Processing = [
  {
    name: "Process Name",
    col: 1,
  },
  {
    name: "Processing Type",
    col: 1,
  },
  {
    name: "input",
    col: 1,
  },
  {
    name: "output",
    col: 1,
  },
  {
    name: "capacity",
    col: 1,
  },
];

const Packaging = [
  {
    name: "Meaterial Weight",
    col: 2,
  },
  {
    name: "Package Material",
    col: 2,
  },
  {
    name: "Package Name",
    col: 2,
  },
];

const Storage = [
  {
    name: "Warehouse Name",
    col: 2,
  },
  {
    name: "Warehouse Size",
    col: 2,
  },
  {
    name: "Temperature",
    col: 2,
  },
  {
    name: "Electricity",
    col: 2,
  },
];

const Energy = [
  {
    name: "Energy Type",
    col: 2,
  },
  {
    name: "kWh",
    col: 2,
  },
  {
    name: "Percentage",
    col: 2,
  },
];

const Transportation = [
  {
    name: "Transport Type",
    col: 2,
  },
  {
    name: "Average Distance",
    col: 2,
  },
];

const ProductUnitTable = [
  {
    name: "Unit Name",
    col: 3,
  },
  {
    name: "Unit Size",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 3,
  },
  {
    name: "EAN Number",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const ClientTransportMethodsTable = [
  {
    name: "Transport Type",
    col: 3,
  },
  {
    name: "From",
    col: 3,
  },
  {
    name: "To",
    col: 3,
  },
  {
    name: "Distance (Km)",
    col: 2,
  },
  {
    name: "Weight (Kg)",
    col: 2,
  },
  {
    name: "",
    col: 2,
  },
];

const SupplierIngredientTable = [
  {
    name: "Ingredient Name",
    col: 4,
  },
  {
    name: "Unit Size",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 3,
  },
  {
    name: "EAN Number",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const ProductSummaryTableCol = [
  {
    name: "Indicator",
    col: 6,
  },
  {
    name: "Measure",
    col: 6,
  },
  {
    name: "Unit per kg",
    col: 3,
  },
  {
    name: "",
    col: 1,
  },
];

const ProductSummaryHistoryTableCol = [
  {
    name: "Date",
    col: 6,
  },
  {
    name: "Climate Impact",
    col: 4,
  },
  {
    name: "",
    col: 3,
  },
];

const CultivationPackagingView = [
  {
    name: "Packaging Name",
    col: 4,
  },
  {
    name: "Packaging Material",
    col: 4,
  },
  {
    name: "Material Weight(kg/unit)",
    col: 3,
  },
  {
    name: "Material Cost",
    col: 3,
  },
  {
    name: "",
    col: 2,
  },
];

const TransportDetailsView = [
  {
    name: "From",
    col: 3,
  },
  {
    name: "To",
    col: 3,
  },
  {
    name: "Transportation type",
    col: 3,
  },
  {
    name: "Distance",
    col: 3,
  },
  {
    name: "",
    col: 4,
  },
];

const CultivationPackagingMethodView = [
  {
    name: "Packaging Step",
    col: 3,
  },
  {
    name: "Packaging Machinary",
    col: 3,
  },
  {
    name: "Packaging Level",
    col: 2,
  },
  {
    name: "Energy",
    col: 1,
  },
  {
    name: "Capacity(pk/hr)",
    col: 1,
  },
  {
    name: "Waste(pk/hr)",
    col: 1,
  },
  {
    name: "",
    col: 2,
  },
];

const FacilityTableCol = [
  {
    name: "Facility Name",
    col: 3,
  },
  {
    name: "Facility Type",
    col: 3,
  },
  {
    name: "",
    col: 2,
  },
];

const FacilityEnergyTable = [
  {
    name: "Year",
    col: 3,
  },
  {
    name: "Source",
    col: 3,
  },
  {
    name: "kWh consumed",
    col: 3,
  },
  {
    name: "Distribution",
    col: 2,
  },
  {
    name: "Total cost",
    col: 2,
  },
  {
    name: "",
    col: 3,
  },
];

const FacilityHeatTable = [
  {
    name: "Year",
    col: 4,
  },
  {
    name: "Source",
    col: 4,
  },
  {
    name: "Volume",
    col: 3,
  },
  {
    name: "Total",
    col: 3,
  },
  {
    name: "",
    col: 2,
  },
];

const FacilityWaterTable = [
  {
    name: "Year",
    col: 3,
  },
  {
    name: "Source",
    col: 3,
  },
  {
    name: "Volume",
    col: 3,
  },
  {
    name: "Total",
    col: 2,
  },
  {
    name: "",
    col: 2,
  },
];

const ChemicalDataCol = [
  {
    name: "Chemical name",
    col: 4,
  },
  {
    name: "Chemical Amount (kg/kg input)",
    col: 4,
  },
  {
    name: "",
    col: 8,
  },
];

const NoteDocumentTable = [
  {
    name: "",
    col: 1,
  },
  {
    name: "Document Name",
    col: 7,
  },
  {
    name: "Upload on",
    col: 4,
  },
  {
    name: "",
    col: 4,
  },
];

const TransportTypeCol = [
  {
    name: "Transportation Type",
    col: 6,
  },
  {
    name: "Distance",
    col: 6,
  },
  {
    name: "",
    col: 4,
  },
];

const TransportSimulateCol = [
  {
    name: "Chemical name",
    col: 4,
  },
  {
    name: "Chemical Amount (kg/kg input)",
    col: 4,
  },
  {
    name: "Transportation Type",
    col: 4,
  },
  {
    name: "Distance",
    col: 4,
  },
];

const WasteManagementDataCol = [
  {
    name: "Management Name",
    col: 4,
  },
  {
    name: "Management Type",
    col: 4,
  },
  {
    name: "Distribution (kg)",
    col: 4,
  },
  {
    name: "",
    col: 4,
  },
];

const NotificationCol = [
  {
    name: "Ingredient Name",
    col: 4,
  },
  {
    name: "Company Name",
    col: 4,
  },
  {
    name: "Product Name",
    col: 4,
  },
  {
    name: "",
    col: 2,
  },
  {
    name: "",
    col: 2,
  },
];

const SimulationDataTable = [
  {
    name: "",
    col: 1,
  },
  {
    name: "Simulation Name",
    col: 3,
  },
  {
    name: "Original Impact",
    col: 3,
  },
  {
    name: "Simulated Impact",
    col: 3,
  },
  {
    name: "Impact Change %",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const SimulationPackagingTable = [
  {
    name: "Category",
    col: 2,
  },
  {
    name: "Name",
    col: 2,
  },
  {
    name: "Original Impact (kgCO2 eq)",
    col: 3,
  },
  {
    name: "Simulated Impact (kgCO2 eq)",
    col: 3,
  },
  {
    name: "Impact Change %",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const SupplierListCol = [
  {
    name: "Supplier Name",
  },
  {
    name: "Contact Name",
  },
  {
    name: "Supplier Email",
  },
  {
    name: "Contact Number",
  },
  {
    name: "",
  },
];

export {
  ChemicalDataCol,
  ClientListTableCol,
  ClientTableCol,
  ClientTransportMethodsTable,
  CommonCol,
  CompanyTableCol,
  CultivationPackagingMethodView,
  CultivationPackagingView,
  Energy,
  EnergySourceData,
  FacilityEnergyTable,
  FacilityHeatTable,
  FacilityTableCol,
  FacilityWaterTable,
  FertilizerTableHeader,
  IngredientTableColView,
  IrrigationTableHeader,
  LandUsageTableHeader,
  NoteDocumentTable,
  NotificationCol,
  Packaging,
  PackagingColDetails,
  PesticideTableHeader,
  ProcessHeatTableHeader,
  Processing,
  ProductSummaryHistoryTableCol,
  ProductSummaryTableCol,
  ProductTableCol,
  ProductUnitTable,
  SimulationDataTable,
  SimulationPackagingTable,
  SipCol,
  Storage,
  SupplierIngredientTable,
  SupplierListCol,
  Transportation,
  TransportDetailsView,
  TransportSimulateCol,
  TransportTableCol,
  TransportTypeCol,
  UserTableCol,
  WasteManagementDataCol,
};
