import ClientIcon from "../assets/menuIcon/clientIcon";
import DashboardIcon from "../assets/menuIcon/dashboardIcon";
import FacilityIcon from "../assets/menuIcon/facilityIcon";
import IngredientIcon from "../assets/menuIcon/ingredientIcon";
import IngredientMapping from "../assets/menuIcon/ingredientMapping";
import MappingIcon from "../assets/menuIcon/mappingIcon";
import MappingRequest from "../assets/menuIcon/mappingRequest";
import ProductIcon from "../assets/menuIcon/productIcon";
import ProductSummary from "../assets/menuIcon/productSummary";
import SupplierIcon from "../assets/menuIcon/supplierIcon";

export const menuBarIconMap: { [key: string]: React.FC } = {
  dashboard: DashboardIcon,
  product: ProductIcon,
  ingredient: IngredientIcon,
  supplier: SupplierIcon,
  facility: FacilityIcon,
  mapping: MappingIcon,
  drawingIngredient: IngredientMapping,
  productSummary: ProductSummary,
  clientList: ClientIcon,
  drawingRequests: MappingRequest,
};
