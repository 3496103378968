import { Grid } from "semantic-ui-react";
import {
  InputText,
  DropDown,
  SearchValue,
  Switch,
  CustomButton,
} from "../../../components";
import {
  DATA_TYPES,
  FARMING_TYPES,
  FIELD_TYPES,
  INGREDIENT_TYPE,
  UNIT_TYPES,
} from "../../../config/constants";
import { isEmpty } from "lodash";
import { errorView } from "../../../helpers/ErrorHandler";
import { IngredientDataType } from "../../../config/enums/IngredientType.enum";
import { useNavigate } from "react-router-dom";
import { MappingSourceType } from "../../../config/enums/MappingSourceType.enum";
import { useMemo } from "react";

interface SupplerIngredientDataViewProps {
  dataView?: boolean;
  register: any;
  ingredientType: string;
  setIngredientType: (type: string) => void;
  dataType: string;
  errors: Record<string, any>;
  country: number;
  countries: any;
  setCountry: (country: number) => void;
  isCountriesDataLoading: boolean;
  defaultType: string;
  setDefaultType: (type: string) => void;
  setDataType: (type: string) => void;
  produceType: string;
  produceTypes: any;
  setProduceType: (type: string) => void;
  isProduceTypesLoading: boolean;
  farmingType: string;
  fieldType: string;
  setFarmingType: (type: string) => void;
  setFieldType: (type: string) => void;
  disableEdit?: boolean;
  errorsNewSupplier: Record<string, any>;
  ingredientSupplierList: any;
  supplierData: any;
  handleSearchValidation: (value: string) => void;
  getSearchValue: (data?: any) => any;
  checkRawOrProcess: {
    processIngredient: boolean;
    produceIngredient: boolean;
  };
  specifyIngredient: any;
  specifyIngredientValue: string;
  setSpecifyIngredientValue: (value: string) => void;
  supplierAsUser: boolean;
  disable?: boolean;
  setViewEnvironmentalData: (value: boolean) => void;
  viewEnvironmentalData: boolean;
}

const SupplerIngredientDataView = ({
  dataView = false,
  register,
  ingredientType,
  setIngredientType,
  dataType,
  errors,
  country,
  countries,
  setCountry,
  isCountriesDataLoading,
  defaultType,
  setDefaultType,
  setDataType,
  produceType,
  produceTypes,
  setProduceType,
  isProduceTypesLoading,
  farmingType,
  fieldType,
  setFarmingType,
  setFieldType,
  disableEdit,
  errorsNewSupplier,
  ingredientSupplierList,
  supplierData,
  handleSearchValidation,
  getSearchValue,
  checkRawOrProcess,
  specifyIngredient,
  specifyIngredientValue,
  setSpecifyIngredientValue,
  supplierAsUser,
  disable,
  setViewEnvironmentalData,
  viewEnvironmentalData,
}: SupplerIngredientDataViewProps) => {
  let processIngredient = checkRawOrProcess.processIngredient;
  let produceIngredient = checkRawOrProcess.produceIngredient;

  const ingredientId = supplierData?.supplier_data?.ingredient_id;
  const supplierId = supplierData?.supplier_data?.supplier_id;

  const navigation = useNavigate();

  let previewData = useMemo(() => {
    return processIngredient || produceIngredient ? true : false;
  }, [ingredientType, dataType]);

  const checkSupplierAsUser = (type: string) => {
    switch (type) {
      case "SUPPLIER":
        return Boolean(supplierAsUser)
          ? setDataType(type)
          : errorView("Supplier is not an user in the system");

      default:
        return setDataType(type);
    }
  };

  return (
    <>
      <form>
        <div style={{ marginBottom: 20 }}>
          <>
            <Grid>
              <Grid.Column
                computer={8}
                tablet={8}
                mobile={8}
                className="paddingRemoveVertical"
              >
                {disableEdit ? (
                  <InputText
                    register={register}
                    errors={errorsNewSupplier.supplier_name}
                    labelName={"Supplier Name*"}
                    placeholder="Type your supplier name"
                    name="supplier_name"
                    required={true}
                    errorMessage={"Please enter supplier name"}
                    disabled={true}
                  />
                ) : (
                  <SearchValue
                    searchDataValues={ingredientSupplierList}
                    defaultValue={supplierData?.supplier_name || ""}
                    checkOther={(status: any) => {
                      handleSearchValidation(status);
                    }}
                    selectDetails={getSearchValue}
                    title={"Supplier Name"}
                    disable={!disable}
                  />
                )}
              </Grid.Column>
            </Grid>
            {!isEmpty(supplierData) ? (
              <>
                <Grid style={{ marginTop: "25px" }}>
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <Grid>
                      <Grid.Column computer={4} tablet={4} mobile={4}>
                        <InputText
                          register={register}
                          errors={errors.unit_size}
                          labelName={"Unit Size"}
                          placeholder="Unit Size"
                          defaultValue={""}
                          name="unit_size"
                          required={true}
                          errorMessage={"Please enter unit size"}
                          type="number"
                          valueAsNumber={true}
                          step={0.0001}
                          disabled={dataView}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={4}>
                        <DropDown
                          labelName={"Unit Type"}
                          currentData={UNIT_TYPES}
                          defaultValue={defaultType}
                          customGridColumn={"customGridColomnTyp"}
                          handleChangeState={(e: any, { value }: any) => {
                            setDefaultType(value);
                          }}
                          disabled={dataView}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={4}>
                        <InputText
                          register={register}
                          errors={errors.ean_bar_code}
                          labelName={"EAN Barcode"}
                          placeholder="EAN Barcode"
                          name="ean_bar_code"
                          required={false}
                          disabled={dataView}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={4}>
                        <InputText
                          register={register}
                          errors={errors.product_id}
                          labelName={"Product ID"}
                          placeholder="Product ID"
                          name="product_id"
                          required={false}
                          disabled={dataView}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <div className="enviorenmentDataSelectBox">
                      <Grid>
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                          <p className="enviorenmentDataSelectTitle">
                            Do you want to Add Environmental Data ?
                          </p>
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                          <Switch
                            onClick={() => {
                              if (viewEnvironmentalData) {
                                setViewEnvironmentalData(false);
                              } else {
                                setViewEnvironmentalData(true);
                              }
                            }}
                            titleOne={"No"}
                            titleTwo={"Yes"}
                            titleOneValue={"No"}
                            titleTwoValue={"Yes"}
                            defaultValue={viewEnvironmentalData ? "Yes" : "No"}
                            disabled={dataView}
                          />
                        </Grid.Column>
                        {viewEnvironmentalData ? (
                          <Grid.Column
                            computer={16}
                            tablet={16}
                            mobile={16}
                            className="paddingRemoveBottom"
                          >
                            <Grid>
                              <Grid.Column
                                computer={8}
                                tablet={8}
                                mobile={16}
                                className="paddingRemoveTop"
                              >
                                <DropDown
                                  labelName="Ingredient Type"
                                  placeholder="Ingredient Type"
                                  defaultValue={ingredientType}
                                  currentData={INGREDIENT_TYPE}
                                  handleChangeState={(
                                    e: any,
                                    { value }: any
                                  ) => {
                                    setIngredientType(value);
                                  }}
                                  search
                                  key={"country"}
                                  customGridColumn={"customGridColomnTyp"}
                                  disabled={dataView}
                                />
                              </Grid.Column>
                              <Grid.Column
                                computer={8}
                                tablet={8}
                                mobile={16}
                                className="paddingRemoveTop"
                              >
                                <DropDown
                                  labelName="Data Type"
                                  placeholder="Select Type"
                                  defaultValue={dataType}
                                  currentData={DATA_TYPES}
                                  customGridColumn={"customGridColomnTyp"}
                                  handleChangeState={(
                                    e: any,
                                    { value }: any
                                  ) => {
                                    checkSupplierAsUser(value);
                                  }}
                                  disabled={dataView}
                                />
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                        ) : null}
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid>
                {viewEnvironmentalData ? (
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <Grid>
                      {produceIngredient ? (
                        <>
                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              required={true}
                              labelName="Produce Type"
                              placeholder="Select Produce Type"
                              defaultValue={produceType}
                              currentData={produceTypes}
                              handleChangeState={(e: any, { value }: any) => {
                                setProduceType(value);
                              }}
                              loading={isProduceTypesLoading}
                              customGridColumn={"customGridColomnTyp"}
                              search
                              key={"produceType"}
                              disabled={dataView}
                              errorMessage={"Produce is required"}
                              customRequired={true}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <InputText
                              register={register}
                              errors={errors.produceCultivar}
                              labelName={"Produce Cultivar"}
                              placeholder="Produce Cultivar"
                              name="produceCultivar"
                              required={false}
                              customGridColumn={"addCultivarCustomGridColumn"}
                              disabled={dataView}
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              labelName="Farming Type"
                              placeholder="Select Farming Type"
                              defaultValue={farmingType}
                              currentData={FARMING_TYPES}
                              customGridColumn={"customGridColomnTyp"}
                              handleChangeState={(e: any, { value }: any) => {
                                setFarmingType(value);
                              }}
                              disabled={dataView}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              labelName="Field Type"
                              placeholder="Select Field Type"
                              defaultValue={fieldType}
                              currentData={FIELD_TYPES}
                              customGridColumn={"customGridColomnTyp"}
                              handleChangeState={(e: any, { value }: any) => {
                                setFieldType(value);
                              }}
                              disabled={dataView}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              labelName="Country"
                              placeholder="Select country"
                              defaultValue={country}
                              currentData={countries()}
                              handleChangeState={(e: any, { value }: any) => {
                                setCountry(value);
                              }}
                              loading={isCountriesDataLoading}
                              search
                              key={"country"}
                              customGridColumn={"customGridColomnTyp"}
                              disabled={dataView}
                            />
                          </Grid.Column>
                        </>
                      ) : null}

                      {processIngredient ? (
                        <>
                          <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              required={true}
                              labelName="Specify Ingredient"
                              placeholder="Specify Ingredient"
                              defaultValue={specifyIngredientValue}
                              currentData={specifyIngredient}
                              handleChangeState={(e: any, { value }: any) => {
                                setSpecifyIngredientValue(value);
                              }}
                              loading={isCountriesDataLoading}
                              search
                              key={"country"}
                              customGridColumn={"customGridColomnTyp"}
                              disabled={dataView}
                              errorMessage={"Specify Ingredient is required"}
                              customRequired={true}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              labelName="Country"
                              placeholder="Select country"
                              defaultValue={country}
                              currentData={countries()}
                              handleChangeState={(e: any, { value }: any) => {
                                setCountry(value);
                              }}
                              loading={isCountriesDataLoading}
                              search
                              key={"country"}
                              customGridColumn={"customGridColomnTyp"}
                              disabled={dataView}
                            />
                          </Grid.Column>
                        </>
                      ) : null}
                    </Grid>
                  </Grid.Column>
                ) : null}
              </>
            ) : null}
          </>
          {dataType === IngredientDataType.USER &&
          ingredientId &&
          supplierId ? (
            <CustomButton
              theme="green"
              customColumnStyle={"customIngredientUnitButton"}
              customBase={"mt-1"}
              title={"Ingredient Mapping"}
              onClick={() => {
                navigation(
                  `/mapping/${MappingSourceType.ingredient}?sourceId=${ingredientId}&ownerId=${supplierId}`
                );
              }}
            />
          ) : null}
        </div>
      </form>
    </>
  );
};

export default SupplerIngredientDataView;
