import React, { useState, useEffect } from "react";
import { Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import {
  InputText,
  CustomButton,
  InputTextArea,
  CommonTable,
  NewImageUploader,
  Switch,
  CommonModal,
  TitleView,
  DropDown,
} from "../../../components";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { modelContentUpdateType, ProductUnitTable, UNIT_TYPES } from "../../../config/constants";
import { errorView } from "../../../helpers/ErrorHandler";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import {
  useGetProductById,
  usePutEditProduct,
  usePutEditSalesUnit,
  usePostUSalesUnit,
  useUploadProductImage,
  useDeleteUploadProductImage,
} from "../../../api/product";
import CommonSplitContainer from "../../../components/commonSplitContainer/CommonSplitContainer";
import PlusIcon from "../../../assets/commonIcon/PlusIcon";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";

import "./productEdit.scss";

interface ProductEditModalProps {
  visibleModal: boolean;
  setVisibleModal: (visible: boolean) => void;
  productId: any;
}

interface SalesUnit {
  _id: string;
  sales_unit_name?: string;
  sales_unit_size?: number | string;
  sales_unit_type?: string;
  ean_barcode?: string;
  sales_unit_status?: string;
  has_drawing?: boolean;
}

interface ProductDetails {
  _id: string;
  product_name: string;
  registration_number?: string;
  product_other_info?: string;
  product_type: string;
  product_image_path?: string;
  sales_units?: SalesUnit[];
  sales_unit_size?: any
  sales_unit_type?: any
  ean_barcode?: any
  sales_unit_name?: any
}

const ProductEditModal: React.FC<ProductEditModalProps> = ({
  visibleModal,
  setVisibleModal,
  productId,
}) => {
  const [addProductUnit, setProductUnit] = useState<SalesUnit[]>([]);
  const [productType, setProductType] = useState<string>("PROCESS");
  const [enableSizeEditStatus, setEnableSizeEditStatus] = useState<boolean>(false);
  const [enableSizeEdit, setEnableSizeEdit] = useState<SalesUnit | null>(null);
  const [defaultType, setDefaultType] = useState<string>(UNIT_TYPES[0].value);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<SalesUnit | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue: setProductValue,
  } = useForm<ProductDetails>();

  const {
    register: registerUnitSize,
    formState: { errors: errorsUnit },
    handleSubmit: handleSubmitUnitSize,
    reset: resetUnitSize,
    setValue,
  } = useForm<SalesUnit>();

  const {
    data: productDetails,
    isLoading: productDetailsLoading,
    refetch,
  } = useGetProductById(productId);

  const { isLoading: editProductLoading, mutate: editProductData } = usePutEditProduct();
  const { isLoading: editProductUnitLoading, mutate: editSalesUnit } = usePutEditSalesUnit();
  const { isLoading: addProductUnitLoading, mutate: addNewSalesUnit } = usePostUSalesUnit();
  const { isLoading: isLoadingProductImage, mutate: uploadProductImage } = useUploadProductImage();
  const { isLoading: isLoadingProductImageDelete, mutate: deleteProductImage } = useDeleteUploadProductImage();

  const handleSalesUnitDeleteConfirmation = () => {
    if (deleteData?.has_drawing) {
      errorView("This product already has a digital twin (Mapping), it cannot be deleted.");
      return;
    }
    editUnitSize(deleteData, modelContentUpdateType.delete);
  };

  const setUnitSizeUpdate = (data: SalesUnit) => {
    setEnableSizeEdit(data);
    setEnableSizeEditStatus(true);
    setValue("sales_unit_name", data.sales_unit_name);
    setValue("sales_unit_size", data.sales_unit_size);
    setValue("sales_unit_type", data.sales_unit_type);
    setValue("ean_barcode", data.ean_barcode);
  };

  const addUnitData = (data: any) => {
    let salesUnit = {
      product_id: productDetails?._id,
      sales_units: [
        {
          sales_unit_size: data?.sales_unit_size ?? "",
          sales_unit_type: defaultType ?? "",
          ean_barcode: data?.ean_barcode ?? "",
          sales_unit_name: data?.sales_unit_name ?? "",
        },
      ],
    };
    addNewSalesUnit(salesUnit, {
      onSuccess: () => {
        refetch(productId);
        setDefaultType(UNIT_TYPES[0].value);
        reset({
          sales_unit_size: "",
          sales_unit_type: "",
          ean_barcode: "",
          sales_unit_name: "",
        });
        resetUnitSize();
      },
    });
  };

  const editUnitSize = (data: any, status: number) => {
    const updateValue = {
      product_id: productDetails?._id,
      sales_unit_id: status === modelContentUpdateType.add ? enableSizeEdit?._id : data._id,
      sales_unit_name: data.sales_unit_name,
      sales_unit_size: data.sales_unit_size,
      sales_unit_type: data.sales_unit_type,
      ean_barcode: data.ean_barcode,
      sales_unit_status: status === modelContentUpdateType.add ? "ACTIVE" : "DISABLE",
    };
    editSalesUnit(updateValue, {
      onSuccess: () => {
        resetUnitSize();
        setDefaultType(UNIT_TYPES[0].value);
        refetch();
      },
    });
  };

  useEffect(() => {
    if (productDetails) {
      setProductValue("product_name", productDetails.product_name);
      setProductValue("registration_number", productDetails.registration_number ?? "");
      setProductValue("product_other_info", productDetails.product_other_info ?? "");
      setProductUnit(productDetails.sales_units ?? []);
      setProductType(productDetails.product_type);
    }
    return () => {
      setEnableSizeEditStatus(false);
      setProductUnit([]);
      setProductType("PROCESS");
    };
  }, [productDetails, visibleModal]);

  //check product error view
  const checkProductSaveCondition = (data: any) => {
    if (isEmpty(data.product_name.trim())) {
      errorView("Product name is required");
      return true;
    } else if (addProductUnit.length <= 0) {
      errorView("Please add product sizes");
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (data: any) => {
    if (!checkProductSaveCondition(data)) {
      let { product_name, product_other_info, registration_number } = data;
      let productData = {
        _id: productDetails?._id,
        product_name,
        product_other_info,
        registration_number,
        product_type: productType,
      };
      editProductData(productData, {
        onSuccess: () => {
          setVisibleModal(true);
          reset();
          resetUnitSize();
        },
      });
      if (!data?.enableNavigate || false) {
        setVisibleModal(true);
      }
    }
  };

  const loadTableData = () => {
    return addProductUnit.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.sales_unit_name ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.sales_unit_size ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.sales_unit_type ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.ean_barcode ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                  onClick={() => {
                    setUnitSizeUpdate(data);
                    setDefaultType(data.sales_unit_type);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                  onClick={() => {
                    setDeleteData(data);
                    setVisibleDeleteModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  if (
    productDetailsLoading ||
    editProductLoading ||
    editProductUnitLoading ||
    addProductUnitLoading ||
    isLoadingProductImage ||
    isLoadingProductImageDelete
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const cancelProduct = () => {
    setVisibleModal(true);
    reset();
    resetUnitSize();
  }

  const saveProduct = () => {
    const values = getValues();
    onSubmit(values);
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(true);
          reset();
          resetUnitSize();
        }}
        centered={true}
        title="Edit Product"
      >
        <TitleView
          title="Product Info"
          CustomTitleViewMain="productEditTitle"
        />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CommonSplitContainer
                  topChild={
                    <>
                      <Grid>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                          <Grid>
                            <Grid.Column
                              computer={8}
                              tablet={16}
                              mobile={16}
                            >
                              <InputText
                                register={register}
                                errors={errors.product_name}
                                labelName={"Name"}
                                placeholder="Name"
                                name="product_name"
                                required={true}
                                errorMessage={"Please enter product name"}
                              />
                            </Grid.Column>
                            <Grid.Column
                              computer={8}
                              tablet={16}
                              mobile={16}
                            >
                              <InputText
                                register={register}
                                errors={errors.registration_number}
                                labelName={"Register number"}
                                placeholder="Register number"
                                name="registration_number"
                                required={false}
                              />
                            </Grid.Column>
                            <Grid.Column
                              computer={8}
                              tablet={16}
                              mobile={16}
                            >
                              <Switch
                                label={"Product Type"}
                                onClick={(data: any) => {
                                  setProductType(data);
                                }}
                                titleOne={"Processed Product"}
                                titleTwo={"Farm Product"}
                                defaultValue={productDetails?.product_type}
                                titleOneValue={"PROCESS"}
                                titleTwoValue={"FARM"}
                              />

                            </Grid.Column>

                            <Grid.Column computer={8} tablet={16} mobile={16}>
                              <NewImageUploader
                                defaultImage={productDetails?.product_image_path}
                                buttonTitle={"Upload an Image"}
                                imageData={(data: any) => {
                                  if (data) {
                                    let dataValue = {
                                      product_id: productDetails?._id,
                                      file: data,
                                    };
                                    uploadProductImage(dataValue, {
                                      onSuccess() {
                                        refetch(productId);
                                      },
                                    });
                                  }
                                }}
                                deleteImage={() => {
                                  let data = {
                                    product_id: productDetails?._id,
                                  };
                                  deleteProductImage(data, {
                                    onSuccess() {
                                      refetch(productId);
                                    },
                                  });
                                }}
                                
                                customImageDeleteMain={"customProductImageViewMain"}
                              />
                            </Grid.Column>

                            <Grid.Column
                              computer={16}
                              tablet={16}
                              mobile={16}
                              className="paddingRemoveTop mb-1"
                            >
                              <InputTextArea
                                register={register}
                                errors={errors.product_other_info}
                                labelName={"Additional Information"}
                                placeholder="Additional Information"
                                name="product_other_info"
                                customInputValidation={true}
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                      <TitleView title="Add Unit sizes" />
                      <Grid>
                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="userBankDetailsMain "
                        >
                          <div>
                            <Grid>
                              <Grid.Column computer={3} tablet={8} mobile={16}>
                                <InputText
                                  register={registerUnitSize}
                                  errors={errorsUnit.sales_unit_name}
                                  labelName={"Unit Name"}
                                  placeholder="Unit Name"
                                  name="sales_unit_name"
                                  required={true}
                                  errorMessage="Unit name is required"
                                />
                              </Grid.Column>
                              <Grid.Column computer={3} tablet={8} mobile={16}>
                                <InputText
                                  register={registerUnitSize}
                                  errors={errorsUnit.sales_unit_size}
                                  labelName={"Unit Size"}
                                  placeholder="Unit Size"
                                  name="sales_unit_size"
                                  minNumber={0}
                                  required={true}
                                  type="number"
                                  valueAsNumber={true}
                                  errorMessage="Unit size is required"
                                />
                              </Grid.Column>
                              <Grid.Column computer={3} tablet={8} mobile={16}>
                                <DropDown
                                  labelName={"Unit Type"}
                                  placeholder="Type"
                                  currentData={UNIT_TYPES}
                                  defaultValue={defaultType}
                                  customGridColumn={"customGridColomnType"}
                                  handleChangeState={(e: any, { value }: any) => {
                                    setValue("sales_unit_type", value);
                                    setDefaultType(value);
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column computer={4} tablet={8} mobile={16}>
                                <InputText
                                  register={registerUnitSize}
                                  errors={errorsUnit.ean_barcode}
                                  labelName={"EAN Barcode"}
                                  placeholder="EAN Barcode"
                                  name="ean_barcode"
                                  errorMessage="EAN barcode is required"
                                />
                              </Grid.Column>

                              <Grid.Column computer={3} tablet={8} mobile={16}>
                                {enableSizeEditStatus ? (
                                  <CustomButton
                                    theme="green"
                                    customColumnStyle={"addUnitSize"}
                                    title="UPDATE"
                                    onClick={handleSubmitUnitSize((data) => {
                                      editUnitSize(data, modelContentUpdateType.add);
                                    })}
                                  />
                                ) : (
                                  <CustomButton
                                    theme="green"
                                    customColumnStyle={"addUnitSize"}
                                    icon
                                    iconChild={<PlusIcon />}
                                    customBase="productUnitAddButton"
                                    onClick={handleSubmitUnitSize((data) => {
                                      addUnitData(data);
                                    })}
                                  />
                                )}
                              </Grid.Column>
                            </Grid>
                            <Grid.Column
                              computer={16}
                              tablet={16}
                              mobile={16}
                              className="productRegisterTable"
                            >
                              {addProductUnit?.length > 0 && (
                                <CommonTable tableHeaderData={ProductUnitTable}>
                                  {loadTableData()}
                                </CommonTable>
                              )}
                            </Grid.Column>
                          </div>
                        </Grid.Column>
                      </Grid>
                    </>
                  }
                  bottomChild={
                    <MainBottomButtonView
                      cancelButtonStatus
                      saveButtonStatus
                      cancelButton={cancelProduct}
                      saveButton={saveProduct}
                    />
                  }
                />
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData(null);
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          handleSalesUnitDeleteConfirmation();
        }}
        title="Delete Unit Size"
        subTitle="Are you sure you want to delete unit size?"
      />
    </>
  );
};

export default ProductEditModal;
