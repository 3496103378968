import { useState } from "react";
import { Grid } from "semantic-ui-react";
import { CommonModal, TabView } from "../../../components";
import IngredientDataRegister from "../ingredientRegister/IngredientDataRegister";
import CommonSplitContainer from "../../../components/commonSplitContainer/CommonSplitContainer";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import { IngredientViewTabs, TAB_KEYS } from "../../../config/constants";
import IngredientSummaryTab from "./IngredientViewTabs/IngredientSummaryTab";

interface IngredientDetailsModalProps {
  ingredientIdToUpdate: any;
  visibleModal: boolean;
  setVisibleModal: (visible: boolean) => void;
}

const IngredientDetailsModal = ({
  ingredientIdToUpdate,
  visibleModal,
  setVisibleModal,
}: IngredientDetailsModalProps) => {
  const [selectedTab, setSelectedTab] = useState<Number>(0);

  const renderTabContent = () => {
    switch (selectedTab) {
      case TAB_KEYS.SUMMARY:
        return (
          <IngredientSummaryTab
            ingredientIdToUpdate={ingredientIdToUpdate}
            visibleModal={visibleModal}
          />
        );
      case TAB_KEYS.ENVIRONMENTAL_FOOTPRINT:
        return <p>Environmental Footprint Content</p>;
      case TAB_KEYS.SUPPLY_CHAIN_MAPPING:
        return <p>Supply Chain Mapping Content</p>;
      case TAB_KEYS.HISTORY:
        return <p>History Content</p>;
      case TAB_KEYS.UNIT_PRICE_ANNUAL_VOLUME:
        return <p>Unit Price & Annual Volume Content</p>;
      case TAB_KEYS.SALES_VOLUMES:
        return <p>Sales Volumes Content</p>;
      default:
        return null;
    }
  };

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      title="Ingredient Details"
    >
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain"
        >
          <CommonSplitContainer
            currentHeight={0.94}
            topChild={
              <>
                <IngredientDataRegister
                  ingredientListByProduct={[]}
                  currentIngredientData={ingredientIdToUpdate}
                  disableEdit={true}
                />
                <TabView
                  tabData={IngredientViewTabs}
                  computer={2}
                  tablet={16}
                  mobile={16}
                  defaultSelect={selectedTab}
                  customMainTab="tabMain"
                  disable={false}
                  onChange={(index: number) => setSelectedTab(index)}
                />
                {renderTabContent()}
              </>
            }
            bottomChild={
              <MainBottomButtonView
                cancelButtonStatus
                cancelButton={() => setVisibleModal(false)}
              />
            }
          />
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default IngredientDetailsModal;
