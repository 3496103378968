import { Table, Popup, Icon, TableFooter, TableRow, TableHeaderCell } from "semantic-ui-react";

import "./CommonTable.scss";

export const CommonTable = ({
  tableHeaderData = [],
  children,
  singleLine = true,
  isSimulateView,
  tableFooterCol = '5',
  tableFooterChild,
  enableTableFooter = false
}: any) => {
  return singleLine ? (
    <div className="tableContainer">
      <Table singleLine={singleLine} className="table" celled>
        <Table.Header>
          <Table.Row>
            {tableHeaderData.map((data: any, index: any) => {
              return (
                <Table.HeaderCell
                  className={
                    isSimulateView ? "tbleHeaderSimulate" : "tbleHeader"
                  }
                  width={data?.col}
                  key={index}
                >
                  {data?.name || ""}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>{children}</Table.Body>
        {enableTableFooter
          ?
          <TableFooter className="tableFooterMain">
            <TableRow>
              <TableHeaderCell colSpan={tableFooterCol}>
                {tableFooterChild}
              </TableHeaderCell>
            </TableRow>
          </TableFooter> : null
        }
      </Table>
    </div>
  ) : (
    <div className="tableContainer">
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            {tableHeaderData.map((data: any, index: any) => {
              return (
                <Table.HeaderCell
                  className={
                    isSimulateView ? "tbleHeaderSimulate" : "tbleHeader"
                  }
                  width={data?.col}
                  key={index}
                >
                  <span className="tbleHeader">{data?.name || ""}</span>
                  {data?.information ? (
                    <Popup
                      content={data?.information}
                      trigger={
                        <Icon className="iconPM" name="exclamation circle" />
                      }
                    />
                  ) : null}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>{children}</Table.Body>
      </Table>
    </div>
  );
};
