import React, { useState } from "react";
import { Button, Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";

import { CustomButton, CommonTable, TitleBar } from "../../../components";
import {
  calculationStatus,
  modelContentType,
  ProductTableCol,
} from "../../../config/constants";
import ProductRegisterModal from "../productRegister/ProductRegisterModal";
import ProductDetailModal from "../productDetails/ProductDetailsModal";
import ProductEditModal from "../productEdit/ProductEditModal";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { useGetProductData, useDeleteProductById } from "../../../api/product";
import PaginationView from "../../../components/pagination/Pagination";
import IngredientRegisterModalView from "../../ingredients/ingredientRegister/IngredientRegisterModalView";
import { errorView } from "../../../helpers/ErrorHandler";
import { getUserRolesInLocal } from "../../../utils/cacheStorage";
import { ROLES } from "../../../config/permission-maps";
import PlusIcon from "../../../assets/commonIcon/PlusIcon";

import "./productList.scss";
import ProductFilter from "./ProductFilter";
import { MappingSourceType } from "../../../config/enums/MappingSourceType.enum";
interface SalesUnit {
  _id: string;
  sales_unit_name?: string;
  climate_impact?: string;
}

interface Product {
  _id?: string;
  product_name?: string;
  sales_units?: SalesUnit[];
  registration_number?: string;
}

const ProductList: React.FC = () => {
  const [productId, setProductId] = useState<string>("");
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleProductDetailsModal, setVisibleProductDetailsModal] =
    useState<boolean>(false);
  const [visibleProductEditModal, setVisibleProductEditModal] =
    useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<Product | null>(null);
  const [visibleRegModal, setVisibleRegModal] = useState<boolean>(false);
  const [dataToPass, setDataToPass] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [productIds, setProductIds] = useState<any>([]);
  const [salesUnitIds, setSalesUnitIds] = useState<any>([]);
  const [calculateStatus, setCalculateStatus] = useState<any>("");

  const localUserRole = getUserRolesInLocal();
  const checkIsUserSupplier = localUserRole?.[0] === ROLES.supplier;

  const {
    data,
    isLoading: productListLoading,
    refetch,
  } = useGetProductData({
    product_status: "ACTIVE",
    pageNumber,
    pageSize,
    productIds,
    salesUnitIds,
    calculateStatus,
  });

  const refetchWithFilters = (
    newProductIds: string[],
    newSalesUnitIds: string[],
    status: number
  ) => {
    setProductIds(newProductIds);
    setSalesUnitIds(newSalesUnitIds);
    setCalculateStatus(calculationStatus[status]);
    refetch();
  };

  const { isLoading, mutate } = useDeleteProductById();

  const totalPages = Math.ceil((data?.total || 0) / (data?.pageSize || 1));

  const getProductDetails = (id: string, type: number) => {
    setProductId(id);
    if (type === modelContentType.edit) {
      setVisibleProductEditModal(true);
    } else {
      setVisibleProductDetailsModal(true);
    }
  };

  const handleProductDeleteConfirmation = () => {
    if (deleteData?.sales_units?.length) {
      errorView("This Product has Sales Units, it cannot be deleted.");
      return;
    }
    deleteProductDetails(deleteData);
  };

  const deleteProductDetails = (product: any) => {
    mutate(product._id, {
      onSuccess: () => {
        refetch();
      },
    });
    setDeleteData(null);
  };

  if (productListLoading || isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar enableTitle titleName="Products" />
      <Grid className="pt-0 flex">
        <Grid.Column computer={16} className="productContentView">
          <CustomButton
            theme="green"
            title="Add New Product"
            onClick={() => setVisibleModal(true)}
            customColumnStyle="topButtonView"
            iconWithText
            iconChild={<PlusIcon />}
          />
          <ProductFilter refetchWithFilters={refetchWithFilters} />
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={ProductTableCol} singleLine={false}>
            {data?.result?.map((product, index) => (
              <Table.Row className="tbleR" key={index}>
                <Table.Cell>
                  <p>{product.product_name}</p>
                </Table.Cell>
                <Table.Cell>
                  {product.sales_units?.map((unit: any) => (
                    <p className="salesUnitLink" key={unit._id}>
                      <Link
                        to={`/mapping/${MappingSourceType.product}?sourceId=${unit._id}`}
                      >
                        {unit.sales_unit_name || "-"}
                      </Link>
                    </p>
                  )) || "-"}
                </Table.Cell>
                <Table.Cell>
                  {product.sales_units?.map((unit: any) => (
                    <p key={unit._id}>
                      {checkIsUserSupplier && unit.climate_impact
                        ? "Calculated: Upgrade to see results"
                        : unit.climate_impact || "Calculation is in progress"}
                    </p>
                  )) || "-"}
                </Table.Cell>
                <Table.Cell>
                  <p>{product.registration_number}</p>
                </Table.Cell>
                <Table.Cell>
                  <Grid>
                    <Grid.Column computer={4} tablet={5} mobile={5}>
                      <TbFileSearch
                        cursor="pointer"
                        onClick={() =>
                          getProductDetails(product._id, modelContentType.view)
                        }
                        size={24}
                        color="var(--tableEditIcon)"
                      />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={5} mobile={5}>
                      <MdModeEditOutline
                        cursor="pointer"
                        onClick={() =>
                          getProductDetails(product._id, modelContentType.edit)
                        }
                        size={24}
                        color="var(--tableEditIcon)"
                      />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={5} mobile={5}>
                      <MdDeleteOutline
                        cursor="pointer"
                        size={24}
                        color="var(--tableEditIcon)"
                        onClick={() => {
                          setVisibleDeleteModal(true);
                          setDeleteData(product);
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </Table.Cell>
              </Table.Row>
            ))}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <PaginationView
        currentPageNo={pageNumber}
        totalPages={totalPages}
        pageSize={pageSize}
        currentSelectPage={setPageNumber}
        changePageSize={(size: any) => {
          setPageSize(size);
          setPageNumber(1);
        }}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        approve={handleProductDeleteConfirmation}
        title="Delete Product Data"
        subTitle="Are you sure you want to delete your product data?"
      />
      <ProductRegisterModal
        visibleModal={visibleModal}
        setVisibleModal={(status: any, data: any) => {
          if (status) {
            refetch();
            setVisibleModal(false);
            if (data?.enableNavigate || false) {
              setDataToPass(data.productData);
            }
            setVisibleRegModal(data?.enableNavigate || false);
          }
        }}
      />
      {visibleProductDetailsModal ? (
        <ProductDetailModal
          visibleModal={visibleProductDetailsModal}
          setVisibleModal={(status: any) => {
            setVisibleProductDetailsModal(false);
          }}
          productId={productId}
        />
      ) : null}
      {visibleProductEditModal ? (
        <ProductEditModal
          visibleModal={visibleProductEditModal}
          setVisibleModal={(status: any) => {
            if (status) {
              refetch();
              setVisibleProductEditModal(false);
            }
          }}
          productId={productId}
        />
      ) : null}
      {visibleRegModal ? (
        <IngredientRegisterModalView
          state={dataToPass}
          visibleModal={visibleRegModal}
          setVisibleModal={() => {
            setDataToPass({});
            setVisibleRegModal(false);
          }}
        />
      ) : null}
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          handleProductDeleteConfirmation();
        }}
        title="Delete Product Data"
        subTitle="Are you sure you want to delete your product data?"
      />
    </>
  );
};

export default ProductList;
