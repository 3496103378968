import React, { useEffect, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { isEmpty } from "lodash";

import {
  InputText,
  CommonModal,
  TitleView,
} from "../../../components";
import ProgressBar from "../../../components/progressBar/ProgressBar";

import "./productSummaryDetails.scss";
import { IngredientImpact, CycleStage } from "../../../config/constants";

const ProductSummaryDetailModal = ({ visibleModal, setVisibleModal }: any) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (data: any) => {};

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => setVisibleModal(false)}
        centered={true}
        
        title="Detail View"
      >
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <TitleView title="Row view" />
            <div className="productSummaryDetailsInsert">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid className="productDetailsViewMain">
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.product_name}
                      labelName={"Indicator"}
                      placeholder="Name"
                      name="product_name"
                      required={true}
                      errorMessage={"Please enter product name"}
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.registration_number}
                      labelName={"Measure/Unit"}
                      placeholder="Register number"
                      name="registration_number"
                      required={false}
                      disabled={true}
                    />
                  </Grid.Column>
                </Grid>
              </form>
            </div>

            <Grid className="backgroundMain">
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="Impact by life cycle stages" />
                {CycleStage.map((data, index) => {
                  return (
                    <ProgressBar
                      key={index}
                      iconView={true}
                      title={data.title}
                      IconName={data.icon}
                      percentage={50}
                    />
                  );
                })}
              </Grid.Column>
            </Grid>

            <Grid className="backgroundMain">
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="Impact by ingredients" />
                {IngredientImpact.map((data, index) => {
                  return <ProgressBar key={index} title={data.title} percentage={50} />;
                })}
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default ProductSummaryDetailModal;
