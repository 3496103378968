import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

import { TitleBar } from "../../components/titleBar/Titlebar";
import CustomSupplierRequest from "../../components/customSupplierRequest/CustomSupplierRequest";
import { useGetSupplierRequest } from "../../api/supplier";

import "./supplierRequest.scss";

const SupplierRequest = () => {
  const { isLoading: isSupplierRequestLoading, data: supplierRequestData } =
    useGetSupplierRequest();

  if (isSupplierRequestLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <div>
      <TitleBar
        enableTitle={true}
        titleName={"Supplier Request"}
        customPageButtonContainer="product-title-container"
      />
      <CustomSupplierRequest data={supplierRequestData} />
    </div>
  );
};

export default SupplierRequest;
