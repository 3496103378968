import { useState, useContext, useMemo, useRef } from "react";
import { DropDown, InputText } from "../../../../../components";
import { useReactFlow } from "reactflow";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import {
  usePostProcessGateInternalTransportData,
  usePutProcessGateInternalTransportData,
  useDeleteProcessGateInternalTransportData,
  useGetProcessGateInternalTransportById,
} from "../../../../../api/process";
import { useGetTransportTypesList } from "../../../../../api/cultivations";
import { errorView, successMessage } from "../../../../../helpers/ErrorHandler";
import { DrawingContext } from "../../../../../components/drawing-tool/DrawingTool";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import { valueGraterThanZero } from "../../../../../utils/utils";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import NoteAndDocument from "../../../../../components/noteAndDocument/NoteAndDocument";

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

interface SaveDocumentData {
  saveDocumentAndNote(id: any): void;
  deleteComment(): void;
}

export const ProcessInternalTransportBase = ({ modalData }: any) => {
  const { processGateId, nodeItem } = modalData;
  const { deleteElements } = useReactFlow();

  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);

  const customAverageDistanceValidation = useRef<HandleValidation>(null);

  const { height } = useWindowDimensions();
  const [transportType, setTransportType] = useState("1");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const ref = useRef<SaveDocumentData>(null);
  const transportId = nodeItem?.data?.reference?.internalTransportId;

  const { data: TransportTypeList } = useGetTransportTypesList();

  const { mutate: postInternalTransportData } =
    usePostProcessGateInternalTransportData();
  const { mutate: deleteInternalTransportData } =
    useDeleteProcessGateInternalTransportData();
  const { mutate: putInternalTransportData } =
    usePutProcessGateInternalTransportData();
  const {
    data: internalTransportData,
    status,
    fetchStatus,
  } = useGetProcessGateInternalTransportById(
    nodeItem?.data?.reference?.internalTransportId
  );

  const values = useMemo(() => {
    setTransportType(`${internalTransportData?.transportationType || "1"}`);
    return {
      internalTransportName: internalTransportData?.internalTransportName,
      averageDistance: internalTransportData?.averageDistance || 0,
      transportType: `${internalTransportData?.transportationType || "1"}`,
      weight: internalTransportData?.weight,
    };
  }, [nodeItem, internalTransportData]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const updateNodeData = async (data: any) => {
    try {
      await ref?.current?.saveDocumentAndNote(data?._id);
      const updatedNode = {
        ...nodeItem,
        data: {
          ...nodeItem?.data,
          label: `Internal Transport (${data?.internalTransportName})`,
          reference: {
            internalTransportId: data?._id,
          },
        },
      };
      const updatedChartNodes = chartNodes.map((n: any) => {
        if (n.id === nodeItem?.id) {
          return updatedNode;
        }
        return n;
      });
      setChartNodes(updatedChartNodes);
      setNodeItem(updatedNode);
      saveDrawing(chartEdges, updatedChartNodes);
      successMessage(
        nodeItem?.data.reference
          ? "Internal Transport updated successfully"
          : "Internal Transport created successfully"
      );
    } catch (error) {
      errorView(error);
    }
  };

  const onSubmit = (data: any) => {
    // Extract internal transport ID from nodeItem data
    const transportId = nodeItem?.data?.reference?.internalTransportId;

    // Prepare data to submit
    const dataToSubmit = {
      processGateId,
      internalTransportId: transportId,
      transportData: {
        internalTransportName: data?.internalTransportName,
        transportationType: Number(data?.transportType),
        averageDistance: data?.averageDistance,
        weight: data?.weight ?? 0,
      },
    };

    // Define the callback for success
    const onSuccess = (data: any) => {
      // Update node data with the returned data
      updateNodeData(data);
    };

    // Decide whether to use PUT or POST based on the presence of transportId
    if (transportId) {
      putInternalTransportData(dataToSubmit, { onSuccess });
    } else {
      postInternalTransportData(dataToSubmit, { onSuccess });
    }
  };

  const onNodesDelete = () => {
    ref?.current?.deleteComment();
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  const handleDeleteSuccess = () => {
    setNodeItem(null);
    onNodesDelete();
    successMessage("Internal Transport deleted successfully");
    setVisibleDeleteModal(false);
  };

  const handleDeleteInternalTransport = async () => {
    const dataToDelete = {
      processGateId: processGateId,
      internalTransportId: nodeItem?.data?.reference?.internalTransportId,
    };

    if (nodeItem?.data.reference) {
      deleteInternalTransportData(dataToDelete, {
        onSuccess: handleDeleteSuccess,
      });
      return;
    }
    handleDeleteSuccess();
  };

  if (
    nodeItem?.data.reference &&
    status == "loading" &&
    fetchStatus == "fetching"
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 100,
                overflowX: "hidden",
              }}
            >
              <Grid className="mb-2">
                <Grid.Column
                  computer={16}
                  tablet={8}
                  mobile={16}
                  className="pb-0"
                >
                  <InputText
                    labelName={"Internal Transport Name*"}
                    errorMessage="Internal Transport Name is required"
                    name="internalTransportName"
                    register={register}
                    errors={errors.internalTransportName}
                    required
                  />
                </Grid.Column>
                <Grid.Column
                  computer={16}
                  tablet={8}
                  mobile={16}
                  className="pb-0"
                >
                  <InputNumber
                    ref={customAverageDistanceValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    errors={errors?.averageDistance}
                    labelName={
                      "Average Distance from Filed(s) to Facility (km)*"
                    }
                    placeholder="Average Distance from Filed(s) to Facility (km)"
                    name={`averageDistance`}
                    errorMessage={"Please enter average distance"}
                    customRequired={true}
                    valueAsNumber={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={16}
                  tablet={8}
                  mobile={16}
                  className="pb-0"
                >
                  <DropDown
                    labelName={"Transportation Type"}
                    currentData={TransportTypeList || []}
                    defaultValue={transportType}
                    customGridColumn={"customGridColomnTyp"}
                    handleChangeState={(e: any, { value }: any) => {
                      setTransportType(value);
                      setValue("transportType", value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={16}
                  tablet={8}
                  mobile={16}
                  className="pt-0"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Weight(Kg)"}
                    placeholder="Weight(Kg)"
                    name={`weight`}
                  />
                </Grid.Column>
              </Grid>
              <NoteAndDocument parentId={transportId} ref={ref} />
            </div>
            <MainBottomButtonView
              deleteStatus={true}
              saveButtonStatus={true}
              saveButton={() => {
                customAverageDistanceValidation.current?.checkCustomRequired(
                  getValues().averageDistance
                );
              }}
              deleteButton={() => setVisibleDeleteModal(true)}
              saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          handleDeleteInternalTransport();
        }}
        title="Delete Process Gate Internal Transport"
        subTitle="Are you sure you want to remove the process gate internal transport? Please consider you cannot recover these data after removed"
      />
    </Grid>
  );
};
