import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { MappingSourceType } from "../config/enums/MappingSourceType.enum";
import { successMessage } from "../helpers/ErrorHandler";
import { ApiServiceErr, axiosApi, customConfig } from "./apiService";

interface drawingTool {
  _id: string;
  tenantId: string;
  salesUnitId: string;
  chartNodes: [
    {
      id: string;
      type: string;
      data: any;
      position: any;
      width: number;
      height: number;
    }
  ];
  chartEdges: [
    {
      id: string;
      type: string;
      data: any;
      position: any;
      width: number;
      height: number;
      style: any;
      markerEnd: any;
      source: string;
      sourceHandle: string;
      target: string;
    }
  ];
}

interface ProductSalesUnits {
  productName: string;
  productId: string;
  salesUnitName: string;
  salesUnitId: string;
}

interface ProductSalesUnitInputs {
  mappingType: MappingSourceType;
  searchKey?: string;
}

export const useGetDrawingToolData = (
  mappingType: MappingSourceType,
  salesUnitId: string | undefined
) =>
  useQuery<drawingTool, ApiServiceErr>(
    [salesUnitId],
    async () => {
      const response = await axiosApi.get(
        `/drawing-tool/${mappingType}/${salesUnitId}`,
        customConfig
      );
      return response?.data || null;
    },
    {
      cacheTime: 0,
      enabled: !!salesUnitId,
    }
  );

export const useCreateDrawing = (
  mappingType: MappingSourceType,
  opt?: UseMutationOptions
) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/drawing-tool/${mappingType}`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const useUpdateDrawing = (
  mappingType: MappingSourceType,
  opt?: UseMutationOptions
) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/drawing-tool/${mappingType}/${data._id}`,
      data
    );
    successMessage(response.data);
    return response.data;
  }, opt);

export const usePutDrawingToolDataToQueue = (
  mappingType: MappingSourceType,
  opt?: UseMutationOptions
) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/drawing-tool/${mappingType}/queue/${data._id}`
    );
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetProductSalesUnitsNotSavedDrawing = ({
  mappingType,
  searchKey,
}: ProductSalesUnitInputs) =>
  useQuery<ProductSalesUnits[], ApiServiceErr>(
    [searchKey, mappingType, "getProductSalesUnitsNotSavedDrawing"],
    async () => {
      const response = await axiosApi.get(
        `/drawing-tool/${mappingType}/sales-units-except-drawing`,
        {
          params: { searchKey },
        }
      );
      return response.data;
    }
  );

export const useCopyDrawingToolData = (
  mappingType: MappingSourceType,
  opt?: UseMutationOptions
) =>
  useMutation<string, ApiServiceErr, any>(
    async ({ drawingId, ownerId, sourceId }) => {
      const response = await axiosApi.post(
        `/drawing-tool/${mappingType}/copy/${drawingId}`,
        null,
        {
          params: { ownerId, sourceId },
        }
      );
      successMessage(response.data);
      return response.data;
    },
    opt
  );

export const useSendErrorDetails = (
  mappingType: MappingSourceType,
  opt?: UseMutationOptions<
    { message: string },
    ApiServiceErr,
    { ownerId: string; sourceId: string }
  >
) =>
  useMutation<
    { message: string },
    ApiServiceErr,
    {
      ownerId: string;
      sourceId: string;
      companyName: string;
      mappingUrl: string;
    }
  >(
    async ({ ownerId, sourceId, companyName, mappingUrl }) => {
      const response = await axiosApi.post(
        `/drawing-tool/${mappingType}/send-error-details`,
        { ownerId, sourceId, companyName, mappingUrl },
        customConfig
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        successMessage(data.message);
      },
      ...opt,
    }
  );

export const useUploadMappingImageAction = (
  mappingType: MappingSourceType,
  opt?: UseMutationOptions
) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/drawing-tool/${mappingType}/upload-final-image`,
      data,
      {
        headers: {
          ContentType: "multipart/form-data",
        },
      }
    );
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetMappingImageUri = (
  mappingType: MappingSourceType,
  imageKey: string
) =>
  useQuery<{ signedUrl: string }, ApiServiceErr>(
    [imageKey, "getMappingImageUri"],
    async () => {
      const response = await axiosApi.get(
        `/drawing-tool/${mappingType}/image-uri`,
        {
          params: { imageKey },
        }
      );
      return response.data;
    },
    {
      enabled: !!imageKey,
    }
  );
