import { useContext, useMemo } from "react";
import CultivarSimulationForm from "../../cultivation/cultivar/simulationCultivar/CultivarForm";
import useWindowDimensions from "../../../hooks/windowsSize";
import { TitleView } from "../../../components";
import { DrawingContext } from "../../../components/drawing-tool/DrawingTool";

export const CultivarBase = ({ modalData }: any) => {
  const { farmGateId, ingredientId, supplierId, nodeItem } = modalData;
  const {
    chartEdges,
    chartNodes,
    setNodeItem,
    setChartNodes,
    saveDrawing,
    secondId,
    baseId,
  } = useContext(DrawingContext);

  const productId = secondId;
  const salesUnitId = baseId;
  const { height } = useWindowDimensions();

  const getFarmGateParentId = nodeItem?.parentNode;

  //get ingredient and supplier data
  const farmGateParentData = useMemo(
    () => chartNodes.filter((e) => e.id == getFarmGateParentId),
    [getFarmGateParentId]
  );

  //update main farm date data
  const updateFarmGate = (currentNode: any, farmGateName?: string) => {
    const updatedFarmNode = {
      ...farmGateParentData[0],
      data: {
        ...farmGateParentData?.[0]?.data,
        ...(farmGateName && { label: `Farm Gate (${farmGateName})` }),
        reference: {
          ...currentNode.data.reference,
        },
      },
    };
    return updatedFarmNode;
  };

  // update drawing node
  const updateDrawingNode = (updatedNode: any, farmGateName?: string) => {
    const updatedFarmGateData = updateFarmGate(updatedNode, farmGateName);
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      } else if (n.id === getFarmGateParentId) {
        return updatedFarmGateData;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
  };

  return (
    <div style={{ height: height - 80 }}>
      <TitleView
        CustomTextTitle="customT"
        CustomTitleViewMain="Farm Gate"
        title="Farm Gate"
      />
      <CultivarSimulationForm
        supplierId={supplierId}
        ingredientId={ingredientId}
        farmGateId={farmGateId}
        nodeItem={nodeItem}
        productId={productId}
        salesUnitId={salesUnitId}
        updateDrawingNode={updateDrawingNode}
        setNodeItem={setNodeItem}
      />
    </div>
  );
};
