import { useMemo, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import { CommonModal, TitleView } from "../../../components";
import { useUpdateSupplierDetails } from "../../../api/supplier";
import SupplierRegisterForm from "../supplierRegisterForm/SuppllierRegisterForm";
import { THEME } from "../../../config/constants";
import CommonSplitContainer from "../../../components/commonSplitContainer/CommonSplitContainer";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";

import "./supplierEdit.scss";
interface Supplier {
  id?: string;
  supplier_name?: string;
  supplier_contact_name?: string;
  supplier_email?: string;
  supplier_phone?: string;
  supplier_address?: string;
  supplier_province?: string;
  supplier_country?: number;
  supplier_postal_code?: string;
  supplier_other_info?: string;
  is_supplier_as_user?: boolean;
  ingredients?: any[];
  supplierIngredientDetails?: any[];
  products?: any[];
  theme?: any
}

interface SupplierEditModalProps {
  visibleModal: boolean;
  closeModal: () => void;
  onSuccessEdit: () => void;
  supplier: Supplier | null;
  updateSupplier?: boolean

}

const SupplierEditModal: React.FC<SupplierEditModalProps> = ({
  visibleModal,
  closeModal,
  onSuccessEdit,
  supplier,
}) => {
  const [isSupplierAsUser, setIsSupplierAsUser] = useState<boolean>(false);
  const [country, setCountry] = useState<number>(1);

  const values = useMemo(() => {
    setIsSupplierAsUser(supplier?.is_supplier_as_user || false);
    setCountry(supplier?.supplier_country || 1);
    return supplier?.supplier_contact_name
      ? supplier
      : { ...supplier, supplier_contact_name: "" };
  }, [supplier]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Supplier>({
    defaultValues: values,
    values: values,
  });

  const { mutate: onUpdateSupplier, isLoading: isLoadingSupplierUpdate } =
    useUpdateSupplierDetails({
      onError: () => {
        closeModal();
      },
      onSuccess: () => {
        closeModal();
        onSuccessEdit();
        reset();
      },
    });

  const onSubmit = (data: Supplier) => {
    data.supplier_country = country;
    data.is_supplier_as_user = isSupplierAsUser;
    data.theme = THEME;
    delete data.supplierIngredientDetails;
    delete data.products;
    delete data.ingredients;
    onUpdateSupplier(data);
  };

  if (isLoadingSupplierUpdate) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => closeModal()}
      centered={true}
      title="Supplier Details"
    >
      <CommonSplitContainer
        topChild={
          <>
            <TitleView
              title="Supplier Details Edit"
              CustomTitleViewMain="mt-0"
            />
            <SupplierRegisterForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
              setCountry={setCountry}
              country={country}
              setIsSupplierAsUser={setIsSupplierAsUser}
              isSupplierAsUser={isSupplierAsUser}
            />
          </>

        }
        bottomChild={
          <MainBottomButtonView
            cancelButtonStatus
            saveButtonStatus
            saveTitle={"Update Supplier"}
            saveButton={handleSubmit((data) => onSubmit(data))}
            cancelButton={closeModal}
          />
        }
      />
    </CommonModal>
  );
};

export default SupplierEditModal;
