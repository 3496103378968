import React, { useState } from "react";
import { Grid, Table, Loader, Dimmer, TableRow } from "semantic-ui-react";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline } from "react-icons/md";
import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { IngredientTableColView } from "../../../config/constants";
import IngredientEditModal from "../ingredientEdit/IngredientEditModal";
import IngredientDetailsModal from "../ingredientDetails/IngredientDetailsModal";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import {
  useGetIngredientList,
  usePutSupplierData,
} from "../../../api/ingredients";
import PaginationView from "../../../components/pagination/Pagination";
import { successMessage } from "../../../helpers/ErrorHandler";
import IngredientRegisterModalView from "../ingredientRegister/IngredientRegisterModalView";
import { getUserRolesInLocal } from "../../../utils/cacheStorage";
import { ROLES } from "../../../config/permission-maps";
import PlusIcon from "../../../assets/commonIcon/PlusIcon";

const IngredientList = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [showDeleteIngredientModal, setShowDeleteIngredientModal] =
    useState(false);
  const [viewIngredientData, setViewIngrdientData] = useState();
  const [editIngredientData, setEditIngrdientData] = useState();
  const [deleteIngredientData, setDeleteIngrdientData] = useState<any>({});
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const localUserRole = getUserRolesInLocal();
  const checkIsUserSupplier = localUserRole?.[0] === ROLES.supplier;

  const {
    data: ingredientList,
    isLoading: isIngredientListLoading,
    refetch: refetchIngredients,
  } = useGetIngredientList(pageNumber, pageLimit);

  const { mutateAsync: mutatePutSupplier } = usePutSupplierData();

  const handlePagination = (currentPage: any) => {
    setPageNumber(currentPage);
  };

  const handlePageSize = (pageSize: any) => {
    setPageLimit(pageSize);
  };

  const getIngredientDetails = (selectedIngredient: any) => {
    setViewIngrdientData(selectedIngredient);
    setVisibleDetailsModal(true);
  };

  const handleIngredientDelete = (detailsToDelete: any) => {
    setDeleteIngrdientData(detailsToDelete);
    setShowDeleteIngredientModal(true);
  };

  const getIngredientForEditDetails = (detailsToEdit: any) => {
    setEditIngrdientData(detailsToEdit);
    setVisibleEditModal(true);
  };

  const deleteSupplier = () => {
    let supplierIngredient = deleteIngredientData.supplierIngredientIds.filter(
      (e: any) => e != deleteIngredientData.ingredient_id
    );
    let supplierUpdate = {
      _id: deleteIngredientData.supplier_id,
      supplier_name: deleteIngredientData.supplier_name,
      ingredientIds: supplierIngredient ? supplierIngredient : [],
    };
    mutatePutSupplier(supplierUpdate, {
      onSuccess(data: any) {
        setShowDeleteIngredientModal(false);
        successMessage("Supplier removed from ingredient successfully");
        setDeleteIngrdientData({});
      },
    });
  };

  const loadTableData = () => {
    const ingredientData = ingredientList?.result?.map((ingredient: any) => {
      return {
        ingredient_id: ingredient?._id,
        ingredient_name: ingredient?.ingredient_name,
        supplier_id: ingredient?.suppliers?._id || null,
        supplier_name: ingredient?.suppliers?.supplier_name,
        supplierAsUser: ingredient?.suppliers?.is_supplier_as_user || false,
        supplierIngredientDetails:
          ingredient?.suppliers?.supplierIngredientDetails?.find(
            (ingDetail: any) => ingDetail?.ingredient_id === ingredient?._id
          ) || null,
        ingredientProductIds: ingredient?.productIds || [],
        supplierProductIds: ingredient?.suppliers?.productIds || [],
        supplierIngredientIds: ingredient?.suppliers?.ingredientIds || [],
        ingredient_image_path: ingredient?.ingredient_image_path,
      };
    });

    return (
      <>
        {ingredientData?.map((dataView: any, index: number) => {
          let hideEditCol = dataView?.supplier_name ? false : true;

          return (
            <TableRow key={index}>
              <Table.Cell>{dataView?.ingredient_name}</Table.Cell>
              <Table.Cell>{dataView?.supplier_name || ""}</Table.Cell>
              <Table.Cell>
                {checkIsUserSupplier &&
                dataView?.supplierIngredientDetails?.climate_impact
                  ? "Calculated: Upgrade to see results"
                  : dataView?.supplierIngredientDetails?.climate_impact ||
                    "Calculation is in progress"}
              </Table.Cell>
              <Table.Cell>
                {!hideEditCol ? (
                  <Grid>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      className="align-content-center"
                    >
                      <TbFileSearch
                        cursor={"pointer"}
                        onClick={() => {
                          getIngredientDetails(dataView);
                        }}
                        size={24}
                        color="var(--tableEditIcon)"
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      className="align-content-center"
                    >
                      <MdModeEditOutline
                        cursor={"pointer"}
                        onClick={() => {
                          getIngredientForEditDetails(dataView);
                        }}
                        size={24}
                        color="var(--tableEditIcon)"
                      />
                    </Grid.Column>
                  </Grid>
                ) : null}
              </Table.Cell>
            </TableRow>
          );
        })}
      </>
    );
  };

  // Loading state check
  if (isIngredientListLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar titleName={"Ingredients"} />
      <CustomButton
        theme="green"
        title="New Ingredient"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconChild={<PlusIcon />}
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable
            tableHeaderData={IngredientTableColView}
            singleLine={true}
            enableTableFooter={true}
            tableFooterChild={
              <PaginationView
                changePageSize={handlePageSize}
                pageSize={pageLimit}
                totalPages={Math.ceil(ingredientList?.total / pageLimit)}
                currentPageNo={pageNumber}
                currentSelectPage={handlePagination}
              />
            }
          >
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      {visibleModal ? (
        <IngredientRegisterModalView
          visibleModal={visibleModal}
          setVisibleModal={() => setVisibleModal(false)}
          ingredientList={ingredientList?.result || []}
          refetchIngredients={refetchIngredients}
        />
      ) : null}
      {visibleEditModal ? (
        <IngredientEditModal
          ingredientIdToUpdate={editIngredientData}
          visibleModal={visibleEditModal}
          setVisibleModal={() => setVisibleEditModal(false)}
          refetchIngredients={refetchIngredients}
        />
      ) : null}
      {visibleDetailsModal ? (
        <IngredientDetailsModal
          visibleModal={visibleDetailsModal}
          setVisibleModal={() => setVisibleDetailsModal(false)}
          ingredientIdToUpdate={viewIngredientData}
        />
      ) : null}
      <ConfirmModal
        viewModal={showDeleteIngredientModal}
        closeModal={() => setShowDeleteIngredientModal(false)}
        cancel={() => {
          setShowDeleteIngredientModal(false);
          setDeleteIngrdientData({});
        }}
        approve={() => {
          deleteSupplier();
        }}
        title="Delete Ingredient Data"
        subTitle="Are you sure you want to delete the ingredient?"
      />
    </>
  );
};

export default IngredientList;
