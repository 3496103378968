import * as React from "react";
import { getPathName } from "../../utils/utils";
const SupplierIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "/supplier"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="m22.44 12.457-2.25-5.25a.75.75 0 0 0-.69-.457h-2.25v-1.5a.75.75 0 0 0-.75-.75H2.25a.75.75 0 0 0-.75.75V18a.75.75 0 0 0 .75.75h1.605a3 3 0 0 0 5.79 0h4.71a3 3 0 0 0 5.79 0h1.605a.75.75 0 0 0 .75-.75v-5.25c0-.1-.02-.2-.06-.293ZM17.25 8.25h1.755L20.61 12h-3.36V8.25ZM6.75 19.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm7.605-2.25h-4.71a3 3 0 0 0-5.79 0H3V6h12.75v9.42a3 3 0 0 0-1.395 1.83Zm2.895 2.25a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM21 17.25h-.855A3 3 0 0 0 17.25 15v-1.5H21v3.75Z"
      />
    </svg>
  );
};
export default SupplierIcon;
