import React from "react";
import { Button, Grid } from "semantic-ui-react";
import useWindowDimensions from "../../hooks/windowsSize";

import "./mainBottomButton.scss";
import { CustomButton } from "../button/Button";

interface MainBottomButtonViewProps {
  customHeight?: number | null;
  deleteStatus?: boolean;
  cancelButtonStatus?: boolean;
  saveButtonStatus?: boolean;
  deleteButton?: () => void;
  saveButton?: () => void;
  cancelButton?: () => void;
  bounceEnable?: boolean;
  cancelButtonTitle?: string;
  saveTitle?: string;
  type?: "button" | "submit" | "reset";
  saveDisable?: boolean;
  isSimulation?: boolean;
  customBottomContentMain?:any
}

const MainBottomButtonView: React.FC<MainBottomButtonViewProps> = ({
  customHeight = null,
  deleteStatus = false,
  cancelButtonStatus = false,
  saveButtonStatus = false,
  deleteButton = () => { },
  saveButton = () => { },
  cancelButton= () => { },
  bounceEnable = false,
  saveTitle = "Save",
  type = "button",
  saveDisable = false,
  isSimulation = false,
  cancelButtonTitle = "Cancel",
  customBottomContentMain
}) => {
  const { height } = useWindowDimensions();
  const heightView = customHeight || height * 0.1;

  return (
    <Grid style={{ height: heightView }} className={`bottomButtonContent ${customBottomContentMain}`}>
      <Grid.Column computer={9} tablet={16} mobile={16}>
        <Grid>
          {cancelButtonStatus && (
            <Grid.Column computer={4} tablet={16} mobile={16} className="pb-0">
              <CustomButton
                theme="green"
                title={cancelButtonTitle}
                onClick={() => {
                  cancelButton();
                }}
                buttonOutLine={true}
              />
            </Grid.Column>
          )}
          {deleteStatus && (
            <Grid.Column computer={4} tablet={16} mobile={16} className="pb-0">
              <CustomButton
                theme="green"
                title={"Delete"}
                onClick={() => {
                  deleteButton();
                }}
                buttonOutLine={true}
              />
            </Grid.Column>
          )}
        </Grid>
      </Grid.Column>
      <Grid.Column computer={7} tablet={16} mobile={16} textAlign="right" className="pb-0">
        {saveButtonStatus && !isSimulation && (
          <CustomButton
            theme="green"
            title={saveTitle}
            onClick={() => saveButton()}
            type={type}
            disabled={saveDisable}
            className={bounceEnable ? "bounce-effect" : ""}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default MainBottomButtonView;

