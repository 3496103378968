import { useMutation, useQuery } from "@tanstack/react-query";
import { successMessage } from "../helpers/ErrorHandler";
import { ApiServiceErr, axiosApi } from "./apiService";

interface Product {
  climate_impact: string;
  createdAt: string;
  createdById: string;
  createdByName: string;
  product_image_path: string;
  product_ingredients: any;
  product_name: string;
  product_other_info: string;
  product_status: string;
  product_type: string;
  registration_number: string;
  sales_units: any;
  tenant_id: string;
  tenant_name: string;
  _id: string;
}

interface ProductInfo {
  result: Product[];
  page: number;
  pageSize: number;
  total: number;
}

interface ProductSalesUnitInputs {
  searchKey?: string;
}

interface ProductListViewParams {
  product_status?: string;
  pageNumber?: number;
  pageSize?: number;
  isAllData?: boolean;
  productIds?:[string];
  salesUnitIds?:[string];
  calculateStatus?:string
}

interface ProductSalesUnitData {
  productName: string;
  productId: string;
  salesUnitName: string;
  salesUnitId: string;
}

interface ProductSalesUnit {
  _id: string;
  sales_unit_name: string;
  sales_unit_size: number;
  sales_unit_type: string;
  sales_unit_status: string;
  ean_barcode: string;
  climate_impact: string;
}

interface ProductSalesUnitPost {
  product_id: string;
  sales_units: ProductSalesUnit;
}

interface ProductImageData {
  product_id: string;
  file: any;
}

interface ProductImageId {
  product_id: string;
}

export const useGetProductSalesUnits = ({
  searchKey,
}: ProductSalesUnitInputs) =>
  useQuery<ProductSalesUnitData[], ApiServiceErr>([searchKey], async () => {
    const response = await axiosApi.get("/products/sales-units", {
      params: { searchKey },
    });
    return response.data;
  });

export const useGetProductCalculateSales = ({
  searchKey,
}: ProductSalesUnitInputs) =>
  useQuery<ProductSalesUnitData[], ApiServiceErr>([searchKey], async () => {
    const response = await axiosApi.get("/products/calculated-sales-units", {
      params: { searchKey },
    });
    return response.data;
  });

export const useGetProductData = (filterData: ProductListViewParams) =>
  useQuery<ProductInfo, ApiServiceErr>(
    [filterData, "getProductDataList"],
    async () => {
      const response = await axiosApi.get("/products", {
        params: filterData,
      });
      return response?.data;
    }
  );

export const useGetProductById = (id: string | undefined) =>
  useQuery<Product, ApiServiceErr>(
    [id, "getProductDataById"],
    async () => {
      const response = await axiosApi.get(`/products/${id}`);
      return response?.data;
    },
    {
      cacheTime: 0,
      enabled: !!id,
    }
  );

export const useCreateProduct = (opt?: any) =>
  useMutation<Product, ApiServiceErr, any>(async (data: Product) => {
    const response = await axiosApi.post("/products", data);
    return response.data;
  }, opt);

export const usePutEditProduct = () =>
  useMutation<Product, ApiServiceErr, any>(
    async (data: Product) => {
      const response = await axiosApi.put(`/products/${data._id}`, data);
      return response.data;
    },
    {
      onSuccess() {
        successMessage("Product updated successfully");
      },
    }
  );

export const useUploadProductImage = () =>
  useMutation<any, ApiServiceErr, any>(
    async (data: ProductImageData) => {
      const response = await axiosApi.post(`/products/upload`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    },
    {
      onSuccess(data) {
        successMessage(data);
      },
    }
  );

export const useDeleteUploadProductImage = () =>
  useMutation<any, ApiServiceErr, any>(
    async (data: ProductImageId) => {
      const response = await axiosApi.delete(`/products/image`, {
        params: { product_id: data.product_id },
      });
      return response.data;
    },
    {
      onSuccess() {
        successMessage("Product image delete successfully");
      },
    }
  );

export const useDeleteProductById = () =>
  useMutation<any, ApiServiceErr, any>(
    async (productId) => {
      const response = await axiosApi.put(`/products/${productId}`, {
        product_status: "DISABLE",
      });
      return response.data;
    },
    {
      onSuccess: () => {
        successMessage("Product delete successfully");
      },
    }
  );

export const useGetSalesUnit = (data: ProductListViewParams) =>
  useQuery<Product, ApiServiceErr>(
    [data, "getSalesUnit"],
    async () => {
      const response = await axiosApi.get(`/products/sales-unit`, {
        params: { data },
      });
      return response?.data;
    },
    {
      cacheTime: 0,
    }
  );

export const usePostUSalesUnit = (data?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: ProductSalesUnitPost) => {
    const response = await axiosApi.post(`/products/sales-unit`, data);
    return response.data;
  }, data);

export const usePutEditSalesUnit = () =>
  useMutation<ProductSalesUnit[], ApiServiceErr, any>(
    async (data) => {
      const response = await axiosApi.put(`/products/sales-unit`, data);
      return response.data;
    },
    {
      onSuccess() {
        successMessage("Sales unit data update successfully");
      },
    }
  );
