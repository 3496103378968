import React, { useMemo, useState } from "react";
import {
  CustomButton,
  CommonTable,
  InputText,
  DropDown,
  AccordionTitleView,
} from "../../../components";
import { useForm, useFieldArray } from "react-hook-form";
import { SipCol, PER_TYPES, PER_WEIGHT_UNITS } from "../../../config/constants";
import { InputNumber } from "../../../components/InputText/InputNumber";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
  Table,
} from "semantic-ui-react";
import { AccordionNames } from "../../../helpers/AccordionHelper";

interface SimulationSipContentProps {
  getSipData: (data: any) => void;
  processCIPData: any;
  currentCipSipData: any;
  originalCipSipData: any;
  originalProcessCIPData: any;
  handleCIPValidation: () => boolean;
  currentAccordionStatus: (
    activeStatus: boolean,
    requestType: AccordionNames
  ) => boolean;
}

const SimulationSipContentContent = ({
  processCIPData,
  getSipData,
  currentCipSipData,
  originalCipSipData,
  originalProcessCIPData,
  handleCIPValidation = () => true,
  currentAccordionStatus,
}: SimulationSipContentProps) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);
  const [defaultPerTypeFrequency, setDefaultPerTypeFrequency] =
    useState("week");
  const [defaultPerTypePA, setDefaultPerTypePA] = useState("week");
  const [defaultWeightUnit, setDefaultWeightUnit] = useState("kg");

  const values: any = useMemo(() => {
    setDefaultPerTypeFrequency(
      currentCipSipData?.[0]?.frequency?.per || "week"
    );
    setDefaultPerTypePA(
      currentCipSipData?.[0]?.productionAmount?.per || "week"
    );
    setDefaultWeightUnit(
      currentCipSipData?.[0]?.productionAmount?.perUnit || "kg"
    );
    return {
      cipData: processCIPData,
      frequency: currentCipSipData?.[0]?.frequency?.amount || 0,
      prodAmount: currentCipSipData?.[0]?.productionAmount?.amount || 0,
    };
  }, [accordionEnabled]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const { fields: cipDetails } = useFieldArray({
    control,
    name: "cipData",
  });

  const onSubmit = (data: any) => {
    let dataView = data.cipData.reduce(function (acc: any, obj: any) {
      acc[obj.typeName] = obj.value;
      return acc;
    }, {});
    getSipData([
      {
        ...dataView,
        frequency: {
          amount: data?.frequency || 0,
          per: defaultPerTypeFrequency,
        },
        productionAmount: {
          amount: data?.prodAmount || 0,
          per: defaultPerTypePA,
          perUnit: defaultWeightUnit,
        },
      },
    ]);
    currentAccordionStatus(false, AccordionNames.CIP_SIP);
    setAccordionEnabled(false);
  };

  const cancelHandler = () => {
    currentAccordionStatus(false, AccordionNames.CIP_SIP);
    setAccordionEnabled(false);
    cipDetails.forEach((v: any, index: any) => {
      setValue(`cipData.${index}.value`, null);
    });
  };

  const handleClick = () => {
    if (!handleCIPValidation()) return;
    const status = currentAccordionStatus(
      !accordionEnabled,
      AccordionNames.CIP_SIP
    );
    setAccordionEnabled(status);
  };

  const loadSipableData = () => {
    return cipDetails.map((data: any, index: any) => {
      const tableCellInputViewStatus =
        data.name === "Model Number" ||
        data.name === "Manufacturer" ||
        data.name === "Chemical use";
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.name}</p>
          </Table.Cell>
          <Table.Cell>
            {tableCellInputViewStatus ? (
              <InputText
                register={register}
                placeholder={data.value}
                name={`cipData.${index}.value`}
              />
            ) : (
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                placeholder={"0"}
                name={`cipData.${index}.value`}
              />
            )}
          </Table.Cell>
          <Table.Cell>
            <p>{data.unit}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <Accordion>
        <AccordionTitle active={accordionEnabled} onClick={handleClick}>
          <AccordionTitleView
            accordionEnabled={accordionEnabled}
            title={"CIP / SIP"}
          />
        </AccordionTitle>
        <AccordionContent active={accordionEnabled}>
          <Grid>
            <Grid.Row divided>
              <Grid.Column computer={8} tablet={8} mobile={8}>
                <Grid>
                  <Grid.Row style={{ alignItems: "center", marginTop: 30 }}>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>Frequency</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>
                        {originalCipSipData?.[0]?.frequency?.amount || 0}
                      </label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>time(s)per</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>
                        {originalCipSipData?.[0]?.frequency?.per}
                      </label>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ alignItems: "center" }}>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>Production Amount</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>
                        {originalCipSipData?.[0]?.productionAmount?.amount || 0}
                      </label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>
                        {originalCipSipData?.[0]?.productionAmount?.perUnit}
                      </label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>per</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <label className={`textLabel`}>
                        {originalCipSipData?.[0]?.productionAmount?.per}
                      </label>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Column
                    style={{ marginTop: 60 }}
                    computer={16}
                    tablet={16}
                    mobile={16}
                  >
                    <CommonTable
                      isSimulateView
                      tableHeaderData={SipCol.filter((e: any) => e.name !== "")}
                    >
                      {originalProcessCIPData?.map((data: any, index: any) => {
                        return (
                          <Table.Row className="tbleR" key={index}>
                            <Table.Cell>
                              <p>{data.name}</p>
                            </Table.Cell>
                            <Table.Cell>{data.value}</Table.Cell>
                            <Table.Cell>
                              <p>{data.unit}</p>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column computer={8} tablet={8} mobile={8}>
                <Grid>
                  <Grid.Row style={{ alignItems: "center" }}>
                    <Grid.Column computer={3} tablet={8} mobile={16}>
                      <label className={`textLabel`}>Frequency</label>
                    </Grid.Column>
                    <Grid.Column computer={5} tablet={8} mobile={16}>
                      <InputNumber
                        checkValidation={true}
                        transform={{
                          input: (value: any) =>
                            isNaN(value) || /^\d*\.\d+$/.test(value)
                              ? ""
                              : value.toString(),
                          output: (e: any) => {
                            return e;
                          },
                        }}
                        control={control}
                        placeholder={"0"}
                        defaultValue={
                          values?.cipData?.[values?.cipData.length - 2]?.value
                            ?.amount
                        }
                        name={`frequency`}
                      />
                    </Grid.Column>
                    <Grid.Column computer={1} tablet={8} mobile={16}>
                      <label className={`textLabel`}>time(s)</label>
                    </Grid.Column>
                    <Grid.Column computer={1} tablet={8} mobile={16}>
                      <label className={`textLabel`}>per</label>
                    </Grid.Column>
                    <Grid.Column computer={5} tablet={8} mobile={16}>
                      <DropDown
                        customLabelText={"customLabelText"}
                        currentData={PER_TYPES}
                        defaultValue={defaultPerTypeFrequency}
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          setDefaultPerTypeFrequency(value);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ alignItems: "center" }}>
                    <Grid.Column computer={3} tablet={8} mobile={16}>
                      <label className={`textLabel`}>Production Amount</label>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={8} mobile={16}>
                      <InputNumber
                        transform={{
                          input: (value: any) =>
                            isNaN(value) ? "" : value.toString(),
                          output: (e: any) => {
                            return e;
                          },
                        }}
                        control={control}
                        placeholder={"0"}
                        defaultValue={
                          values?.cipData?.[values?.cipData.length - 1]?.value
                            ?.amount
                        }
                        name={`prodAmount`}
                      />
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={8} mobile={16}>
                      <DropDown
                        customLabelText={"customLabelText"}
                        currentData={PER_WEIGHT_UNITS}
                        defaultValue={defaultWeightUnit}
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          setDefaultWeightUnit(value);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column computer={1} tablet={8} mobile={16}>
                      <label className={`textLabel`}>per</label>
                    </Grid.Column>
                    <Grid.Column computer={5} tablet={8} mobile={16}>
                      <DropDown
                        customLabelText={"customLabelText"}
                        currentData={PER_TYPES}
                        defaultValue={defaultPerTypePA}
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          setDefaultPerTypePA(value);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <CommonTable tableHeaderData={SipCol}>
                      {loadSipableData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
                <div className="sipButtonContent">
                  <div className="sipCancel">
                    <CustomButton
                      onClick={() => {
                        cancelHandler();
                      }}
                      title="Cancel"
                      theme="green"
                      buttonOutLine={true}
                    />
                  </div>
                  <div className="sipAdd">
                    <CustomButton
                      onClick={handleSubmit(onSubmit)}
                      title="Save"
                      theme="green"
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </AccordionContent>
      </Accordion>
    </>
  );
};

export default SimulationSipContentContent;
