import React, { useState, useRef, useEffect, ReactNode } from "react";
import "./popOver.scss";
interface PopoverProps {
  children?: ReactNode;
  content?: ReactNode;
  topArrowStyle?: any;
  mainContentView?: any;
  customPopOverContent?: any;
  visibility?: boolean;
  currentVisibility?: any;
}

export const PopOver: React.FC<PopoverProps> = ({
  children,
  content,
  topArrowStyle,
  mainContentView,
  customPopOverContent,
  visibility = false,
  currentVisibility,
}) => {
  const [isVisible, setIsVisible] = useState(visibility);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    currentVisibility(!isVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setIsVisible(false);
      }
    };
    if (!visibility) setIsVisible(false);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibility]);
  return (
    <div className="popover-container">
      <button
        ref={triggerRef}
        onClick={toggleVisibility}
        className="popover-trigger"
        aria-haspopup="true"
        aria-expanded={isVisible}
        aria-controls="popover-content"
      >
        {children}
      </button>
      {isVisible && (
        <div
          id="popover-content"
          ref={popoverRef}
          className={`popover-content ${customPopOverContent}`}
          role="dialog"
          aria-modal="true"
        >
          <div className={`popover-content-arrow ${topArrowStyle}`} />
          <div className={`popOverContentMain ${mainContentView}`}>
            {content}
          </div>
        </div>
      )}
    </div>
  );
};
