import * as React from "react";
import { useLocation } from "react-router-dom";
import { getPathName } from "../../utils/utils";

const DashboardIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "/"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M7 16a1.5 1.5 0 0 0 1.5-1.5.77.77 0 0 0 0-.15l2.79-2.79h.46l1.61 1.61v.08a1.5 1.5 0 1 0 3 0v-.08L20 9.5A1.5 1.5 0 1 0 18.5 8a.767.767 0 0 0 0 .15l-3.61 3.61h-.16L13 10a1.5 1.5 0 1 0-3 0l-3 3a1.5 1.5 0 1 0 0 3Zm13.5 4h-17V3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 0 0 0-2Z"
      />
    </svg>
  );
};

export default DashboardIcon;
