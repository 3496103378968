import { useCallback, useEffect, useMemo } from "react";
import {
  useGetProductById,
  useGetProductSalesUnits,
} from "../../../api/product";
import { MappingSourceType } from "../../../config/enums/MappingSourceType.enum";
import { useSearchParams } from "react-router-dom";

interface useDrawingInitialDataProps {
  searchKey: string;
  sourceId: string | undefined;
  saveProductData: any;
  mappingSourceType: MappingSourceType;
}

const useProductDrawingInitialData = (
  searchKey: string,
  salesUnitId: string | undefined,
  saveProductData: any
) => {
  const {
    data: productSalesUnitsData,
    isLoading: isProductSalesUnitsLoading,
    isSuccess: isSuccessSalesUnits,
  } = useGetProductSalesUnits({ searchKey });

  const productId = useMemo(() => {
    return productSalesUnitsData?.find((ps) => ps.salesUnitId === salesUnitId)
      ?.productId;
  }, [salesUnitId, isSuccessSalesUnits]);

  const {
    data: productDetails,
    isSuccess: isSuccessProduct,
    isError: isErrorProduct,
  } = useGetProductById(productId);

  const productSalesUnits: DropdownOption[] = (productSalesUnitsData || []).map(
    ({ productName, salesUnitName, salesUnitId }) => ({
      key: salesUnitId,
      text: `${productName} - ${salesUnitName}`,
      value: salesUnitId,
    })
  );

  const setProductData = useCallback(() => {
    if (isSuccessProduct || isErrorProduct) {
      saveProductData(productDetails);
    }
  }, [productDetails, isSuccessProduct, isErrorProduct]);

  useEffect(() => {
    setProductData();
  }, [productDetails, setProductData]);

  return {
    ownerId: productId,
    selectionData: productSalesUnits,
    isSourceLoading: isProductSalesUnitsLoading,
  };
};

const useIngredientDrawingInitialData = () => {
  const [searchParams] = useSearchParams();
  const ownerId = searchParams.get("ownerId");

  return {
    ownerId: ownerId,
    selectionData: [],
    isSourceLoading: false,
  };
};

export const useDrawingInitialData = (prop: useDrawingInitialDataProps) => {
  const { searchKey, sourceId, saveProductData } = prop;
  const productData = useProductDrawingInitialData(
    searchKey,
    sourceId,
    saveProductData
  );

  const ingredientData = useIngredientDrawingInitialData();

  if (prop.mappingSourceType === MappingSourceType.product) {
    return productData;
  } else if (prop.mappingSourceType === MappingSourceType.ingredient) {
    return ingredientData;
  }

  return {
    ownerId: null,
    selectionData: [],
    isSourceLoading: false,
  };
};
