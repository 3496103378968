import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { generateRandomColors } from "../../../utils/utils";
import "./BarChart.scss";
import { Grid, Segment } from "semantic-ui-react";

interface BarChartProps {
  values: (string[] | number[])[];
  isLoading: boolean;
  title: string;
  xAxisName?: string;
  yAxisName?: string;
  height?:number
}

export const BarChart = (props: BarChartProps) => {
  const { isLoading, values, xAxisName, title, yAxisName, height } = props;
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return;
    const myChart = echarts.init(chartRef.current);
    const options = {
      title: {
        text: title,
        left: "center",
      },
      grid: {
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        name: xAxisName,
        data: values[0],
        axisLabel: {
          rotate: 60,
        },
      },
      yAxis: {
        type: "value",
        name: yAxisName,
      },
      height: height,
      series: [
        {
          type: "bar",
          data: values[1],
          itemStyle: {
            color: (params: any) => {
              return generateRandomColors(values[1].length || 0)[
                params.dataIndex
              ];
            },
          },
          label: {
            show: false,
            position: "top",
            formatter: "{b}",
          },
        },
      ],
    };

    myChart.setOption(options);

    return () => {
      myChart.dispose();
    };
  }, [values]);

  return (
    <Segment loading={isLoading}>
      <Grid.Row>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div className="bar-chart" ref={chartRef} />
        </Grid.Column>
      </Grid.Row>
    </Segment>
  );
};
