import React, { ReactNode} from "react";

import { CommonContainer } from "../../components/commonContainer/commonContainer";
import MenuBar from "../../components/menuBar/Menubar";
import useWindowDimensions from "../../hooks/windowsSize";
import { Header } from "../../components/index";

import "../mainContanier.scss";

interface MainContentViewLayoutProps {
  children: ReactNode ;
  currentPath: string;
}

export const MainContentViewLayout = ({
  children,
  currentPath,
}: MainContentViewLayoutProps) => {
  const { width } = useWindowDimensions();

  return (
    <div style={{ width: width }}>
      <Header />
      <div className="viewMain contentHide">
        <div className="leftLayoutContent contentHide">
          <MenuBar />
        </div>
        <div className="rightContentMain">
          <CommonContainer
            currentPath={currentPath}
            styles={`customMainBottomContainer`}
          >
            {children}
          </CommonContainer>
        </div>
      </div>
    </div>
  );
};
