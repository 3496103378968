import * as React from "react";
const PlusIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path fill="var(--menuBarIconActive)" d="M.5 0h18v18H.5z" />
    <path
      fill="#fff"
      d="M8.75 14.25v-4.5h-4.5v-1.5h4.5v-4.5h1.5v4.5h4.5v1.5h-4.5v4.5h-1.5Z"
    />
  </svg>
);
export default PlusIcon;
