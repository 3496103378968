import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";

export const usePostSimulationFarmGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/farm-gate/internal-storage?farmGateId=${data?.farmGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const usePutSimulationFarmGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/farm-gate/internal-storage/${data?.internalStorageId}?farmGateId=${data?.farmGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const useDeleteSimulationFarmGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/farm-gate/internal-storage/${data?.internalStorageId}?farmGateId=${data?.farmGateId}`
    );
    return response.data;
  });
};

export const useGetSimulationFarmGateInternalStorageById = (
  farmGateInternalStorageId: any
) =>
  useQuery(
    ["getSimulationFarmGateInternalStorageById", farmGateInternalStorageId],
    async () => {
      const response = await axiosApi.get(
        `/farm-gate/internal-storage/${farmGateInternalStorageId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!farmGateInternalStorageId,
    }
  );

export const useGetExistingFarmGateInternalStorageById = (
  farmGateInternalStorageId: any
) =>
  useQuery(
    ["getFarmGateExistingInternalStorageById", farmGateInternalStorageId],
    async () => {
      const response = await axiosApi.get(
        `/farm-gate/internal-storage/${farmGateInternalStorageId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!farmGateInternalStorageId,
    }
  );
