import { useMemo, useState } from "react";
import { Grid, Table, Loader, Dimmer } from "semantic-ui-react";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { FacilityTableCol } from "../../../config/constants";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { successMessage } from "../../../helpers/ErrorHandler";
import {
  useDeleteFacility,
  useGetFacilityPagesList,
} from "../../../api/facility";
import FacilityDetailsModal from "../facilityDetails/FacilityDetailsModal";
import FacilityRegisterModal from "../facilityRegister/FacilityRegisterModal";
import FacilityEditModal from "../facilityEdit/FacilityEditModal";
import PaginationView from "../../../components/pagination/Pagination";
import { useGetCountries } from "../../../api/static-data";
import PlusIcon from "../../../assets/commonIcon/PlusIcon";

const FacilityList = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [showDeleteFacilityModal, setShowDeleteFacilityModal] = useState(false);
  const [viewFacilityData, setViewFacilityData] = useState();
  const [editFacilityData, setEditFacilityData] = useState();
  const [deleteFacilityData, setDeleteFacilityData] = useState<any>({});
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [pageLimit, setPageLimit] = useState<any>(10);

  const {
    data: facilityList,
    isLoading: isFacilityListLoading,
    refetch,
  } = useGetFacilityPagesList(pageNumber, pageLimit);

  const { isLoading: editFacilityLoading, mutate: deleteFacility } =
    useDeleteFacility();

  const {
      data: countriesData,
      isSuccess: isCountriesDataSuccess,
      isLoading: isCountriesDataLoading,
    } = useGetCountries();


  const handlePagination = (currentPage: any) => {
    setPageNumber(currentPage);
  };

  const handlePageSize = (pageSize: any) => {
    setPageLimit(pageSize);
  };

  const getFacilityDetails = (selectedFacility: any) => {
    setViewFacilityData(selectedFacility);
    setVisibleDetailsModal(true);
  };

  const handleFacilityDelete = (detailsToDelete: any) => {
    setDeleteFacilityData(detailsToDelete);
    setShowDeleteFacilityModal(true);
  };

  const getFacilityForEditDetails = (selectedFacility: any) => {
    setEditFacilityData(selectedFacility);
    setVisibleEditModal(true);
  };

  //delete facility data
  const deleteFacilityDetails = (data: any) => {
    deleteFacility(data, {
      onSuccess: () => {
        setVisibleModal(false);
      },
    });
  };

 
  const loadTableData = () => {
    return facilityList?.result?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.facilityName}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.facilityType}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <TbFileSearch
                  cursor={"pointer"}
                  onClick={() => {
                    getFacilityDetails(data);
                  }}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  onClick={() => {
                    getFacilityForEditDetails(data);
                  }}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <MdDeleteOutline
                  onClick={() => {
                    handleFacilityDelete({
                      _id: data?._id,
                    });
                  }}
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  // Loading state check
  if (isFacilityListLoading || editFacilityLoading || isCountriesDataLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar titleName={"Facilities"} />
      <CustomButton
        theme="green"
        title="New Facility"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconChild={<PlusIcon />}
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={FacilityTableCol}>
            {loadTableData()}
          </CommonTable>
          <PaginationView
            changePageSize={handlePageSize}
            pageSize={pageLimit}
            totalPages={Math.ceil(facilityList?.total / pageLimit)}
            currentPageNo={pageNumber}
            currentSelectPage={handlePagination}
          />
        </Grid.Column>
      </Grid>
      <FacilityRegisterModal
        visibleModal={visibleModal}
        setVisibleModal={() => {
          setVisibleModal(false);
          refetch();
        }}
      />
      <FacilityEditModal
        visibleModal={visibleEditModal}
        setVisibleModal={() => {
          setVisibleEditModal(false);
          refetch();
        }}
        facilityData={editFacilityData}
        updateFacilityData={setEditFacilityData}
      />
      <FacilityDetailsModal
        visibleModal={visibleDetailsModal}
        setVisibleModal={() => setVisibleDetailsModal(false)}
        facilityData={viewFacilityData}
        countries={countriesData}
      />
      <ConfirmModal
        viewModal={showDeleteFacilityModal}
        closeModal={() => setShowDeleteFacilityModal(false)}
        cancel={() => {
          setShowDeleteFacilityModal(false);
          setDeleteFacilityData({});
        }}
        approve={() => {
          deleteFacilityDetails(deleteFacilityData);
          successMessage("Facility removed successfully");
          setDeleteFacilityData({});
          setShowDeleteFacilityModal(false);
        }}
        title="Delete Facility Data"
        subTitle="Are you sure you want to delete the facility?"
      />
    </>
  );
};

export default FacilityList;
