import React, { useEffect, useState, useRef, useCallback } from "react";
import { Grid } from "semantic-ui-react";
import { useForm, useWatch } from "react-hook-form";
import defaultUserImage from "../../../assets/images/default_icon.jpeg";
import isEmpty from "lodash/isEmpty";

import {
  InputText,
  CustomButton,
  SearchValue,
  NewImageUploader,
} from "../../../components";
import {
  useDeleteUploadIngredientImage,
  usePostIngredientData,
  useUploadIngredientImage,
} from "../../../api/ingredients";
import { successMessage } from "../../../helpers/ErrorHandler";

import "./IngredientRegister.scss";
import { noResultIngredient } from "../../../config/constants";
interface HandleValidation {
  checkValidation(): void;
}

const IngredientDataRegister = ({
  ingredientListByProduct = [],
  ingredientRegisterData = () => {},
  refetchIngredient = () => {},
  currentIngredientData,
  disableEdit = false,
  showSupplier = () => {},
  setViewPackage,
  setImageData,
}: any) => {
  const [newIngredient, setNewIngredient] = useState(false);
  const [ingredientData, setIngredientData] = useState<any>({});
  const validIngredient = useRef<HandleValidation>(null);
  const { mutateAsync: mutatePostIngredient } = usePostIngredientData();
  const { isLoading: isLoadingProductImage, mutate: uploadIngredientImage } =
    useUploadIngredientImage();
  const {
    isLoading: isLoadingProductImageDelete,
    mutate: deleteIngredientImage,
  } = useDeleteUploadIngredientImage();
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    control,
  } = useForm();

  const ingredientNameChange = useWatch({
    control,
    name: "ingredient_name",
    defaultValue: getValues().ingredient_name,
  });

  const checkValueISChange = useCallback(() => {
    if (ingredientData.ingredient_name == ingredientNameChange) return;
    setValue(
      "ingredient_name",
      ingredientNameChange
        ? ingredientNameChange
        : currentIngredientData?.ingredient_name
    );
    let updateObject = {
      ...currentIngredientData,
      ingredient_name: ingredientNameChange,
    };
    setIngredientData(updateObject);
    ingredientRegisterData(updateObject);
  }, [ingredientNameChange, ingredientData]);

  const setIngredientValue = useCallback(() => {
    if (disableEdit) {
      setValue("ingredient_name", ingredientData?.ingredient_name || "");
    }
  }, [disableEdit, ingredientData]);

  useEffect(() => {
    setIngredientValue();
    if (currentIngredientData) {
      setIngredientData(currentIngredientData);
    }
  }, [currentIngredientData, setIngredientValue, checkValueISChange]);

  const getSearchValue = (data: any) => {    
    if (data?.result?.id === 0) {
      setValue("ingredient_name", "");
      setNewIngredient(true);
      showSupplier(false);
      setViewPackage({
        processIngredient: false,
        produceIngredient: false,
      });
      ingredientRegisterData(undefined);
      setIngredientData({});
    } else {
      setNewIngredient(false);
      let ingredientData = {
        ingredient_name: data?.result?.title,
        ingredient_data:
          data?.result?.other?.ingredientData || data?.result?.ingredientData,
      };
      ingredientRegisterData(ingredientData);
      setIngredientData(ingredientData);
      if (showSupplier) {
        showSupplier(true);
      }
    }
  };

  const handleSearchValidation = (status: any) => {
    if (status) {
      ingredientRegisterData(undefined);
    }
  };

  const onSubmit = (data: any) => {
    const dataToSubmit = {
      ingredient_name: data.ingredient_name,
      ingredient_status: "ACTIVE",
    };
    mutatePostIngredient(dataToSubmit, {
      onSuccess(data: any) {
        successMessage("Ingredient added successfully");
        let ingredientData = {
          ingredient_name: data?.ingredient_name,
          ingredient_data: data,
        };
        ingredientRegisterData(ingredientData);
        setIngredientData(ingredientData);
        setNewIngredient(false);
        refetchIngredient();
        if (showSupplier) {
          showSupplier(true);
        }
      },
    });
  };

  const customResultTextView = (title: string, id: number) => (
    <p
      className={
        id === noResultIngredient.id && title === noResultIngredient.title
          ? "customSearchResultText"
          : "searchResultText"
      }
    >
      {title}
    </p>
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ marginBottom: 20 }}>
        {newIngredient ? (
          <>
            <Grid>
              <Grid.Row className="paddingRemoveBottom">
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={8}
                  className="paddingRemoveBottom"
                >
                  <InputText
                    register={register}
                    errors={errors.ingredient_name}
                    labelName={"Ingredient Name*"}
                    placeholder="Type your ingredient name"
                    name="ingredient_name"
                    required={true}
                    errorMessage={"Please enter ingredient name"}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  <NewImageUploader
                    mainTitle={"Ingredient Image"}
                    defaultUserImage={defaultUserImage}
                    buttonTitle={"Upload an Image"}
                    customImageViewMain={"customProductImageView"}
                    imageData={(data: any) => {
                      setImageData(data);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer={4} tablet={4} mobile={4}>
                  <CustomButton
                    theme="green"
                    customColumnStyle={"customIngredientUnitButton"}
                    title="Save"
                    type="submit"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={4}>
                  <CustomButton
                    theme="green"
                    onClick={() => {
                      setValue("ingredient_name", "");
                      setIngredientData({});
                      setNewIngredient(false);
                    }}
                    customColumnStyle={"customIngredientUnitButton"}
                    title="Back"
                    type="button"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        ) : (
          <Grid>
            {disableEdit ? (
              <>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  <InputText
                    register={register}
                    errors={errors.ingredient_name}
                    labelName={"Ingredient Name*"}
                    placeholder="Type your ingredient name"
                    name="ingredient_name"
                    required={true}
                    errorMessage={"Please enter ingredient name"}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  {!isEmpty(ingredientData?.ingredient_image_path) ? (
                    <NewImageUploader
                      defaultUserImage={defaultUserImage}
                      buttonTitle={"Upload an Image"}
                      defaultImage={ingredientData.ingredient_image_path}
                      uploadButtonView={false}
                      deleteButtonView={false}
                      mainTitle={"Ingredient Image"}
                    />
                  ) : null}
                </Grid.Column>
              </>
            ) : (
              <>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  <SearchValue
                    ref={validIngredient}
                    searchDataValues={ingredientListByProduct}
                    checkOther={(status: any) => {
                      handleSearchValidation(status);
                    }}
                    title={"Ingredient Name*"}
                    validationMessage="Please insert ingredient name"
                    defaultValue={ingredientData.ingredient_name}
                    selectDetails={getSearchValue}
                    noResultEnable={true}
                    noResultData={noResultIngredient}
                    disable={false}
                    customeResultTextEnable={true}
                    customResultTextView={customResultTextView}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  <NewImageUploader
                    mainTitle={"Ingredient Image"}
                    defaultImage={ingredientData.ingredient_image_path}
                    buttonTitle={"Upload an Image"}
                    customImageViewMain={"customProductImageView"}
                    imageData={(data: any) => {
                      setImageData(data);
                      if (data) {
                        let dataValue = {
                          ingredient_id: ingredientData?.ingredient_id,
                          file: data,
                        };
                        uploadIngredientImage(dataValue);
                      }
                    }}
                    deleteImage={() => {
                      let data = {
                        ingredient_id: ingredientData?.ingredient_id,
                      };
                      deleteIngredientImage(data);
                    }}
                  />
                </Grid.Column>
              </>
            )}
          </Grid>
        )}
      </div>
    </form>
  );
};

export default IngredientDataRegister;
